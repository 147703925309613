import React from "react";

const GitCommands: React.FC = () => {
  return (
    <div>
      <h2>Git Commands</h2>
      <p>
        Git is a powerful version control system used to manage and track
        changes in your projects. Whether you're working individually or
        collaborating with a team, Git helps you keep track of your code's
        history, manage branches for different features, and merge changes
        efficiently. Below is a comprehensive list of essential Git commands
        categorized by their functions, from configuration to advanced
        operations.
      </p>
      <h3>Configuring Git</h3>
      <ul>
        <li>
          <code>git config --global user.name "Your Name"</code>: Set the name
          that will be attached to your commits.
        </li>
        <li>
          <code>git config --global user.email "your.email@example.com"</code>:
          Set the email that will be attached to your commits.
        </li>
        <li>
          <code>git config --global core.editor "code --wait"</code>: Set the
          default editor used by Git.
        </li>
        <li>
          <code>git config --list</code>: List all the Git configurations.
        </li>
      </ul>

      <h3>Getting and Creating Projects</h3>
      <ul>
        <li>
          <code>git init [project-name]</code>: Create a new local repository.
        </li>
        <li>
          <code>git clone [url]</code>: Clone a repository into a new directory.
        </li>
      </ul>

      <h3>Basic Snapshotting</h3>
      <ul>
        <li>
          <code>git add [file]</code>: Add a file to the staging area.
        </li>
        <li>
          <code>git add .</code>: Add all new and changed files to the staging
          area.
        </li>
        <li>
          <code>git commit -m "Commit message"</code>: Commit changes to the
          repository with a message.
        </li>
        <li>
          <code>git status</code>: Show the status of changes as untracked,
          modified, or staged.
        </li>
        <li>
          <code>git diff</code>: Show differences between the working directory
          and index.
        </li>
        <li>
          <code>git diff --staged</code>: Show differences between the index and
          the last commit.
        </li>
        <li>
          <code>git reset [file]</code>: Unstage a file while retaining changes
          in the working directory.
        </li>
        <li>
          <code>git reset --hard</code>: Reset the working directory to the last
          commit.
        </li>
      </ul>

      <h3>Branching and Merging</h3>
      <ul>
        <li>
          <code>git branch</code>: List branches.
        </li>
        <li>
          <code>git branch [branch-name]</code>: Create a new branch.
        </li>
        <li>
          <code>git checkout [branch-name]</code>: Switch to a branch.
        </li>
        <li>
          <code>git merge [branch-name]</code>: Merge a branch into the current
          branch.
        </li>
        <li>
          <code>git branch -d [branch-name]</code>: Delete a branch.
        </li>
      </ul>

      <h3>Sharing and Updating Projects</h3>
      <ul>
        <li>
          <code>git remote add [alias] [url]</code>: Add a remote repository.
        </li>
        <li>
          <code>git fetch [alias]</code>: Fetch changes from a remote
          repository.
        </li>
        <li>
          <code>git pull [alias] [branch]</code>: Fetch and merge changes from a
          remote branch.
        </li>
        <li>
          <code>git push [alias] [branch]</code>: Push changes to a remote
          branch.
        </li>
        <li>
          <code>git push --set-upstream [alias] [branch]</code>: Push a branch
          to a remote repository and set it as the upstream branch.
        </li>
      </ul>

      <h3>Inspection and Comparison</h3>
      <ul>
        <li>
          <code>git log</code>: Show the commit history.
        </li>
        <li>
          <code>git log --oneline --graph --decorate --all</code>: Show a graph
          of the commit history.
        </li>
        <li>
          <code>git show [commit]</code>: Show information about a specific
          commit.
        </li>
        <li>
          <code>git diff [commit1] [commit2]</code>: Show differences between
          two commits.
        </li>
      </ul>

      <h3>Undoing Changes</h3>
      <ul>
        <li>
          <code>git revert [commit]</code>: Create a new commit that undoes the
          changes from a previous commit.
        </li>
        <li>
          <code>git reset --hard [commit]</code>: Reset the working directory
          and staging area to match a commit.
        </li>
        <li>
          <code>git clean -f</code>: Remove untracked files from the working
          directory.
        </li>
      </ul>

      <h3>Stashing</h3>
      <ul>
        <li>
          <code>git stash</code>: Save changes temporarily.
        </li>
        <li>
          <code>git stash apply</code>: Apply the most recently stashed changes.
        </li>
        <li>
          <code>git stash list</code>: List stashed changes.
        </li>
        <li>
          <code>git stash drop</code>: Remove a stashed state.
        </li>
      </ul>

      <h3>Advanced Branching and Merging</h3>
      <ul>
        <li>
          <code>git rebase [branch]</code>: Reapply commits on top of another
          base tip.
        </li>
        <li>
          <code>git cherry-pick [commit]</code>: Apply the changes introduced by
          some existing commits.
        </li>
      </ul>

      <h3>Tagging</h3>
      <ul>
        <li>
          <code>git tag</code>: List tags.
        </li>
        <li>
          <code>git tag [tag-name]</code>: Create a new tag.
        </li>
        <li>
          <code>git tag -d [tag-name]</code>: Delete a tag.
        </li>
        <li>
          <code>git push [alias] [tag-name]</code>: Push a tag to a remote
          repository.
        </li>
      </ul>

      <h3>Rewriting History</h3>
      <ul>
        <li>
          <code>git commit --amend</code>: Modify the most recent commit.
        </li>
        <li>
          <code>git rebase -i [base]</code>: Interactively rebase commits onto
          another base.
        </li>
      </ul>

      <h3>Submodules</h3>
      <ul>
        <li>
          <code>git submodule add [url] [path]</code>: Add a submodule.
        </li>
        <li>
          <code>git submodule init</code>: Initialize the submodules.
        </li>
        <li>
          <code>git submodule update</code>: Update the submodules.
        </li>
      </ul>
    </div>
  );
};

export default GitCommands;
