import React from "react";
import './Footer.css';
const Footer: React.FC = () => {
    return (
        <>
            <div className="copyright">
                &copy; 2024 Nipuna Solutions. All Rights Reserved.
            </div>
        </>
    )
}

export default Footer;