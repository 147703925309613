import React from "react";

const registrationUIXML = `
<ui:UiBinder xmlns:ui='urn:ui:com.google.gwt.uibinder'
             xmlns:gwt='urn:import:com.google.gwt.user.client.ui'
             xmlns:res='urn:with:com.nipuna.codesnippets.gwt.client.RegistrationResources'>
    <ui:with type="com.nipuna.codesnippets.gwt.client.RegistrationResources" field="res"/>
    <gwt:HTMLPanel>
        <div class="{res.style.formPanel}">
            <h1>Registration Form</h1>

            <gwt:HorizontalPanel styleName="{res.style.formGroup}">
                <gwt:Label text="First Name:" styleName="{res.style.formLabel}"/>
                <gwt:TextBox ui:field="firstNameBox" styleName="{res.style.formInput}"/>
            </gwt:HorizontalPanel>

            <gwt:HorizontalPanel styleName="{res.style.formGroup}">
                <gwt:Label text="Last Name:" styleName="{res.style.formLabel}"/>
                <gwt:TextBox ui:field="lastNameBox" styleName="{res.style.formInput}"/>
            </gwt:HorizontalPanel>

            <gwt:HorizontalPanel styleName="{res.style.formGroup}">
                <gwt:Label text="Gender:" styleName="{res.style.formLabel}"/>
                <gwt:HorizontalPanel styleName="{res.style.radioGroup}">
                    <gwt:RadioButton ui:field="maleRadioButton" name="gender" text="Male"/>
                    <gwt:RadioButton ui:field="femaleRadioButton" name="gender" text="Female"/>
                </gwt:HorizontalPanel>
            </gwt:HorizontalPanel>

            <gwt:HorizontalPanel styleName="{res.style.formGroup}">
                <gwt:Label text="Mobile:" styleName="{res.style.formLabel}"/>
                <gwt:TextBox ui:field="mobileBox" styleName="{res.style.formInput}"/>
            </gwt:HorizontalPanel>

            <gwt:HorizontalPanel styleName="{res.style.formGroup}">
                <gwt:Label text="Email:" styleName="{res.style.formLabel}"/>
                <gwt:TextBox ui:field="emailBox" styleName="{res.style.formInput}"/>
            </gwt:HorizontalPanel>

            <gwt:HorizontalPanel styleName="{res.style.formGroup}">
                <gwt:Label text="Country:" styleName="{res.style.formLabel}"/>
                <gwt:ListBox ui:field="countryDropdown" styleName="{res.style.formInput}">
                </gwt:ListBox>
            </gwt:HorizontalPanel>

            <gwt:HorizontalPanel styleName="{res.style.formGroup}">
                <gwt:Label text="Notes:" styleName="{res.style.formLabel}"/>
                <gwt:TextArea ui:field="notesArea" styleName="{res.style.formInput}"/>
            </gwt:HorizontalPanel>

            <gwt:HorizontalPanel styleName="{res.style.buttonPanel}">
                <gwt:Button ui:field="submitButton" text="Submit" styleName="submitButton"/>
            </gwt:HorizontalPanel>
        </div>
    </gwt:HTMLPanel>
</ui:UiBinder>
`;

const registrationJavaClass = `
package com.nipuna.codesnippets.gwt.client;

import com.google.gwt.core.client.GWT;
import com.google.gwt.event.dom.client.ClickEvent;
import com.google.gwt.uibinder.client.UiBinder;
import com.google.gwt.uibinder.client.UiField;
import com.google.gwt.uibinder.client.UiHandler;
import com.google.gwt.uibinder.client.UiTemplate;
import com.google.gwt.user.client.Window;
import com.google.gwt.user.client.ui.Button;
import com.google.gwt.user.client.ui.Composite;
import com.google.gwt.user.client.ui.ListBox;
import com.google.gwt.user.client.ui.RadioButton;
import com.google.gwt.user.client.ui.TextArea;
import com.google.gwt.user.client.ui.TextBox;
import com.google.gwt.user.client.ui.Widget;

public class Registration extends Composite {

    private static RegistrationUiBinder uiBinder = GWT.create(RegistrationUiBinder.class);

    @UiTemplate("Registration.ui.xml")
    interface RegistrationUiBinder extends UiBinder<Widget, Registration> {
    }

    @UiField(provided = true)
    final RegistrationResources res;

    public Registration() {
        this.res = GWT.create(RegistrationResources.class);
        res.style().ensureInjected();
        initWidget(uiBinder.createAndBindUi(this));
    }

    @UiField
    TextBox firstNameBox;

    @UiField
    TextBox lastNameBox;

    @UiField
    RadioButton maleRadioButton;

    @UiField
    RadioButton femaleRadioButton;

    @UiField
    TextBox mobileBox;

    @UiField
    TextBox emailBox;

    @UiField
    ListBox countryDropdown;

    @UiField
    TextArea notesArea;

    @UiField
    Button submitButton;

    @UiHandler("submitButton")
    void onClickSubmit(ClickEvent event) {
        String firstName = firstNameBox.getText();
        String lastName = lastNameBox.getText();
        String gender = maleRadioButton.getValue() ? "Male" : "Female";
        String mobile = mobileBox.getText();
        String email = emailBox.getText();
        String country = countryDropdown.getSelectedItemText();
        String notes = notesArea.getText();

        String message = "First Name: " + firstName
                + "\nLast Name: " + lastName
                + "\nGender: " + gender
                + "\nMobile: " + mobile
                + "\nEmail: " + email
                + "\nCountry: " + country
                + "\nNotes: " + notes;
        Window.alert(message);
    }
}
`;

const registrationCss = `
.formPanel {
  width: 100%;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.formLabel {
  width: 150px;
  margin-right: 10px;
  font-weight: bold;
}

.formPanel h1 {
  text-align: center;
}

.formInput {
   flex: 1;
   padding: 8px;
   border: 1px solid #ccc;
   border-radius: 5px;
   min-width: 200px;
 }

.formPanel h1 {
  text-align: center;
}

.formPanel label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formPanel input,
.formPanel select,
.formPanel textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.formPanel button {
  width: auto;
  padding: 10px;
  background-color: #5CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.formPanel button:hover {
  background-color: #4cae4c;
}

.radioGroup {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.buttonPanel {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
`;

const registrationHtml = `
<!DOCTYPE html>
<html>
<head>
    <title>SampleGWTApp - Registration Form</title>
    <link rel="stylesheet" type="text/css" href="registration.css">
    <script type="text/javascript" language="javascript" src="samplegwtapp/samplegwtapp.nocache.js"></script>
</head>
<body>
<h1>Registration Form</h1>
<div id="formContainer"></div>
</body>
</html>
`;

const registrationResources = `
package com.nipuna.codesnippets.gwt.client;

import com.google.gwt.resources.client.ClientBundle;
import com.google.gwt.resources.client.CssResource;

public interface RegistrationResources extends ClientBundle {
    public interface MyCss extends CssResource {
        String formPanel();
        String formGroup();
        String formLabel();
        String formInput();
        String radioGroup();
        String buttonPanel();
    }

    @Source("registration.css")
    MyCss style();
}
`;

const SampleGWTAppJava = `
package com.nipuna.codesnippets.gwt.client;

import com.google.gwt.core.client.EntryPoint;
import com.google.gwt.user.client.ui.RootPanel;

public class SampleGWTApp implements EntryPoint {
    public void onModuleLoad() {
        RootPanel.get().add(new Registration());
    }
}

`;

const SampleGWTAppHtml = `
<!doctype html>
<html>
<head>
    <meta charset="UTF-8">
    <link type="text/css" rel="stylesheet" href="SampleGWTApp.css">
    <title>SampleGWTApp</title>
    <script type="text/javascript" src="samplegwtapp/samplegwtapp.nocache.js"></script>
</head>
<body>
<noscript>
    <div style="color: red;">
        Your web browser must have JavaScript enabled for this application to display correctly.
    </div>
</noscript>
</body>
</html>
`;

const SampleGWTAppxml = `
<inherits name='com.google.gwt.uibinder.UiBinder'/>                    
<entry-point class='com.nipuna.codesnippets.gwt.client.SampleGWTApp'/>
`;

const GWTUIBinders: React.FC = () => {
  return (
    <div>
      <h2>GWT UI Binders</h2>
      <p>
        <strong>UiBinder</strong> is a GWT feature that allows developers to
        create user interfaces in a declarative way using XML. It separates the
        UI design from the logic, making it easier to manage and maintain
        complex UIs.
      </p>
      <h4>Key Benefits:</h4>
      <ul>
        <li>
          UI design and application logic are separated, enhancing readability
          and maintainability.
        </li>
        <li>Allows for creating reusable UI components.</li>
        <li>
          Automatically binds UI elements to Java fields, simplifying event
          handling and data manipulation.
        </li>
      </ul>

      <h3>Steps to Create a Registration Form Using UiBinder</h3>

      <h4>1. Set Up Your GWT Project</h4>
      <p>
        Create your GWT project using the command:
        <pre>
          <code>
            webAppCreator -out SampleGWTApp -junit
            "C:\gwt-2.11.0\junit-4.13.2.jar"
            com.nipuna.codesnippets.gwt.SampleGWTApp
          </code>
        </pre>
      </p>

      <h4>2. Create Required Files</h4>
      <p>
        Create the following files in your project structure (Client folder):
        <ul>
          <li>
            <code>Registration.java</code> :The main class that defines the
            registration form. It initializes the UI components, manages user
            interactions, and handles the submission event.
          </li>
          <li>
            <code>Registration.ui.xml</code>:The layout definition for the
            registration form using UiBinder. It describes how the form
            components are arranged and styled using the provided CSS resources.
          </li>
          <li>
            <code>registration.css</code> :Contains the styling rules for the
            registration form, defining the appearance of each component,
            including layout, colors, and hover effects.
          </li>
          <li>
            <code>RegistrationResources.java</code> :A ClientBundle that
            provides a way to include CSS styles in the GWT application. It
            ensures that the styles are properly loaded and available for the UI
            components.
          </li>
          <li>
            <code>registration.html</code> :The HTML file that serves as the
            entry point for the GWT application. It includes references to the
            CSS file and the compiled GWT JavaScript.
          </li>
        </ul>
      </p>

      <h4>3. Define the UiBinder XML</h4>
      <p>
        Create the <code>Registration.ui.xml</code> file with the following
        structure:
      </p>
      <div className="listitems">
        <pre>{registrationUIXML}</pre>
      </div>

      <h4>4. Implement the Java Class</h4>
      <p>
        In <code>Registration.java</code>, implement the following:
      </p>
      <div className="listitems">
        <pre>{registrationJavaClass}</pre>
      </div>

      <h4>5. Define CSS Styles</h4>
      <p>
        In <code>registration.css</code>, define the styles for your form:
      </p>
      <div className="listitems">
        <pre>{registrationCss}</pre>
      </div>

      <h4>6. Include Resources</h4>
      <p>
        In <code>RegistrationResources.java</code>, manage CSS resources:
      </p>
      <div className="listitems">
        <pre>{registrationResources}</pre>
      </div>

      <h4>7. Set Up Entry Point</h4>
      <p>
        In <code>SampleGWTApp.java</code>, add your registration form to the
        root panel: This is the entry point class of the GWT application that
        initializes the application and adds the registration form to the root
        panel when the application starts.
      </p>
      <div className="listitems">
        <pre>{SampleGWTAppJava}</pre>
      </div>

      <h4>8. Update SampleGWTApp HTML File</h4>
      <p>
        In <code>SampleGWTApp.html</code>, set up your HTML structure: This is
        the basic HTML structure for the overall GWT application, including
        necessary meta tags and linking to the main application script.
      </p>
      <div className="listitems">
        <pre>{SampleGWTAppHtml}</pre>
      </div>
      <h4>9. Create Registration HTML File</h4>
      <p>
        In <code>registration.html</code>, set up the registration form entry
        point:
      </p>
      <div className="listitems">
        <pre>{registrationHtml}</pre>
      </div>
      <h4>10. Update Module Descriptor</h4>
      <p>
        Ensure your module descriptor <code>SampleGWTApp.gwt.xml</code> includes
        the necessary <strong>inherits</strong> and <strong>entry-point</strong>{" "}
        modules as below:
      </p>
      <div className="listitems">
        <pre>{SampleGWTAppxml}</pre>
      </div>
      <h4>11. Test Your Application</h4>
      <p>
        Run your GWT application in Super Dev Mode to see your registration form
        in action.
      </p>

      <h3>Generated UI screenshot will look like below:</h3>
      <img
        src="/gwt_uibinder_ui.png"
        alt="gwt_uibinder_ui"
        style={{ width: "500px", height: "500px" }}
      />
      <p>
        With these steps, you should have a fully functional registration form
        using GWT UiBinder. We can also expand and customize the form as needed!
      </p>
    </div>
  );
};

export default GWTUIBinders;
