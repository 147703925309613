import React from "react";

const SagaDesignPattern: React.FC = () => {
  return (
    <div>
      <div className="listitems">
        <h2>Saga Choreography</h2>
        <img
          src="/saga_choreography.png"
          alt="Saga Choreography"
        />
      </div>
      <div className="listitems">
      <h2>Saga Orchestration</h2>
        <img
          src="/saga_orchestration.png"
          alt="Saga Orchestration"
           style={{ width: "400px"}}
        />
      </div>
    </div>
  );
};

export default SagaDesignPattern;
