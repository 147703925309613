import React from "react";
const OverflowCodeSnippetRawHTML = `
.example {
  overflow: visible;
}
`;
const ContentOverflowCodeSnippetRawHTML = `
.example {
  overflow: hidden;
}
`;
const ScrollflowCodeSnippetRawHTML = `
.example {
  overflow: auto;
}
`;
const InheritCodeSnippetRawHTML = `
.child {
  overflow: inherit;
}
`;
const OverflowExampleCodeSnippetRawHTML = `
.container {
  width: 200px;
  height: 100px;
  border: 1px solid #000;
  overflow: hidden; /* or scroll/auto/visible */
}

.content {
  width: 300px; /* Larger than the container width */
  height: 150px; /* Larger than the container height */
  background-color: #ddd;
}
html
<div class="container">
  <div class="content">This content may overflow the container.</div>
</div>
  
`;

const ItemsFlexCodeSnippetRawHTML = `
.flex-container {
  display: flex;
}
`;
const FlexDirectionCodeSnippetRawHTML = `
.flex-container {
  display: flex;
  flex-direction: row; /* or column, row-reverse, column-reverse */
}
`;
const JustifyCodeSnippetRawHTML = `
.flex-container {
  display: flex;
  justify-content: space-between; /* or flex-start, flex-end, center, space-around, space-evenly */
}
`;
const AlignCodeSnippetRawHTML = `
.flex-container {
  display: flex;
  align-items: center; /* or flex-start, flex-end, baseline, stretch */
}
`;
const FlexWrapCodeSnippetRawHTML = `
.flex-container {
  display: flex;
  flex-wrap: wrap; /* or nowrap, wrap-reverse */
}
`;
const AlignContentSnippetRawHTML = `
.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between; /* or flex-start, flex-end, center, space-around, space-evenly, stretch */
}
`;
const FlexExampleSnippetRawHTML = `
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.item {
  flex: 1; /* Flex-grow, flex-shrink, flex-basis */
}
html
Copy code
<div class="container">
  <div class="item">Item 1</div>
  <div class="item">Item 2</div>
  <div class="item">Item 3</div>
</div>
`;
const Module08: React.FC = () => {
  return (
    <div>
      <h2>CSS Overflow:</h2>
      <p>
        Overflow property is used to control what happens when the content of an
        element is larger than the dimensions specified for that element. It
        comes in handy when you want to handle overflow in cases where the
        content exceeds the box model.
      </p>
      <h3>The overflow property can take the following values:</h3>
      <h4>visible:</h4>
      <p>Default value. Content is not clipped, and it may overflow the box.</p>
      <div className="listitems">
        <pre>{OverflowCodeSnippetRawHTML}</pre>
      </div>
      <h4>hidden: </h4>
      <p>Content that overflows the box is simply hidden.</p>
      <div className="listitems">
        <pre>{ContentOverflowCodeSnippetRawHTML}</pre>
      </div>
      <h4>scroll: </h4>
      <p>
        A scrollbar is added to the box to allow users to scroll the content.
      </p>
      <div className="listitems">
        <pre>{ScrollflowCodeSnippetRawHTML}</pre>
      </div>
      <h4>inherit: </h4>
      <p>Inherits the overflow value from the parent element.</p>
      <div className="listitems">
        <pre>{InheritCodeSnippetRawHTML}</pre>
      </div>
      <h3>Example:</h3>
      <div className="listitems">
        <pre>{OverflowExampleCodeSnippetRawHTML}</pre>
      </div>
      <h2> CSS Flexbox:</h2>
      <p>
        Flexbox (Flexible Box Layout) is a layout model that allows you to
        design complex layouts more efficiently and with less code than
        traditional models. It provides an easier and more efficient way to
        distribute space and align items in a container, even if their sizes are
        unknown or dynamic.
      </p>
      <h3>Basics of Flexbox:</h3>
      <h4>Container and Items:</h4>
      <p>
        To use Flexbox, you define a container element as a flex container using
        <ul>
          <li>display: flex; or</li> <li>display: inline-flex;</li>
        </ul>
        The direct children of a flex container become flex items.
      </p>
      <div className="listitems">
        <pre>{ItemsFlexCodeSnippetRawHTML}</pre>
      </div>
      <h4>Flex Direction:</h4>
      <p>
        The flex-direction property defines the main axis of the flex container.
      </p>
      <div className="listitems">
        <pre>{FlexDirectionCodeSnippetRawHTML}</pre>
      </div>
      <h4>Justify Content:</h4>
      <p>
        justify-content aligns items along the main axis of the flex container.
      </p>
      <div className="listitems">
        <pre>{JustifyCodeSnippetRawHTML}</pre>
      </div>
      <h4>Align Items:</h4>
      <p>aligns items along the cross axis of the flex container.</p>
      <div className="listitems">
        <pre>{AlignCodeSnippetRawHTML}</pre>
      </div>
      <h4>Flex Wrap:</h4>
      <p>
        By default, flex items will try to fit onto one line. You can use
        flex-wrap to allow items to wrap onto multiple lines.
      </p>
      <div className="listitems">
        <pre>{FlexWrapCodeSnippetRawHTML}</pre>
      </div>
      <h4>Align Content:</h4>
      <p>
        align-content aligns multiple lines of flex items along the cross axis.
      </p>
      <div className="listitems">
        <pre>{AlignContentSnippetRawHTML}</pre>
      </div>
      <h3>Example</h3>
      <div className="listitems">
        <pre>{FlexExampleSnippetRawHTML}</pre>
      </div>
      <p>
        In this example, the .container is a flex container, and .item elements
        are flex items. The items will be evenly distributed along the main axis
        with space between them. The flex: 1; property is used to make the items
        grow and occupy equal space
      </p>
    </div>
  );
};

export default Module08;
