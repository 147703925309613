import React from "react";

const session_inst = `
npm install express-session

OR

yarn add express-session

`;

const session_ex = `
const express = require('express');
const session = require('express-session');

const app = express();

// Configure express-session middleware
app.use(
  session({
    secret: 'your-secret-key', // Used to sign the session ID cookie
    resave: false, // Don't save session if unmodified
    saveUninitialized: false, // Don't create session until something is stored
  })
);

// Example route using sessions
app.get('/', (req, res) => {
  // Access the session data
  if (req.session.views) {
    req.session.views++;
  } else {
    req.session.views = 1;
  }

  res.send('Views: ${"req.session.views"}');
});

const PORT = 3000;
app.listen(PORT, () => {
  console.log('Server is running on http://localhost:3000');
});


`;

const ExpressSession: React.FC = () => {
  return (
    <>
      <h1>Express Session</h1>
      <p>
        'express-session' is a middleware for managing user sessions in
        Express.js web applications. Sessions are a way to persist data across
        multiple requests and responses. This is particularly useful for storing
        user authentication information and other user-specific data.
        express-session simplifies the process of handling sessions in your
        Express.js application. It enables your web application to retain
        user-specific information across multiple requests, creating a more
        personalized and interactive experience.
      </p>
      <h3>Why Use Express Session?</h3>
      <p>
        Imagine a scenario where a user logs into your website. Without Express
        Session, the server wouldn't remember this user as they navigate through
        various pages. Express Session comes to the rescue by storing session
        data, allowing you to recognize and customize the user's journey.
      </p>
      <h3>How does it work ?</h3>
      <p>Express Session employs a unique session identifier, often stored as a cookie, to associate subsequent requests from the same user. This identifier links back to a server-side session object where you can store user-specific information, such as login status, preferences, or shopping cart contents.</p>
      <h3>Installing Helmet </h3>
      <div className="listitems">
        <pre>{session_inst}</pre>
      </div>
      <h3>Basic Usage</h3>
      <div className="listitems">
        <pre>{session_ex}</pre>
      </div>
      <ul>
        <li>
          express-session is configured with a secret key (used to sign the
          session ID cookie) and some options.
        </li>
        <li>
          The middleware is added to the Express application using
          app.use(session(options)).
        </li>
        <li>
          In the route, the session data is accessed and modified. In this case,
          a simple counter (views) is incremented.
        </li>
      </ul>
      <p>
        express-session provides a flexible and configurable way to handle
        sessions. You can store session data in-memory, or you can use external
        stores like databases to manage sessions.
      </p>
      <p>
        Keep in mind that the secret key used for session signing should be kept
        secure, and you may want to store it in an environment variable rather
        than hardcoding it in your application.{" "}
      </p>
    </>
  );
};

export default ExpressSession;
