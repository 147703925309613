import React from "react";

const CreateGWTProject: React.FC = () => {
  return (
    <div>
      <h2>Set Up/Create GWT Project</h2>
      <h3>Prerequisites</h3>
      <ul>
        <li>Java Development Kit (JDK) installed on your machine.</li>
        <li>Apache Maven installed (optional but recommended).</li>
        <li>JUnit JAR file to run tests.</li>
      </ul>

      <h3>Download GWT</h3>
      <p>
        Download the latest version of GWT from the official GWT website:
        <a
          href="https://www.gwtproject.org/download.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          GWT Download
        </a>
        .
      </p>

      <h3>Steps to Create a GWT Project</h3>
      <ol>
        <li>Open a command prompt or terminal window.</li>
        <li>
          Navigate to the directory where you want to create your project.
        </li>
        <li>
          Run the following command to create a new GWT project:
          <pre>
            <code>
              webAppCreator -out SampleGWTApp -junit
              "C:\gwt-2.11.0\junit-4.13.2.jar"
              com.nipuna.codesnippets.gwt.SampleGWTApp
            </code>
          </pre>
        </li>
        <li>
          This command will generate a new GWT project named "SampleGWTApp" with
          a basic structure, including HTML, CSS, and Java files.
        </li>
        <li>
          Navigate to the newly created project directory:
          <pre>
            <code>cd SampleGWTApp</code>
          </pre>
        </li>
        <li>Open the project in your preferred IDE (e.g., IntelliJ IDEA).</li>
        <li>
          Ensure that the JUnit jar file is included in your project's build
          path.
        </li>
        <li>
          Now you can start developing your GWT application by modifying the
          generated files.
        </li>
      </ol>
    </div>
  );
};

export default CreateGWTProject;
