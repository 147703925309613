import React from "react";

const SolidPrinciples: React.FC = () => {
  return (
    <div>
      <h2>
        <strong>
          <span style={{ color: "#0000ff" }}>S</span>ingle-Responsibility
          Principle (SRP):
        </strong>
      </h2>
      <h4>A class should have one, and only one, reason to change.</h4>
      <p>
        {" "}
        An object should have only one responsibility (a reason to change). It
        is supposed to simplify modifications. The cohesion is stronger,
        dependency coupling is looser and the code is less complex.
      </p>

      <h2>
        <strong>
          <span style={{ color: "#0000ff" }}>O</span>pen-Closed Principle (OCP):
        </strong>
      </h2>
      <h4>
        Entities should be open for extension, but closed for modification.
      </h4>
      <p>
        {" "}
        Entity can be extended by adding what is needed, but it can never be
        modified. This significantly reduces the risk of breaking existing
        functionality and provides a looser coupling.
      </p>

      <h2>
        <strong>
          <span style={{ color: "#0000ff" }}>L</span>iskov Substitution
          Principle (LSP):
        </strong>
      </h2>
      <h4>Subtypes must be substitutable for their base types. </h4>
      <p>
        The principle says that it is possible to use the base type and get a
        correct result as the outcome. It can be said that the LSP confirms
        abstractions are correct.
      </p>

      <h2>
        <strong>
          <span style={{ color: "#0000ff" }}>I</span>nterface Segregation
          Principle (ISP):
        </strong>
      </h2>
      <h4>
        Classes that implement interfaces should not be forced to implement
        methods they do not use.
      </h4>
      <p>
        Big interfaces should be split into smaller ones so there are no methods
        that are not used implemented. Classes know only about methods related
        to them, providing decoupling and easier modifications.
      </p>

      <h2>
        <strong>
          <span style={{ color: "#0000ff" }}>D</span>ependency Inversion
          Principle (DIP):
        </strong>
      </h2>
      <h4>
        High-level modules should not depend on low-level modules. Both should
        depend on abstractions. Abstractions should not depend on details.
        Details should depend on abstractions.
      </h4>
      <p>
        This reduces dependencies in the code modules. Code is much easier to
        maintain if abstractions and details are isolated from each other.
      </p>
    </div>
  );
};

export default SolidPrinciples;
