import React from "react";

const cookieinstall = `
yarn add cookie-parser

OR

npm install cookie-parser

`;

const cookieparserex = `
const express = require('express');
const cookieParser = require('cookie-parser');

const app = express();
const port = 3000;

// Middleware to parse cookies
app.use(cookieParser());

//routes and other middleware go here...

// Start the server
app.listen(port, () => {
  console.log('Server is running on port 3000');
});
`;

const cookieexample = `
const express = require('express');
const cookieParser = require('cookie-parser');

const app = express();
const port = 3000;

// Middleware to parse cookies
app.use(cookieParser());

// Route to set a cookie
app.get('/set-cookie', (req, res) => {
  res.cookie('user', 'John Doe');
  res.send('Cookie set!');
});

// Route to read a cookie
app.get('/read-cookie', (req, res) => {
  const userName = req.cookies.user || 'Guest';
  res.send('Hello, {userName}!');
});

// Start the server
app.listen(port, () => {
  console.log('Server is running on port 3000');
});

`;

const CookieParser: React.FC = () => {
  return (
    <>
      <h2>Cookie-Parser</h2>
      <p>
        Imagine building a web application where user data needs to be stored,
        retrieved, and managed effortlessly. This is where the magic of Cookie
        Parser comes into play when working with Express.js. In simple terms,
        Cookie Parser is like the friendly baker in the digital world, handling
        cookies with ease.
      </p>
      <h3>1.What is it:</h3>
      <p>
        'cookie-parser' is a middleware in Express.js that parses cookies
        attached to the client's request object. It is an Express middleware
        that simplifies the handling of cookies in your web applications.
        Cookies, small pieces of data stored on the user's browser, are commonly
        used for session management, user authentication, and tracking user
        behavior.
      </p>
      <p>
        It extracts cookie data, parses it, and makes it accessible through the
        req.cookies object.
      </p>
      <h3>2.Why to Use:</h3>
      <p>
        Cookies are small pieces of data sent from a server and stored on the
        client's browser.
      </p>
      <p>
        cookie-parser simplifies working with cookies in Express applications by
        parsing and handling the cookie-related tasks. It enables reading and
        setting cookies easily.
      </p>
      <p>With just a few lines of code, you can seamlessly integrate Cookie Parser into your Express application.</p>
      <h3>3.How to Use:</h3>
      <div className="listitems">
        <pre>{cookieinstall}</pre>
      </div>
      <p>Integrate cookie-parser into the Express app:</p>
      <div className="listitems">
        <pre>{cookieparserex}</pre>
      </div>
      <h3>4.Example Usage:</h3>
      <div className="listitems">
        <pre>{cookieexample}</pre>
      </div>
    </>
  );
};

export default CookieParser;
