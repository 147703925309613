import React from "react";

const HttpClientCodeSnippetRawHTML = `

@Service
@Slf4j
@RequiredArgsConstructor
public class LogService {

    private final HttpClient httpClient;
    private final ObjectMapper objectMapper;

    public void auditLog(String auditUrl, String logs,
                         String key) {
        try {
            final AuditLogRequest auditLogRequest = AuditLogRequest.builder()
                    .log(logs)
                    .key(key)
                    .build();

            final URI uri = new URI(auditUrl);

            httpClient.send(
                    HttpRequest.newBuilder(uri)
                            .header("Content-Type", APPLICATION_JSON_VALUE)
                            .header("Accept", APPLICATION_JSON_VALUE)
                            .POST(HttpRequest.BodyPublishers.ofString(objectMapper.writeValueAsString(auditLogRequest)))
                            .build(),
                    HttpResponse.BodyHandlers.ofString()
            );
        } catch (URISyntaxException | IOException | InterruptedException ex) {
            log.error("Failed to audit the event", ex);
        }
    }
}
`;

const HttpClient: React.FC = () => {
  return (
    <div>
      <h2>Http Client</h2>
      <p>
        The HttpClient class in Java is part of the java.net module and was
        introduced in Java 11 as a modern replacement for the older
        HttpURLConnection. It provides a more flexible and efficient API for
        sending HTTP requests and receiving HTTP responses.
      </p>
      <h3>Code Snippet :</h3>
      <div className="listitems">
        <pre>{HttpClientCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default HttpClient;
