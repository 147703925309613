import React from "react";

const NginxWindowsCommandRawHTML = `
nginx -s stop	fast shutdown
nginx -s quit	graceful shutdown
nginx -s reload	changing configuration, starting new worker processes with a new configuration, graceful shutdown of old worker processes
nginx -s reopen	re-opening log files
`;

const Ngrok: React.FC = () => {
  return (
    <div>
      <h2>Nginx</h2>
      <h3>Nginx windows command:</h3>
      <div className="listitems">
        <pre>{NginxWindowsCommandRawHTML}</pre>
      </div>
    </div>
  );
};

export default Ngrok;
