import React from "react";

const StylesCss = `
h1 {
  color: green;
}

.input-block {
  margin-top: 1em;
}

#messages {
  height: 20em;
  overflow: auto;
  margin-top: 1em;
}
`;

const appTs = `
(function () {
  let ws: WebSocketExt;
  const HEARTBEAT_TIMEOUT = ((1000 * 5) + (1000 * 1)); // 5 + 1 second
  const HEARTBEAT_VALUE = 1;
  const messages = <HTMLElement>document.getElementById('messages');
  const wsOpen = <HTMLButtonElement>document.getElementById('ws-open');
  const wsClose = <HTMLButtonElement>document.getElementById('ws-close');
  const wsSend = <HTMLButtonElement>document.getElementById('ws-send');
  const wsInput = <HTMLInputElement>document.getElementById('ws-input');

  function showMessage(message: string) {
      if (!messages) {
          return;
      }

      messages.textContent += '\ndollarSymbol{message}';
      messages.scrollTop = messages?.scrollHeight;
  }

  function closeConnection() {
      if (!!ws) {
          ws.close();
      }
  }

  function heartbeat() {
      if (!ws) {
          return;
      } else if (!!ws.pingTimeout) {
          clearTimeout(ws.pingTimeout);
      }

      ws.pingTimeout = setTimeout(() => {
          ws.close();

          // business logic for deciding whether or not to reconnect
      }, HEARTBEAT_TIMEOUT);

      const data = new Uint8Array(1);

      data[0] = HEARTBEAT_VALUE;

      ws.send(data);
  }

  function isBinary(obj: any) {
      return typeof obj === 'object' && Object.prototype.toString.call(obj) === '[object Blob]';
  }

  wsOpen.addEventListener('click', () => {
      closeConnection();

      ws = new WebSocket('ws://localhost:3000') as WebSocketExt;

      ws.addEventListener('error', () => {
          showMessage('WebSocket error');
      });

      ws.addEventListener('open', () => {
          showMessage('WebSocket connection established');
      });

      ws.addEventListener('close', () => {
          showMessage('WebSocket connection closed');

          if (!!ws.pingTimeout) {
              clearTimeout(ws.pingTimeout);
          }
      });

      ws.addEventListener('message', (msg: MessageEvent<string>) => {
          if (isBinary(msg.data)) {
              heartbeat();
          } else {
              showMessage('Received message: dollarSymbol{msg.data}');
          }
      });
  });

  wsClose.addEventListener('click', closeConnection);

  wsSend.addEventListener('click', () => {
      const val = wsInput?.value;

      if (!val) {
          return;
      } else if (!ws) {
          showMessage('No WebSocket connection');
          return;
      }

      ws.send(val);
      showMessage('Sent "dollarSymbol{val}"');
      wsInput.value = '';
  });
})();
`;

const routersIndexTs = `
import express, { Application, Request, Response, NextFunction } from 'express';
import { json } from 'body-parser';
import { resolve } from 'path';
import api from './api';

export default function configure(app: Application) {
    app
        .get('/', (req, res, next) => {
            res.sendFile(resolve(__dirname, '../index.html'));
        })
        .use(express.static('public'))
        .use(json())
        .use('/api', api())
        .use('/error', (req, res, next) => {
            next(new Error('Other Error'));
        })
        .use((req, res, next) => {
            next(new Error('Not Found'));
        })
        .use((error: Error, req: Request, res: Response, next: NextFunction) => {
            switch (error.message) {
                case 'Not Found':
                    res.sendFile(resolve(__dirname, '../notfound.html'));
                    return;
            }

            res.sendFile(resolve(__dirname, '../error.html'));
        });
}
`;

const routersApiTs = `
import { Router } from 'express';
import users from './user';

export default function api() {
    const router = Router();

    router
        .use((req, res, next) => {
            if (!req.body) {
                next(new Error('Bad request'));
                return;
            }

            next();
        })
        .use('/v1', apiV1())
        .use((req, res, next) => {
            res.json({
                error: 'Invalid route',
            });
        });

    return router;
}

function apiV1() {
    const router = Router();

    router
        .use((req, res, next) => {
            console.log('API V1');
            next();
        })
        .use('/users', users());

    return router;
}
`;

const routersUserTs = `
import { Router } from 'express';

export default function users() {
    const router = Router();

    router
        .get('/', (req, res, next) => {
            res.json({
                id: 1,
                firstname: 'Matt',
                lastname: 'Morgan',
            });
        })
        .post(['/', '/:id'], (req, res, next) => {
            const params = req.params;
            const id = params.id;
            const queryParams = req.query;

        });

    return router;
}
`;
const socketsIndexTs = `
import { Server } from 'http';
import { WebSocketServer, WebSocket } from 'ws';

const HEARTBEAT_INTERVAL = 1000 * 5; // 5 seconds
const HEARTBEAT_VALUE = 1;

function onSocketPreError(e: Error) {
    console.log(e);
}

function onSocketPostError(e: Error) {
    console.log(e);
}

function ping(ws: WebSocket) {
    ws.send(HEARTBEAT_VALUE, { binary: true });
}

export default function configure(s: Server) {
    const wss = new WebSocketServer({ noServer: true });

    s.on('upgrade', (req, socket, head) => {
        socket.on('error', onSocketPreError);

        // perform auth
        if (!!req.headers['BadAuth']) {
            socket.write('HTTP/1.1 401 Unauthorized\r\n\r\n');
            socket.destroy();
            return;
        }

        wss.handleUpgrade(req, socket, head, (ws) => {
            socket.removeListener('error', onSocketPreError);
            wss.emit('connection', ws, req);
        });
    });

    wss.on('connection', (ws, req) => {
        ws.isAlive = true;

        ws.on('error', onSocketPostError);

        ws.on('message', (msg, isBinary) => {
            if (isBinary && (msg as any)[0] === HEARTBEAT_VALUE) {
                 console.log('pong');
                ws.isAlive = true;
            } else {
                wss.clients.forEach((client) => {
                    if (client.readyState === WebSocket.OPEN) {
                        client.send(msg, { binary: isBinary });
                    }
                });
            }
        });

        ws.on('close', () => {
            console.log('Connection closed');
        });
    });

    const interval = setInterval(() => {
        // console.log('firing interval');
        wss.clients.forEach((client) => {
            if (!client.isAlive) {
                client.terminate();
                return;
            }

            client.isAlive = false;
            ping(client);
        });
    }, HEARTBEAT_INTERVAL);

    wss.on('close', () => {
        clearInterval(interval);
    });
}
`;
const typingsIndexTs = `
interface WebSocketExt extends WebSocket {
  pingTimeout: NodeJS.Timeout;
}
`;
const typingsWsTs = `
import { WebSocket } from "ws";

declare module 'ws' {
    interface WebSocket {
        isAlive: boolean;
    }
}
`;
const errorHtml = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Express Basics Error</title>
</head>
<body>
    <h1>There was an unfortunate error :(</h1>
</body>
</html>
`;
const indexHtml = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Web Sockets</title>
    <link rel="stylesheet" href="/css/styles.css">
</head>
<body>
    <h1>Test:</h1>
    <button id="ws-open">
        Open Connection
    </button>
    <button id="ws-close">
        Close Connection
    </button>
    <div class="input-block">
        <input type="text" id="ws-input">
        <button id="ws-send">
            Send message
        </button>
    </div>
    <pre id="messages"></pre>
    <script src="/js/app.js"></script>
</body>
</html>
`;
const notFoundHtml = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Express Basics Not Found</title>
</head>
<body>
    <h1>Not Found :(</h1>
</body>
</html>
`;
const indexTs = `
import express from 'express';
import configureRoutes from './routers';
import configureSockets from './sockets';

const app = express();
const port = process.env.PORT || 3000;

configureRoutes(app);

console.log('Attempting to run server on port dollarSymbol{port}');

configureSockets(app.listen(port, () => {
    console.log('Listening on port dollarSymbol{port}');
}));
`;

const packageJson = `
"scripts": {
  "ts": "tsc -p .",
  "start": "npm run ts && npm run start:server",
  "start:server": "node index.js",
  "dev": "tsc-watch -p tsconfig.json --onSuccess \"npm run start:server\"",
  "test": "echo \"Error: no test specified\" && exit 1"
}
`;

const WebSocket: React.FC = () => {
  return (
    <div>
      <h2>Web Socket</h2>
      <p>
        WebSocket is a technology that allows two-way communication between a
        web browser and a server. It enables messages to be sent and received
        simultaneously, making interactions faster and more responsive. With
        WebSocket, there's less unnecessary data sent back and forth, which
        helps keep things running smoothly. It's like having a direct phone line
        between the browser and server, so they can talk to each other without
        delays or interruptions.
      </p>
      <h3>Creating simple websocker server:</h3>
      <ol>
        <li>
          Create a new Node.js project: <code>npm init -y</code>
        </li>
        <li>
          Install the WebSocket library: <code>npm i --save ws @types/ws</code>
        </li>
        <li>
          Install Express for serving the web page (optional):{" "}
          <code>npm install express</code>
        </li>
        <li>Create your WebSocket server code</li>
        <li>
          To run the server, use: <code>npm run dev</code>
        </li>
      </ol>
      <p>
        Here's a simple example of creating a WebSocket server with different
        files with folder paths in code snippets:
      </p>
      <p>
        <strong>public/css/styles.css</strong>
      </p>
      <p>
        This class holds CSS styles for the web page. It defines styles for
        headings (h1), input blocks (.input-block), and a message container
        (#messages).
      </p>
      <div className="listitems">
        <pre>{StylesCss}</pre>
      </div>
      <p>
        <strong>public/js/app.ts</strong>
      </p>
      <p>
        This script handles the client-side WebSocket functionality. It sets up
        event listeners for opening, closing, and sending messages over
        WebSocket connections. It also includes functions for displaying
        messages and managing heartbeat signals to keep the connection alive.
      </p>
      <div className="listitems">
        <pre>{appTs}</pre>
      </div>
      <p>
        <strong>routers/api.ts</strong>
      </p>
      <p>
        This file defines API routes for the Express server. It sets up routes
        for API versioning and includes middleware for error handling and
        routing to specific API endpoints.
      </p>
      <div className="listitems">
        <pre>{routersApiTs}</pre>
      </div>
      <p>
        <strong>routers/index.ts</strong>
      </p>
      <p>
        This file configures routes for the Express server. It serves static
        files, such as HTML, CSS, and JavaScript files, and defines error
        handling middleware for handling 404 and other errors.
      </p>
      <div className="listitems">
        <pre>{routersIndexTs}</pre>
      </div>
      <p>
        <strong>routers/user.ts</strong>
      </p>
      <p>
        This file defines routes for user-related API endpoints. It includes
        routes for retrieving user data (GET) and handling user updates (POST).
      </p>
      <div className="listitems">
        <pre>{routersUserTs}</pre>
      </div>
      <p>
        <strong>sockets/index.ts</strong>
      </p>
      <p>
        This file configures WebSocket functionality on the server-side. It sets
        up WebSocket connections, handles incoming messages, manages heartbeats
        to keep connections alive, and cleans up inactive connections.
      </p>
      <div className="listitems">
        <pre>{socketsIndexTs}</pre>
      </div>
      <p>
        <strong>typings/index.d.ts</strong>
      </p>
      <p>
        This file defines custom TypeScript typings. It extends the existing
        WebSocket interface to include an additional property called
        pingTimeout. This property is used for managing timeouts related to
        WebSocket ping messages.
      </p>
      <div className="listitems">
        <pre>{typingsIndexTs}</pre>
      </div>
      <p>
        <strong>typings/ws.d.ts</strong>
      </p>
      <p>
        Similar to typingsIndexTs, this file defines custom TypeScript typings.
        It extends the WebSocket interface to include an extra property named
        isAlive. This property helps manage the WebSocket connection's state by
        indicating whether the connection is active or not.
      </p>
      <div className="listitems">
        <pre>{typingsWsTs}</pre>
      </div>
      <p>
        <strong>error.html</strong>
      </p>
      <p>
        {" "}
        This HTML file represents an error page for scenarios where there is an
        internal server error. It provides a simple HTML structure with an
        appropriate error message. This page typically displays when the server
        encounters an unexpected error that prevents it from fulfilling the
        client's request.
      </p>
      <div className="listitems">
        <pre>{errorHtml}</pre>
      </div>
      <p>
        <strong>index.html</strong>
      </p>
      <p>
        This HTML file serves as the main page for the web application. It
        provides a basic structure for the web page, including elements like
        buttons and input fields for interacting with WebSocket connections.
        This page typically displays when users access the root URL of the
        application.
      </p>
      <div className="listitems">
        <pre>{indexHtml}</pre>
      </div>
      <p>
        <strong>notfound.html</strong>
      </p>
      <p>
        This HTML file represents an error page for scenarios where the
        requested page or resource is not found (HTTP 404 error). It provides a
        simple HTML structure with a message indicating that the requested
        resource could not be found. This page typically displays when users
        access a URL that does not correspond to any existing route or resource
        in the application.
      </p>
      <div className="listitems">
        <pre>{notFoundHtml}</pre>
      </div>
      <p>
        <strong>index.ts</strong>
      </p>
      <p>
        This is the entry point for the server-side code. It configures an
        Express app, sets up routes, and starts the server listening on a
        specified port.
      </p>
      <div className="listitems">
        <pre>{indexHtml}</pre>
      </div>
      <p>
        <strong>Note:</strong> dollarSymbol text in above code snippets should
        be replaced with dollar symbol
      </p>
      <p>
        We need scripts block in the package.json file as below which define commands for running
        TypeScript compilation, starting the server, running in development mode
        with automatic recompilation, and testing (although the test script
        currently only outputs an error message).
      </p>
      <div className="listitems">
        <pre>{packageJson}</pre>
      </div>
      <p>
        After starting the server, open the URL http://localhost:3000/ in
        multiple browser tabs. Then, establish connections in each tab and
        exchange messages between them to test the WebSocket functionality.
      </p>
    </div>
  );
};

export default WebSocket;
