import React from "react";

const soapRequestJs = `

import axios from "axios";

const soapRequest = '
<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
  <soap:Body>
    <NumberToWords xmlns="http://www.dataaccess.com/webservicesserver/">
      <ubiNum>500</ubiNum>
    </NumberToWords>
  </soap:Body>
</soap:Envelope>
';

const url = "https://www.dataaccess.com/webservicesserver/NumberConversion.wso";

axios.post(url, soapRequest, {
    headers: {
      "Content-Type": "text/xml",
    },
    timeout: 9000
  })
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.error("Error:", error);
  });
`;

const packageJsonScript = `
{
  "name": "soap",
  "version": "1.0.0",
  "type": "module",
  "main": "index.js",
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "dependencies": {
    "@types/axios": "^0.14.0",
    "@types/node": "^20.12.5",
    "axios": "^1.6.8"
  },
  "description": "",
  "devDependencies": {
    "ts-node": "^10.9.2",
    "typescript": "^5.4.4"
  }
}

`;
const soapRequestExtractJs = `
import * as xml2js from 'xml2js';

// Sample SOAP response XML
const soapResponse = '
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://service.example.com">
   <soapenv:Header/>
   <soapenv:Body>
      <ser:YourSOAPResponse>
         <data>
            <name>John Doe</name>
            <age>30</age>
            <email>john@example.com</email>
         </data>
      </ser:YourSOAPResponse>
   </soapenv:Body>
</soapenv:Envelope>
';

// Parse the SOAP response XML
xml2js.parseString(soapResponse, (err, result) => {
    if (err) {
        console.error('Error parsing SOAP response:', err);
    } else {
        // Extract data from the parsed XML object
        const data = result['soapenv:Envelope']['soapenv:Body'][0]['ser:YourSOAPResponse'][0]['data'][0];
        const extractedData = {
            name: data['name'][0],
            age: parseInt(data['age'][0]),
            email: data['email'][0]
        };

        // Do something with the extracted data
        console.log('Extracted data:', extractedData);
    }
});
`;
const installXml2jsCommand = `
npm install xml2js
`;

const xmlToObjextJs = `
import { parseString } from 'xml2js';

function xmlToObject(xml) {
    let result;
    parseString(xml, (err, res) => {
        if (err) {
            console.error('Error parsing XML:', err);
            result = null;
        } else {
            const envelope = res['soap:Envelope'];
            const body = envelope['soap:Body'][0];
            const searchAccount = body['m:SearchAccount'][0];
            const keys = Object.keys(searchAccount);
            result = {};

            keys.forEach(key => {
                const actualKey = key.split(':')[1];
                result[actualKey] = searchAccount[key][0];
            });
        }
    });
    return result;
}

const soapXml = '<?xml version="1.0"?>
<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope/" soap:encodingStyle="http://www.w3.org/2003/05/soap-encoding">
  <soap:Body xmlns:m="http://www.example.org/stock">
    <m:SearchAccount>
      <m:DocType>finance</m:DocType>
      <m:Name>FWG</m:Name>
      <m:Priority>1</m:Priority>
      <m:OrgType>finance</m:OrgType>
    </m:SearchAccount>
  </soap:Body>
</soap:Envelope>';

const jsObject = xmlToObject(soapXml);
console.log(jsObject);

`;

const ObjectToSoapXmlJs = `
function objectToXml(obj) {
  let xml = '<?xml version="1.0"?>\n';
  xml += '<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope/" soap:encodingStyle="http://www.w3.org/2003/05/soap-encoding">\n';
  xml += '<soap:Body xmlns:m="http://www.example.org/stock">\n';
  xml += '<m:SearchAccount>\n';
  
  for (const key in obj) {
      xml += '<m:dollarSign{key}>dollarSign{obj[key]}</m:dollarSign{key}>\n';
  }

  xml += '</m:SearchAccount>\n';
  xml += '</soap:Body>\n';
  xml += '</soap:Envelope>';

  return xml;
}

const requestData = {
  docType: 'finance',
  name: 'FWG',
  priority: 1,
  orgType: 'finance'
};

const xmlData = objectToXml(requestData);
console.log(xmlData);

`;

const SoapApi: React.FC = () => {
  return (
    <div>
      <h2>SOAP Api</h2>
      <p>
        SOAP stands for Simple Object Access Protocol. It is a protocol
        specification for exchanging structured information in the
        implementation of web services. SOAP uses XML (Extensible Markup
        Language) for its message format and relies on other application layer
        protocols, most notably HTTP (Hypertext Transfer Protocol) and SMTP
        (Simple Mail Transfer Protocol), for message negotiation and
        transmission.
      </p>
      <p>
        SOAP was developed to provide a standard way of communicating between
        different platforms and languages, allowing for interoperability between
        various systems. It defines a set of rules for structuring messages,
        including the format of the message envelope, headers, and body.
      </p>
      <h3>
        {" "}
        Process of creating a JavaScript project that sends a SOAP request and
        retrieves the response:
      </h3>
      <p>
        For this, we'll use JavaScript and a library called axios to make HTTP
        requests. Follow these steps:
      </p>
      <p>
        1. <strong>Create a New Directory:</strong> Start by creating a new
        directory for your project. You can name it whatever you like.
      </p>
      <p>
        2. <strong>Initialize NPM:</strong> Navigate to your project directory
        in the terminal and run <strong>npm init -y </strong>to initialize a new
        npm project with default settings.
      </p>
      <p>
        3. <strong>Install Axios:</strong> Install the Axios library which will
        help us make HTTP requests. Run npm install axios to install Axios.
      </p>
      <p>
        4.<strong>Create a JavaScript File:</strong> Create a JavaScript file
        inside your project directory. You can name it soapRequest.js or
        anything else you prefer.
      </p>
      <p>
        5. <strong>Write JavaScript Code:</strong> Open the JavaScript file you
        created and write the following code:
      </p>
      <div className="listitems">
        <pre>{soapRequestJs}</pre>
      </div>
      <p>
        Replace soapRequest and url in the code with your actual SOAP request
        body and the URL of your SOAP service.
      </p>
      <p>6.Package.json file configuration looks as below:</p>
      <div className="listitems">
        <pre>{packageJsonScript}</pre>
      </div>
      <p>
        7. <strong>Run the Script:</strong> Save the file and then run it using
        Node.js. In the terminal, navigate to your project directory and run{" "}
        <strong>node soapRequest.js</strong>. This will execute the script,
        sending the SOAP request and logging the response to the console.
      </p>
      <p>
        That's it! You've created a JavaScript project that sends a SOAP request
        and retrieves the response using Axios.
      </p>
      <h3>Extracting SOAP request data into an object: </h3>
      <p>
        Below is a step-by-step process for extracting SOAP request data into an
        object using the xml2js library:
      </p>
      <p>
        Firstly,we need to install xml2js library using npm. You can do this by
        running the following command in your terminal:
      </p>
      <div className="listitems">
        <pre>{installXml2jsCommand}</pre>
      </div>
      <p>
        Below is the js code snippet for extracting SOAP request data into an
        object and in this example, the extracted data object is logged to the
        console:
      </p>
      <div className="listitems">
        <pre>{soapRequestExtractJs}</pre>
      </div>
      <h3>Extracting SOAP request data into an object: </h3>
      <p>
        Below is the JavaScript code snippet to
        extracts data from a SOAP request XML string into a JavaScript object
      </p>
      <div className="listitems">
        <pre>{xmlToObjextJs}</pre>
      </div>
      <p>
        This javaScript code defines a function called xmlToObject that parses a
        SOAP request XML string into a JavaScript object. It utilizes the
        parseString function from the xml2js library to asynchronously parse the
        XML string, handling any parsing errors if they occur. Upon successful
        parsing, it extracts specific elements from the XML, including the SOAP
        envelope, body, and search account details. It then converts this
        extracted data into a JavaScript object, removing namespace prefixes
        from keys for clarity. Finally, it returns this object, providing a
        convenient way to extract and work with SOAP request data in a
        JavaScript environment. The code includes an example SOAP XML string for
        testing and logs the resulting JavaScript object to the console.
      </p>
      <h3>Generation SOAP request from object: </h3>
      <p>
        Below is the JavaScript code snippet that generates a
        SOAP XML request from a given JavaScript object
      </p>
      <div className="listitems">
        <pre>{ObjectToSoapXmlJs}</pre>
      </div>
      <p>
        This javaScript code defines a function called objectToXml that generates a
        SOAP XML request from a given JavaScript object. It constructs the SOAP
        envelope, body, and specific request elements based on the object's
        properties. The function iterates over the properties of the input
        object, appending each property as a child element within the
        'm:SearchAccount' element in the SOAP body. It dynamically generates XML
        tags using the property names and values from the object. After
        constructing the SOAP XML request, the function returns the resulting
        XML string. In the example provided, the requestData object contains
        properties such as docType, name, priority, and orgType, representing
        data to be included in the SOAP request. The xmlData variable stores the
        generated SOAP XML request, which is then logged to the console for
        testing or further processing. This function provides a convenient way
        to generate SOAP requests dynamically from JavaScript objects,
        facilitating integration with SOAP-based APIs or services.
      </p>
    </div>
  );
};

export default SoapApi;
