import React from "react";

const dockerCompose = `
version: '3.8'

services:
  dynamodb-local:
    image: amazon/dynamodb-local
    container_name: dynamodb-local
    ports:
      - "8000:8000"
    volumes:
      - dynamodb_data:/home/dynamodblocal/data

  dynamodb-admin:
    image: aaronshaf/dynamodb-admin
    container_name: dynamodb-admin
    ports:
      - "8001:8001"
    environment:
      - DYNAMO_ENDPOINT=http://dynamodb-local:8000
    depends_on:
      - dynamodb-local

volumes:
  dynamodb_data:

`;

const dynamoDBLocalConfig = `
package com.nipuna.dynamoDBLocal.config;

import com.amazonaws.client.builder.AwsClientBuilder;
import com.amazonaws.services.dynamodbv2.AmazonDynamoDB;
import com.amazonaws.services.dynamodbv2.AmazonDynamoDBClientBuilder;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBMapper;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBMapperConfig;
import org.springframework.context.annotation.Bean;
import org.springframework.context.annotation.Configuration;

@Configuration
public class CustomDynamoDBConfig {

    @Bean
    public AmazonDynamoDB customAmazonDynamoDB() {
        return AmazonDynamoDBClientBuilder.standard()
                .withEndpointConfiguration(new AwsClientBuilder.EndpointConfiguration("http://localhost:8000", null))
                .build();
    }

    @Bean
    public DynamoDBMapperConfig customDynamoDBMapperConfig() {
        return DynamoDBMapperConfig.DEFAULT;
    }

    @Bean
    public DynamoDBMapper customDynamoDBMapper(AmazonDynamoDB customAmazonDynamoDB, DynamoDBMapperConfig config) {
        return new DynamoDBMapper(customAmazonDynamoDB, config);
    }
}

`;

const customerClass = `
package com.nipuna.dynamoDBLocal.model;

import com.amazonaws.services.dynamodbv2.datamodeling.*;

@DynamoDBTable(tableName = "customer")
public class Customer {

    private String customerId;
    private String customerName;
    private String phone;
    private String email;

    @DynamoDBHashKey(attributeName = "customer_id")
    public String getCustomerId() {
        return customerId;
    }

    public void setCustomerId(String customerId) {
        this.customerId = customerId;
    }

    @DynamoDBAttribute(attributeName = "customer_name")
    public String getCustomerName() {
        return customerName;
    }

    public void setCustomerName(String customerName) {
        this.customerName = customerName;
    }

    @DynamoDBAttribute(attributeName = "phone")
    public String getPhone() {
        return phone;
    }

    public void setPhone(String phone) {
        this.phone = phone;
    }

    @DynamoDBAttribute(attributeName = "email")
    public String getEmail() {
        return email;
    }

    public void setEmail(String email) {
        this.email = email;
    }
}
`;

const customerService = `
package com.nipuna.dynamoDBLocal.service;

import com.amazonaws.services.dynamodbv2.AmazonDynamoDB;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBMapper;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBScanExpression;
import com.nipuna.dynamoDBLocal.model.Customer;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.stereotype.Service;

import java.util.List;

@Service
public class CustomerService {

    @Autowired
    private AmazonDynamoDB customAmazonDynamoDB;

    @Autowired
    private DynamoDBMapper customDynamoDBMapper;

    public Customer saveCustomer(Customer customer) {
        customDynamoDBMapper.save(customer);
        return customer;
    }

    public List<Customer> getAllCustomers() {
        return customDynamoDBMapper.scan(Customer.class, new DynamoDBScanExpression());
    }

    public Customer getCustomerById(String customerId) {
        return customDynamoDBMapper.load(Customer.class, customerId);
    }

    public void deleteCustomer(String customerId) {
        Customer customer = customDynamoDBMapper.load(Customer.class, customerId);
        if (customer != null) {
            customDynamoDBMapper.delete(customer);
        } else {
            throw new RuntimeException("Customer with ID " + customerId + " not found.");
        }
    }
}
`;

const CustomerController = `
package com.nipuna.dynamoDBLocal.controller;

import com.nipuna.dynamoDBLocal.model.Customer;
import com.nipuna.dynamoDBLocal.service.CustomerService;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.web.bind.annotation.*;

import java.util.List;

@RestController
@RequestMapping("/customers")
public class CustomerController {

    @Autowired
    private CustomerService customerService;

    @PostMapping
    public Customer createCustomer(@RequestBody Customer customer) {
        return customerService.saveCustomer(customer);
    }

    @GetMapping
    public List<Customer> getAllCustomers() {
        return customerService.getAllCustomers();
    }

    @GetMapping("/{id}")
    public Customer getCustomerById(@PathVariable("id") String customerId) {
        return customerService.getCustomerById(customerId);
    }

    @DeleteMapping("/{id}")
    public void deleteCustomer(@PathVariable("id") String customerId) {
        customerService.deleteCustomer(customerId);
    }
}
`;

const tableController = `
package com.nipuna.dynamoDBLocal.controller;

import com.amazonaws.services.dynamodbv2.AmazonDynamoDB;
import com.nipuna.dynamoDBLocal.service.DynamoDBTableService;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.web.bind.annotation.*;

import java.util.List;

@RestController
@RequestMapping("/tables")
public class TableController {

    @Autowired
    private AmazonDynamoDB customAmazonDynamoDB;

    @Autowired
    private DynamoDBTableService dynamoDBService;

    @GetMapping
    public List<String> listTables() {
        return customAmazonDynamoDB.listTables().getTableNames();
    }

    @PostMapping("/{tableName}")
    public void createTable(@PathVariable String tableName) {
        dynamoDBService.createTable(tableName);
    }
}
`;

const tableService = `
package com.nipuna.dynamoDBLocal.service;

import com.amazonaws.services.dynamodbv2.AmazonDynamoDB;
import com.amazonaws.services.dynamodbv2.model.*;

import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.stereotype.Service;

@Service
public class DynamoDBTableService {

    @Autowired
    private AmazonDynamoDB customAmazonDynamoDB;

    public void createTable(String tableName) {
        CreateTableRequest request = new CreateTableRequest()
                .withTableName(tableName)
                .withKeySchema(new KeySchemaElement(tableName + "_id", KeyType.HASH))
                .withAttributeDefinitions(
                        new AttributeDefinition(tableName + "_id", ScalarAttributeType.S)
                )
                .withProvisionedThroughput(new ProvisionedThroughput(5L, 5L));

        customAmazonDynamoDB.createTable(request);
    }
}
`;

const DynamoDBLocal: React.FC = () => {
  return (
    <div>
      <h2>DynamoDB Local</h2>
      <p>
        DynamoDB Local is a downloadable version of DynamoDB that enables you to
        develop and test applications without needing an internet connection. It
        mimics the behavior of the DynamoDB service, making it useful for local
        development and testing. Running DynamoDB Local on Docker further
        simplifies the setup process.
      </p>
      <h3>Docker Compose File:</h3>
      <p>Sets up DynamoDB Local and DynamoDB Admin UI</p>
      <div className="listitems">
        <pre>{dockerCompose}</pre>
      </div>
      <p>
        The Docker Compose file defines two services:
        <strong>dynamodb-local</strong>(Runs the local instance of DynamoDB),{" "}
        <strong>dynamodb-admin</strong>(Provides a web-based UI to interact with
        DynamoDB).
      </p>
      <h3>Spring Configuration Class</h3>
      <p>Configures connection to DynamoDB Local.</p>
      <p>
        The <strong>CustomDynamoDBConfig</strong> class configures the
        connection to DynamoDB Local.
      </p>
      <div className="listitems">
        <pre>{dynamoDBLocalConfig}</pre>
      </div>
      <h3>Customer Model Class</h3>
      <p>
        The <strong>Customer</strong> class is a DynamoDB entity model
        representing a table structure.
      </p>
      <div className="listitems">
        <pre>{customerClass}</pre>
      </div>
      <h3>Customer Service Class</h3>
      <p>
        The <strong>CustomerService</strong> class provides methods to interact
        with the Customer table.
      </p>
      <div className="listitems">
        <pre>{customerService}</pre>
      </div>
      <h3>Customer Controller Class</h3>
      <p>
        The <strong>CustomerController</strong> class exposes REST endpoints to
        interact with the Customer service.
      </p>
      <div className="listitems">
        <pre>{CustomerController}</pre>
      </div>
      <h3>Table Controller Class</h3>
      <p>
        The <strong>TableController</strong> class provides REST endpoints to
        list and create tables dynamically.
      </p>
      <div className="listitems">
        <pre>{tableController}</pre>
      </div>
      <h3>DynamoDB Table Service Class</h3>
      <p>
        The <strong>DynamoDBTableService</strong> class provides a method to
        create tables dynamically.
      </p>
      <div className="listitems">
        <pre>{tableService}</pre>
      </div>
      <p>
        This setup allows you to interact with DynamoDB Local, create and manage
        tables dynamically, and perform CRUD operations on the Customer table
        through RESTful APIs. You can view and manage your tables and data using
        the dynamodb-admin UI running at <a>http://localhost:8001</a>.
      </p>
    </div>
  );
};

export default DynamoDBLocal;
