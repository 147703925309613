import React from "react";

const AccessingECSApisUsingVpcLinks: React.FC = () => {
  return (
    <div>
      <div className="listitems">
        <img
          src="/access_ecs_apis_using_vpc_links.png"
          alt="Accessing ECS APis Using VPC Links"
          style={{ width: "1000px"}}
        />
      </div>
    </div>
  );
};

export default AccessingECSApisUsingVpcLinks;
