import React from "react";

const installNunjucksHTML = `
npm install nunjucks
`;

const initialiseProjectHTML = `
npm init
`;

const installDependenciesHTML = `
npm install typescript express nunjucks @types/express --save-dev
`;

const initialiseTypescriptHTML = `
npx tsc --init
`;

const projectFolderStructureHTML = `
- src/
  - routes/
    - about.ts
    - home.ts
  - views/
    - about.html
    - home.html
  - app.ts
- tsconfig.json
- package.json
`;

const tsConfigFileHTML = `
{
  "compilerOptions": {
    "module": "NodeNext",
    "esModuleInterop": true,
    "target": "ES6",
    "moduleResolution": "NodeNext",
    "removeComments": true,
    "sourceMap": true,

    "outDir": "./dist",
    "resolveJsonModule": true,

    "skipLibCheck": true,
    "allowJs": true,

    "strict": false
  },
  "include": ["src"],
  "exclude": ["node_modules", "dist/*", "src/static"],
  "lib": ["es6"]
}

`;

const configureNunjucksHTML = `

// src/app.ts

import express from 'express'
import homeRoute from './routes/home';
import aboutRoute from './routes/about';
import path from 'path';
import nunjucks from 'nunjucks';

const app = express();

const port = 4000;
app.set("view engine", "html")

nunjucks.configure(path.resolve(__dirname, "views"), {
  autoescape: true,
  express: app,
  watch: true,
});

app.use('/',homeRoute)
app.use('/about',aboutRoute)

app.listen(port, () => {
  console.log("started on", port);
});

`;

const homeRouteTsFile = `

// src/routes/home.ts

import { Router } from 'express';

const router = Router();

router.get('/', (req, res) => {
  res.render('home.html', {
    home: {
      heading: 'Welcome to the Home Page',
      img: 'https://example.com/home.jpg',
    },
  });
});

export default router;

`;

const aboutRouteTsFile = `
// src/routes/about.ts

import { Router } from 'express';

const router = Router();

router.get('/', (req, res) => {
  res.render('about.html', {
    about: {
      heading: 'Welcome to the About Page',
      img: 'https://example.com/about.jpg',
    },
  });
});

export default router;

`;

const homeHtmlFile = `

<!-- src/views/home.html -->

<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Home Page</title>
</head>
<body>
    <h1>{{ home.heading }}</h1>
    <img src="{{ home.img }}" alt="Home Image">
</body>
</html>

`;

const aboutHtmlFile = `
<!-- src/views/about.html -->

<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>About Page</title>
</head>
<body>
    <h1>{{ about.heading }}</h1>
    <img src="{{ about.img }}" alt="About Image">
</body>
</html>

`;

const packageJsonStartScript = `
{
  "scripts": {
    "start": "tsc && node dist/app.js",
    "dev": "nodemon --exec ts-node-dev src/app.ts"
  }
}

`;

const runServerCommand = `
npm run dev
`;

const Nunjucks: React.FC = () => {
  return (
    <div>
      <h2>Nunjucks</h2>

      <p>
        Nunjucks is a templating engine for JavaScript inspired by Jinja2. It
        allows you to inject variables and logic into HTML templates, making it
        easier to generate dynamic content for web applications.
      </p>
      <h3>Setting Up Nunjucks with TypeScript and Express</h3>
      <h4> 1. Project Initialization:</h4>
      <p>Initialize a new Node.js project using npm:</p>
      <ul className="listitems">
        <pre>{initialiseProjectHTML}</pre>
      </ul>

      <h4>2. Install Nunjucks:</h4>
      <p>Run the following command to install Nunjucks as a dependency:</p>
      <ul className="listitems">
        <pre>{installNunjucksHTML}</pre>
      </ul>

      <h4>3. Install Dependencies:</h4>
      <p>Install TypeScript and other required dependencies:</p>
      <ul className="listitems">
        <pre>{installDependenciesHTML}</pre>
      </ul>

      <h4>4. Initialize TypeScript Configuration:</h4>
      <p>
        Run the TypeScript compiler initialization wizard to create a
        tsconfig.json file:
      </p>
      <ul className="listitems">
        <pre>{initialiseTypescriptHTML}</pre>
      </ul>

      <h4>5. Create Project Structure:</h4>
      <p>Create the following project folder structure:</p>
      <ul className="listitems">
        <pre>{projectFolderStructureHTML}</pre>
      </ul>

      <h4>6. Configure Typescript:</h4>
      <p>
        Update tsconfig.json with the following
        configuration:
      </p>
      <ul className="listitems">
        <pre>{tsConfigFileHTML}</pre>
      </ul>

      <h4>7. Configure Express and Nunjucks:</h4>
      <p>
        Create an Express application in 'app.ts'
        and configure Nunjucks:
      </p>
      <ul className="listitems">
        <pre>{configureNunjucksHTML}</pre>
      </ul>

      <h4>8. Define Routes:</h4>
      <p>
        Define routes in home.ts and about.ts under the src/routes
        directory:
      </p>
      <ul className="listitems">
        <pre>{homeRouteTsFile}</pre>
      </ul>
      <ul className="listitems">
        <pre>{aboutRouteTsFile}</pre>
      </ul>

      <h4>9. Create HTML Templates:</h4>
      <p>Create HTML templates in the views directory:</p>
      <ul className="listitems">
        <pre>{homeHtmlFile}</pre>
      </ul>
      <ul className="listitems">
        <pre>{aboutHtmlFile}</pre>
      </ul>

      <h4>10. Update Package.json Scripts:</h4>
      <p>
        Update the start script in package.json to
        run the TypeScript files:
      </p>
      <ul className="listitems">
        <pre>{packageJsonStartScript}</pre>
      </ul>

      <h4>11. Run the Server:</h4>
      <p>Start the server using the following command:</p>
      <ul className="listitems">
        <pre>{runServerCommand}</pre>
      </ul>
    </div>
  );
};

export default Nunjucks;
