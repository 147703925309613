import React from "react";

const appJs = `
const express = require('express');
const bodyParser = require('body-parser');
const ds = require('./downstream');
const LoggerService = require("./loggerService");

const app = express();
app.use(bodyParser.urlencoded({ extended: true }));

app.post("/post-test", (req, res) => {
    const log = new LoggerService(req.headers.correlationid);
    log.info('Got request body:',req.body);
    ds.postApi("dummyData", log)
    res.sendStatus(200);
});

app.get("/get-test", (req, res) => {
    const log = new LoggerService(req.headers.correlationid);
    log.info('Got request');
    ds.postApi("dummyData", log)
    res.sendStatus(200);
});

app.listen(8085, () => console.log('Started server at port http://localhost:8085!'))

`;

const downstreamJs = `
const axios = require("axios");

var postApi = function (data, log) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "JWT fefege...",
    correlationId: log.correlationId
  };

  axios
    .post("https://reqres.in/api/users", data, {
      headers: headers,
    })
    .then((response) => {
      log.info(JSON.stringify(response.body));
    })
    .catch((error) => {
      log.info(JSON.stringify(error));
    })
}
 
var getApi = function(data, log) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "JWT fefege...",
        correlationId: log.correlationId
    }

    axios
    .post("https://reqres.in/api/users/2", data, {
      headers: headers,
    })
    .then((response) => {
      log.info(JSON.stringify(response.body));
    })
    .catch((error) => {
      log.info(JSON.stringify(error));
    })
}

module.exports = {postApi, getApi}

`;

const loggerServiceJs = `
const { v4: uuidv4 } = require('uuid');

class LoggerService{
    constructor(reqCorrelationId){
        this.correlationId = reqCorrelationId || uuidv4();
    }

    info(msg) {
        console.log({ correlationId: this.correlationId, msg})
    }
    error(msg) {
        console.log({ correlationId: this.correlationId, msg})
    }

}

module.exports = LoggerService
`;

const CorrelationId: React.FC = () => {
  return (
    <div>
      <h2>Correlation Id</h2>
      <p>
        Correlation ID is a unique identifier that is used to track a particular
        transaction or request as it flows through different services. It helps
        in correlating various log entries and events associated with the same
        request or transaction across multiple services or components.
      </p>
      <p>
        In distributed systems, a single request may trigger several internal
        requests between different services. Without a correlation ID, tracing
        the path of a request through the system becomes challenging.
      </p>
      <p>
        Correlation IDs are crucial for logging and debugging. When an error
        occurs or debugging is necessary, having a correlation ID allows for
        easy tracing of the request's flow through the system. Each service can
        log messages with the correlation ID, simplifying the understanding of
        events leading to an issue.Here's how Correlation IDs work:
      </p>
      <ul>
        <li>
          When a request enters the system, a correlation ID is generated. This
          ID can be generated by the client or by the system's entry point, like
          an API gateway.
        </li>
        <li>
          The correlation ID travels with the request through the system. Each
          service that processes the request includes the correlation ID in its
          logs and when making requests to downstream services.
        </li>
        <li>
          Services log messages along with the correlation ID, facilitating
          correlation between logs from different services.
        </li>
        <li>
          During debugging, developers can search for logs with the specific
          correlation ID, enabling them to view all log entries related to that
          request and identify the root cause of the issue efficiently.
        </li>
      </ul>
      <p>
        Correlation IDs offer several benefits: they provide traceability,
        simplify debugging, aid in root cause analysis, and can be used for
        performance monitoring and analyzing request latency across services.
        Overall, implementing correlation IDs enhances observability in
        distributed systems, making it easier to manage and debug interactions
        between services.
      </p>
      <p>
        Below are the steps to set up a Node.js project and implement
        Correlation IDs:{" "}
      </p>
      <p>
        <strong>1. Node.js Project Setup:</strong> Begin by initializing a new
        Node.js project using <strong>npm init</strong> in your terminal. This
        command guides you through setting up your project details.
      </p>
      <p>
        <strong>2. Install Dependencies:</strong> Install necessary packages
        such as Express.js (<strong>npm install express</strong>), Axios (
        <strong>npm install axios</strong>), Body Parser (
        <strong>npm install body-parser</strong>), and UUID (
        <strong>npm install uuid</strong>) using npm.
      </p>
      <p>
        <strong>3. Create Project Files:</strong> Create essential project files
        like app.js, loggerService.js, and downstream.js
      </p>
      <p>
        <strong>4. App.js Configuration:</strong> This is the
        main file of the application. It sets up an Express.js server with
        routes for handling POST and GET requests. It creates a new instance of
        LoggerService for each request and passes the correlation ID from the
        request headers. Code for app.js looks as below:
      </p>
      <div className="listitems">
        <pre>{appJs}</pre>
      </div>
      <p>
        <strong>5. LoggerService Implementation:</strong> Implement a
        LoggerService class in loggerService.js that manages logging with
        Correlation IDs.  In loggerservice.js, the LoggerService
        class checks if a correlation ID is provided in the request headers. If
        not, it generates a new one using UUID. This ensures that each request
        is associated with a unique correlation ID. Code for loggerservice.js
        looks as below:
      </p>
      <div className="listitems">
        <pre>{loggerServiceJs}</pre>
      </div>
      <p>
        The info method of the LoggerService logs messages along with the
        correlation ID. This allows you to trace logs related to specific
        requests.This class initializes a logger with a correlation ID.It provides methods like info and error to log messages along
        with the correlation ID.
      </p>
      <p>
        <strong>6. Downstream Service Integration:</strong> This module contains
        functions to make HTTP requests to downstream services. It includes the
        correlation ID in the request headers.In downstream.js, create
        functions for making HTTP requests to downstream services. Ensure that
        these functions accept and propagate the Correlation ID passed from the
        LoggerService. Code for downstream.js looks as below:
      </p>
      <div className="listitems">
        <pre>{downstreamJs}</pre>
      </div>
      <p>
        In downstream.js, the postApi and getApi functions pass the correlation
        ID in the request headers when making requests to downstream services.
        This ensures that the downstream services can also log messages with the
        same correlation ID, allowing for easy tracing of requests across
        different services.
      </p>
      <p>
        <strong>7. Correlation ID Handling:</strong> Use the Correlation ID
        received from the request headers when initializing the LoggerService in
        your routes. Pass this Correlation ID to downstream services to maintain
        traceability.
      </p>
      <p>
        <strong>8. Logging with Correlation ID:</strong> Log messages along with
        the Correlation ID in all services to facilitate easy tracing and
        debugging of requests across the system.
      </p>
      <p>
        By following these steps, you can set up a Node.js project and implement
        Correlation IDs to enhance traceability and debugging in a distributed
        system.
      </p>
    </div>
  );
};

export default CorrelationId;
