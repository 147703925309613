import React from "react";
const BeforeSuedoCodeSnippetRawHTML = `
.example::before {
  content: "Before content: ";
}
`;
const AfterSuedoCodeSnippetRawHTML = `
.example::after {
  content: " After content.";
}
`;
const FirstSuedoCodeSnippetRawHTML = `
p::first-line {
  font-weight: bold;
}
`;
const FirstLetterSuedoCodeSnippetRawHTML = `
p::first-letter {
  font-size: 150%;
  font-weight: bold;
}
`;
const SelectionSuedoCodeSnippetRawHTML = `
::selection {
  background-color: yellow;
  color: black;
}
`;
const PlaceholderSuedoCodeSnippetRawHTML = `
input::placeholder {
  color: #999;
}
`;
const ClearingFloatsSuedoCodeSnippetRawHTML = `
.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
`;
const HoverSuedoCodeSnippetRawHTML = `
a:hover {
  color: blue;
}
`;
const ActiveSuedoCodeSnippetRawHTML = `
button:active {
  background-color: #ffcc00;
}
`;
const FocusSuedoCodeSnippetRawHTML = `
button:active {
  background-color: #ffcc00;
}
`;
const FirstChildSuedoCodeSnippetRawHTML = `
button:active {
  background-color: #ffcc00;
}
`;
const LastChildSuedoCodeSnippetRawHTML = `
div p:last-child {
  margin-bottom: 0;
}
`;
const NthChildSuedoCodeSnippetRawHTML = `
li:nth-child(odd) {
  background-color: #f0f0f0;
}
`;
const NthOfChildSuedoCodeSnippetRawHTML = `
p:nth-of-type(2n) {
  color: red;
}
`;
const NotChildSuedoCodeSnippetRawHTML = `
input:not([type="text"]) {
  opacity: 0.7;
}
`;
const CheckedSuedoCodeSnippetRawHTML = `
input[type="checkbox"]:checked {
  background-color: #66cc66;
}
`;
const DisabledSuedoCodeSnippetRawHTML = `
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
`;
const Module09: React.FC = () => {
  return (
    <div>
      <h2> CSS Pseudo Element:</h2>
      <p>
        pseudo-elements are used to style specific parts of an element. They
        allow you to style parts of an element that are not part of the actual
        HTML content. Pseudo-elements are denoted by a double colon (::)
        followed by the name of the pseudo-element.
      </p>
      <h3>Here are some common CSS pseudo-elements:</h3>
      <h4>::before:</h4>
      <p>
        The ::before pseudo-element is used to insert content before the content
        of the selected element.
      </p>
      <div className="listitems">
        <pre>{BeforeSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>::after:</h4>
      <p>
        The ::after pseudo-element is used to insert content after the content
        of the selected element.
      </p>
      <div className="listitems">
        <pre>{AfterSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>::first-line:</h4>
      <p>
        The ::first-line pseudo-element selects the first line of a block-level
        element.
      </p>
      <div className="listitems">
        <pre>{FirstSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>::first-letter:</h4>
      <p>
        The ::first-letter pseudo-element selects the first letter of a
        block-level element.
      </p>
      <div className="listitems">
        <pre>{FirstLetterSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>::selection:</h4>
      <p>
        The ::selection pseudo-element selects the portion of an element that is
        selected by a user.
      </p>
      <div className="listitems">
        <pre>{SelectionSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>::placeholder:</h4>
      <p>
        The ::placeholder pseudo-element styles the placeholder text in an input
        or textarea element.
      </p>
      <div className="listitems">
        <pre>{PlaceholderSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>::before and ::after for Clearing Floats:</h4>
      <p>
        Pseudo-elements are commonly used to clear floats without adding extra
        elements to the HTML.
      </p>
      <div className="listitems">
        <pre>{ClearingFloatsSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h2> CSS Pseudo Classes:</h2>
      <p>
        pseudo-classes are used to select and style elements based on their
        state or position in the document. Pseudo-classes are denoted by a
        single colon (:) followed by the name of the pseudo-class.
      </p>
      <h3>Here are some common CSS pseudo-classes:</h3>
      <h4>:hover:</h4>
      <p>
        The :hover pseudo-class is used to select and style an element when the
        user hovers over it.
      </p>
      <div className="listitems">
        <pre>{HoverSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:active:</h4>
      <p>
        The :active pseudo-class selects and styles an element while it is being
        activated (e.g., clicked).
      </p>
      <div className="listitems">
        <pre>{ActiveSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:focus:</h4>
      <p>
        The :focus pseudo-class selects and styles an element that has keyboard
        focus.
      </p>
      <div className="listitems">
        <pre>{FocusSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:first-child:</h4>
      <p>
        The :first-child pseudo-class selects the first child of a parent
        element.
      </p>
      <div className="listitems">
        <pre>{FirstChildSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:last-child:</h4>
      <p>
        The :last-child pseudo-class selects the last child of a parent element.
      </p>
      <div className="listitems">
        <pre>{LastChildSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:nth-child(n):</h4>
      <p>
        The :nth-child(n) pseudo-class selects elements based on their position
        as a child of their parent.
      </p>
      <div className="listitems">
        <pre>{NthChildSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:nth-of-type(n):</h4>
      <p>
        The :nth-of-type(n) pseudo-class selects elements based on their
        position as a child of their parent, considering only elements of the
        same type.
      </p>
      <div className="listitems">
        <pre>{NthOfChildSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:not(selector):</h4>
      <p>
        The :not(selector) pseudo-class selects elements that do not match the
        given selector.
      </p>
      <div className="listitems">
        <pre>{NotChildSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:checked:</h4>
      <p>
        The :checked pseudo-class selects radio buttons or checkboxes that are
        checked.
      </p>
      <div className="listitems">
        <pre>{CheckedSuedoCodeSnippetRawHTML}</pre>
      </div>
      <h4>:disabled:</h4>
      <p>
        The :disabled pseudo-class selects and styles disabled form elements.
      </p>
      <div className="listitems">
        <pre>{DisabledSuedoCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module09;
