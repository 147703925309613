import React from "react";
const AttachmentCodeSnippetRawHTML = ` body {
    background-image: url('background-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; /* or 'scroll' */
  }
  `;
const ImageCodeSnippetRawHTML = `selector {
    background-image: none|url('path/to/image.jpg')|linear-gradient(value)|radial-gradient(value)|repeating-linear-gradient(value)|repeating-radial-gradient(value)|initial|inherit;
  }
  `;

const BackgroundImageCodeSnippetRawHTML = `body {
    background-image: url('background-image.jpg');
  }
  `;

const MultipleBackgroundCodeSnippetRawHTML = `body {
    background-image: url('background1.jpg'), url('background2.jpg');
  }
  `;
const GradientCodeSnippetRawHTML = `body {
    background-image: linear-gradient(to right, #ff7e5f, #feb47b);
  }
  `;
const ColorCodeSnippetRawHTML = `selector {
    background-color: color|transparent|initial|inherit;
  }
  `;
const BackgroungColorCodeSnippetRawHTML = `Setting a solid background color:
body {
  background-color: #f0f0f0; /* Hex color code */
}
  `;
const RGBBackgroungColorCodeSnippetRawHTML = `Using RGB values for background color:
div {
  background-color: rgb(255, 0, 0); /* Red color using RGB values */
}
  `;
const ColorNameCodeSnippetRawHTML = `Using color names:
p {
  background-color: lightblue; /* Color name */
}
  `;
const TransparentCodeSnippetRawHTML = `header {
    background-color: transparent;
  }
  `;
const RepeatCodeSnippetRawHTML = `selector {
    background-repeat: repeat|repeat-x|repeat-y|no-repeat|initial|inherit;
  }
  `;
const ExampleCodeSnippetRawHTML = `div {
    background-image: url('background.jpg');
    background-repeat: repeat; /* Default value */
  }
  section {
    background-image: url('pattern.png');
    background-repeat: repeat-x; /* Repeat horizontally */
  }
  `;
const Module02: React.FC = () => {
  return (
    <div>
      <h2>background-attachment:</h2>
      <p>
        The background-attachment property in CSS is used to specify whether a
        background image should scroll with the rest of the page content or
        remain fixed in place as the page is scrolled. <br /> <br />
        It can take one of the following values: <br /> <b>scroll:</b> The
        background image will scroll along with the page content. <br />{" "}
        <b>fixed:</b> The background image will remain fixed in place while the
        page is scrolled.
        <br /> <br />
      </p>
      <h3>Properties:</h3>
      <p>
        <b>background-image:</b> Specifies the URL of the background image.
        <br />
        <b>background-repeat:</b> Sets how the background image should be
        repeated (e.g., no-repeat to prevent repetition). <br />
        <b>background-size:</b> Sets the size of the background image (e.g.,
        cover to cover the entire background).
      </p>
      <h3>Horizontal Position:</h3>
      <p>
        <b>left:</b> Aligns the background image to the left.
        <br /> <b>center:</b> Aligns the background image to the center
        horizontally.
        <br />
        <b>right:</b> Aligns the background image to the right. <br />
        <b>length:</b> Specifies an absolute position (e.g., 10px, 50%).
      </p>
      <h3>Vertical Position:</h3>
      <p>
        <b>top:</b> Aligns the background image to the top. <br />
        <b>center:</b> Aligns the background image to the center vertically.
        <br />
        <b>bottom:</b> Aligns the background image to the bottom. <br />
        <b>length:</b> Specifies an absolute position (e.g., 10px, 50%).
      </p>
      <h2>background-image:</h2>
      <p>
        The background-image property in CSS is used to set one or more
        background images for an element. It can be applied to various HTML
        elements, such as &lt;body&gt;, &lt;div&gt;, or other elements, to
        provide a background visual.
      </p>
      <h3>Syntax:</h3>
      <div className="listitems">
        <pre>{ImageCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>none:</b> No background image is applied. <br />
        <b>url('path/to/image.jpg'):</b> Specifies the path to an image file.{" "}
        <br />
        <b>linear-gradient(value):</b> Creates a linear gradient background.
        <br />
        <b>radial-gradient(value):</b> Creates a radial gradient background.
        <br />
        <b>repeating-linear-gradient(value):</b> Repeats a linear gradient
        background.
        <br />
        <b>repeating-radial-gradient(value):</b> Repeats a radial gradient
        background.
        <br />
        <b>initial:</b> Sets the property to its default value. <br />
        <b>inherit:</b> Inherits the property from its parent element.
      </p>
      <h3>Example: </h3>
      <div className="listitems">
        <pre>{BackgroundImageCodeSnippetRawHTML}</pre>
      </div>
      <h3>Multiple Background Images: </h3>
      <p>
        multiple background images separated by commas. Each image will be
        layered on top of the others, with the first one listed on top.
      </p>
      <h3>Example:</h3>
      <div className="listitems">
        <pre>{MultipleBackgroundCodeSnippetRawHTML}</pre>
      </div>
      <h3>Gradient Background:</h3>
      <p>
        creates a linear gradient background from #ff7e5f to #feb47b from left
        to right.
      </p>
      <div className="listitems">
        <pre>{GradientCodeSnippetRawHTML}</pre>
      </div>
      <h2>background-color:</h2>
      <p>
        background-color property is used to set the background color of an
        element. It can be applied to various HTML elements, such as
        {/* <body>, <div>,  */}
        or other elements.
      </p>
      <h3>Syntax:</h3>
      <p>Include the CSS directly within a tag in the HTML file:</p>
      <div className="listitems">
        <pre>{ColorCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>color:</b> Specifies the background color using a color value (e.g.,
        hex, RGB, or color name). <br />
        <b>transparent: </b>Specifies a transparent background.
        <br />
        <b>initial:</b> Sets the property to its default value. <br />
        <b>inherit:</b> Inherits the property from its parent element. <br />
      </p>
      <h3>Examples:</h3>
      <div className="listitems">
        <pre>{BackgroungColorCodeSnippetRawHTML}</pre>
      </div>
      <div className="listitems">
        <pre>{RGBBackgroungColorCodeSnippetRawHTML}</pre>
      </div>
      <div className="listitems">
        <pre>{ColorNameCodeSnippetRawHTML}</pre>
      </div>
      <h3>Transparent background:</h3>

      <div className="listitems">
        <pre>{TransparentCodeSnippetRawHTML}</pre>
      </div>
      <h2>background-repeat :</h2>
      <p>
        The background-repeat property in CSS is used to define how a background
        image should be repeated or tiled within its container. It determines
        whether the background image is repeated horizontally, vertically, both,
        or not repeated at all.
      </p>
      <h3>Syntax:</h3>
      <div className="listitems">
        <pre>{RepeatCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>repeat:</b> The background image is repeated both horizontally and
        vertically (default).
        <br /> <b>repeat-x:</b> The background image is repeated horizontally
        only.
        <br />
        <b>repeat-y:</b> The background image is repeated vertically only.{" "}
        <br />
        <b>o-repeat:</b> The background image is not repeated and is shown only
        once.
      </p>
      <h3>Example:</h3>
      <div className="listitems">
        <pre>{ExampleCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module02;
