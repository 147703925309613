import React from "react";

const morgan_ex = `
const express = require('express');
const morgan = require('morgan');

const app = express();

// Use Morgan middleware with a predefined format ('combined' format in this example)
app.use(morgan('combined'));

// The routes and other middleware go here

// Example route
app.get('/', (req, res) => {
  res.send('Hello, World!');
});

const PORT = 3000;
app.listen(PORT, () => {
  console.log('Server is running on http://localhost:3000');
});

`;

const Morgan: React.FC = () => {
  return (
    <>
      <h1>Morgan</h1>
      <p>
        Morgan is a popular middleware for logging HTTP requests in Node.js and
        Express applications. It provides a simple and consistent way to log
        information about incoming HTTP requests, making it easier to monitor
        and debug your server. Morgan is often used in conjunction with Express
        to log details such as request method, URL, response status, response
        time, and more.
      </p>
      <h3>Here's a brief explanation of key aspects of Morgan:</h3>
      <ul>
        <li>
          Logging Formats: Morgan supports various predefined logging formats,
          and you can also define custom formats. The logging formats determine
          what information about each request is logged.
        </li>
        <li>
          Middleware Integration: Morgan is designed to work as middleware in an
          Express application. You include it in your application's middleware
          stack using app.use().
        </li>
        <li>
          Debugging Aid: When troubleshooting issues, the detailed logs provided
          by Morgan can be invaluable in pinpointing the root cause of problems.
        </li>
        <li>
          Performance Monitoring: Monitor response times and identify
          slow-performing routes, enabling you to optimize your application's
          speed and efficiency.
        </li>
      </ul>
      <h3>Example usage: </h3>
      <div className="listitems">
        <pre>{morgan_ex}</pre>
      </div>
      <p>
        In the above example, Morgan is configured to use the 'combined' logging
        format, which includes information about the IP address, remote user,
        date, request method, URL, HTTP version, response status, content
        length, and referer. We can customize the format based on your
        preferences and requirements.
      </p>
      <p>
        When we run the server, we'll see log messages in the console each time
        a request is made to the server, providing information about the
        incoming HTTP request.
      </p>
    </>
  );
};

export default Morgan;
