import React from "react";

const helmetinst = `
npm install helmet

OR

yarn add helmet

`;

const helmet_ex = `
const express = require('express');
const helmet = require('helmet');

const app = express();

// Use Helmet middleware
app.use(helmet());

// routes and other middleware go here

// Example route
app.get('/', (req, res) => {
  res.send('Hello, World!');
});

const PORT = 3000;
app.listen(PORT, () => {
  console.log('Server is running on http://localhost: 3000');
});


`;

const Helmet: React.FC = () => {
  return (
    <>
      <h1>Helmet</h1>
      <p>
        Helmet is a middleware for Express.js that helps you secure your web
        applications by setting various HTTP headers. These headers can mitigate
        common web vulnerabilities and enhance the security of your application.
        It provides protection against certain types of attacks, such as
        cross-site scripting (XSS), clickjacking, and other security threats.
      </p>
      <h3>Why to use Helmet.js ?</h3>
      <p>
        While Express is generally robust for assisting your full stack
        application builds and prototypes, it is not perfect when it comes to
        security. The reason for this is because Express is designed for
        creating web applications — not securing your server from
        vulnerabilities. The defaults leave the HTTP headers mostly open, in
        part, to assist with the rapid application development. This is where
        Helmet.js steps in.
      </p>
      <p>
        Helmet.js fills in the gap between Node.js and Express.js by securing
        HTTP headers that are returned by your Express apps. HTTP, by design, is
        open in nature and highly insecure. It can leak sensitive information
        about your app and leave your data open to anyone with some tech skills
        to see.
      </p>
      <p>
        In short, using the preset default HTTP headers is a sure way to quickly
        get your app and running, but at the potential cost of anyone having
        access to it in a malicious way. What makes it worse is that because end
        users tend to be uneducated over the importance of HTTPS over HTTP,
        developers tend to ignore it unless otherwise forced to use HTTPS by
        their security policies.
      </p>
      <h3>
        Here are some of the security headers that Helmet can help you set:
      </h3>
      <ul>
        <li>
          <strong>Content Security Policy (CSP):</strong> Helps prevent various
          types of code injection attacks by defining the content sources that
          the browser should consider valid.
        </li>
        <li>
          <strong>Strict-Transport-Security (HSTS):</strong> Informs the browser
          to only access the website over secure, HTTPS connections.
        </li>
        <li>
          <strong>X-Content-Type-Options:</strong> Prevents browsers from
          interpreting files as a different MIME type than what is declared by
          the server.
        </li>
        <li>
          <strong>X-Frame-Options:</strong> Protects your web pages from being
          embedded within an iframe, preventing clickjacking attacks.
        </li>
        <li>
          <strong>X-XSS-Protection:</strong> Enables the browser's Cross-Site
          Scripting (XSS) protection.
        </li>
      </ul>
      <h3>How to Use Helmet:</h3>
      <p>
        Just like you hire a security guard and trust them to do their job, you
        add app.use(helmet()); to your website code. This tells Helmet to start
        doing its security work and applying these safety measures.
      </p>
      <p>
        Helmet.js comes with a collection of Node modules that you can use to
        interface to Express to increase the HTTP header security. How does it
        work? It lets you configure the headers and prevent common
        vulnerabilities such as clickjacking, implementation of strict HTTP, and
        download options for vulnerable browsers such as IE8.
      </p>
      <p>
        When you use Helmet.js, you can also configure Content-Security-Policy
        to force subsequent developers working on public-facing APIs that
        require HTTP to approach the code with a security-first mindset.
      </p>
      <h3>Installing Helmet </h3>
      <div className="listitems">
        <pre>{helmetinst}</pre>
      </div>
      <p>
        Remember that helmet() is nothing more than an Express middleware.
        Specifically, the top-level helmet() function is a wrapper of 15
        sub-middlewares. So, by registering helmet(), you are adding 15 Express
        middlewares to your apps.Note that each middleware takes care of setting
        one HTTP security header.
      </p>
      <h3>Using Helmet in Express.js</h3>
      <div className="listitems">
        <pre>{helmet_ex}</pre>
      </div>
      <p>
        By adding app.use(helmet());, you are telling Express to use the default
        security headers provided by Helmet. You can customize the headers based
        on your needs. Helmet provides several options that you can pass to
        tailor the middleware's behavior.
      </p>
    </>
  );
};

export default Helmet;
