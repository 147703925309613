import React from "react";
import "../../App.css";

const envFile = `
ME_CONFIG_MONGODB_SERVER=mongo-db
ME_CONFIG_BASICAUTH_USERNAME=your_username
ME_CONFIG_BASICAUTH_PASSWORD=your_password
ME_CONFIG_MONGODB_PORT=27017
ME_CONFIG_MONGODB_ADMINUSERNAME=root
ME_CONFIG_MONGODB_ADMINPASSWORD=rootpassword
MONGO_INITDB_DATABASE=demodb
MONGO_INITDB_ROOT_USERNAME=root
MONGO_INITDB_ROOT_PASSWORD=rootpassword
`;

const dockerComposeYml = `
version: '3'

services:
  mongo-express:
    image: mongo-express
    ports:
      - 8081:8081
    env_file:
      - .env
    links:
      - mongo-db
    networks:
      - mongo-compose-network
    restart: always
    depends_on:
      - mongo-db

  mongo-db:
    image: mongo:latest
    container_name: mongo
    env_file:
      - .env
    ports:
      - "27017:27017"
    volumes:
      - mongodb_data_container:/data/db
    networks:
      - mongo-compose-network


networks:
    mongo-compose-network:
      driver: bridge

volumes:
  mongodb_data_container:
`;

const dockerComposeCmd = `
docker-compose up
`;

const dockerremoveImage = `
docker image ls
docker image rm -f IMAGEID
docker image prune
`;

const MongoDBExpress: React.FC = () => {
  return (
    <div>
      <h2>MongoDB Express</h2>
      <p>
        MongoDB Express is a web-based administrative interface for MongoDB,
        which is a popular NoSQL database. MongoDB Express provides a
        user-friendly way to interact with MongoDB databases, collections,
        documents, and perform administrative tasks without needing to write
        complex commands.
      </p>
      <p>
        MongoDB Express simplifies the process of managing and interacting with
        MongoDB databases, especially for users who may not be comfortable with
        command-line interfaces or prefer a graphical user interface for
        database administration. It's a valuable tool for developers, database
        administrators, and anyone else working with MongoDB databases.
      </p>
      <h3>Setting Up MongoDB Express with Docker:</h3>
      <h4>1. Prepare Environment Variables and Docker Compose File:</h4>
      <p>
        Create an <strong>.env</strong> file in your project folder. This file will contain
        sensitive information like usernames and passwords. Open the .env file
        and add the following lines:
      </p>
      <div className="listitems">
        <pre>{envFile}</pre>
      </div>
      <p>
        Replace your_username and your_password with your desired username and
        password for MongoDB.
      </p>
      <p>
        Next, create a <strong>docker-compose.yml</strong>  file in the same directory. This file
        defines the services, networks, and volumes required for your Docker
        setup. Here's a basic example:
      </p>
      <div className="listitems">
        <pre>{dockerComposeYml}</pre>
      </div>
      <h4>2. Start Docker Containers:</h4>
      <p>
        Open a command prompt or terminal window and navigate to the directory
        containing your .env and docker-compose.yml files.Run the following
        command to start the Docker containers:
      </p>
      <div className="listitems">
        <pre>{dockerComposeCmd}</pre>
      </div>
      <h4>3. Access MongoDB Express UI:</h4>
      <p>
        Once the containers are up and running, open a web browser and navigate
        to <strong>http://localhost:8081</strong>. You will be prompted to enter the username and
        password you provided in the .env file.
      </p>
      <h4>4. Managing Databases and Documents:</h4>
      <p>
        After logging in, you'll have access to the MongoDB Express UI, where
        you can create new databases, collections (tables), and manage
        documents. Use the UI to perform various operations like inserting,
        updating, querying, and deleting documents within your MongoDB
        databases.
      </p>
      <h3> Rebuilding Docker Images (Optional):</h3>
      <p>If you want to remove existing Docker images and generate new ones, follow these steps:</p>
      <p>1. Remove existing mongo docker images using below commands after stopping docker images:</p>
      <div className="listitems">
        <pre>{dockerremoveImage}</pre>
      </div>
      <p>2. After removing the images, we have to remove any volumes associated with them in the folder. </p>
      <p>3. Once the volumes are removed, you can rebuild the Docker images and start the containers again by following the steps in the "Start Docker Containers" section.</p>
    </div>
  );
};

export default MongoDBExpress;
