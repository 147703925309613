import React from "react";

const submitClickEvent = `
submitButton.addClickHandler(new ClickHandler() {
    @Override
    public void onClick(ClickEvent event) {
        String firstName = firstNameBox.getText();
        String lastName = lastNameBox.getText();
        String gender = maleRadioButton.getValue() ? "Male" : "Female";
        String mobile = mobileBox.getText();
        String email = emailBox.getText();
        String country = countryDropdown.getSelectedItemText();
        String notes = notesArea.getText();

        String message = "First Name: " + firstName
                + "\\nLast Name: " + lastName
                + "\\nGender: " + gender
                + "\\nMobile: " + mobile
                + "\\nEmail: " + email
                + "\\nCountry: " + country
                + "\\nNotes: " + notes;
        Window.alert(message);
    }
});
`;

const EventHandlersGWT: React.FC = () => {
  return (
    <div>
      <h2>Event Handlers GWT</h2>
      <p>
        In GWT (Google Web Toolkit), event handlers are mechanisms that respond
        to user actions, such as clicks, key presses, or mouse movements. These
        handlers allow developers to create interactive web applications by
        executing specific code in response to user events.
      </p>
      <p>
        An event handler is a method that is invoked when a specified event
        occurs. In GWT, you can attach event handlers to UI components to define
        what happens when a user interacts with those components. This is
        crucial for building responsive and dynamic user interfaces.
      </p>

      <h3>Types of Events</h3>
      <p>GWT supports a variety of event types, including:</p>
      <ul>
        <li>
          <strong>Mouse Events:</strong> Triggered by mouse actions, such as
          clicks or movements.
        </li>
        <li>
          <strong>Keyboard Events:</strong> Triggered by keyboard actions, such
          as key presses.
        </li>
        <li>
          <strong>Focus Events:</strong> Triggered when a component gains or
          loses focus.
        </li>
        <li>
          <strong>Value Change Events:</strong> Triggered when the value of a
          component changes, such as in text boxes or dropdowns.
        </li>
      </ul>

      <h3>Event Handler Used in Our Code</h3>
      <p>
        In our <code>SampleGWTApp</code> code, we use a click event handler for
        the submit button. This handler responds when the user clicks the
        "Submit" button and performs specific actions based on the values
        entered in the form.
      </p>


      <h4>ClickHandler Implementation</h4>
      <p>
        Here's how we implemented the event handler for the submit button:
      </p>

      <div className="listitems">
            <pre>{submitClickEvent}</pre>
          </div>

      <h4>Explanation of the Code</h4>
      <p>In the above code:</p>
      <ul>
        <li>
          The <code>addClickHandler</code> method attaches a new{" "}
          <code>ClickHandler</code> to the <code>submitButton</code>.
        </li>
        <li>We need to add this code in the <b>onModuleLoad</b> method.</li>
        <li>
          When the button is clicked, the <code>onClick</code> method is
          invoked, retrieving the values from the form fields.
        </li>
        <li>These values are then concatenated into a message string.</li>
        <li>
          Finally, the message is displayed using <code>Window.alert()</code>,
          providing feedback to the user with the entered information.
        </li>
      </ul>

      <p>
        This event handling mechanism allows for interactive user experiences in
        your GWT applications, making it essential for modern web development.
      </p>
    </div>
  );
};

export default EventHandlersGWT;
