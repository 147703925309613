import React from "react";

const dockerComposeFile = `
version: '3.8'

services:
  localstack:
    image: localstack/localstack:latest
    ports:
      - "4566:4566"
      - "4575:4575"
    environment:
      - SERVICES=sns
      - DEFAULT_REGION=us-east-1

`;

const dependencies = `
implementation 'com.amazonaws:aws-java-sdk-sns:1.12.134'
`;

const applicationYml = `
cloud:
  aws:
    sns:
      endpoint: http://localhost:4566
      region: eu-west-1
      accessKeyId: test
      secretKey: test
`;

const snsService = `
package com.nipuna.ivacomms.snsLocalStack;

import com.amazonaws.auth.AWSStaticCredentialsProvider;
import com.amazonaws.auth.BasicAWSCredentials;
import com.amazonaws.client.builder.AwsClientBuilder;
import com.amazonaws.services.sns.AmazonSNS;
import com.amazonaws.services.sns.AmazonSNSClientBuilder;
import com.amazonaws.services.sns.model.CreateTopicRequest;
import com.amazonaws.services.sns.model.PublishRequest;
import com.amazonaws.services.sns.model.SubscribeRequest;
import org.springframework.beans.factory.annotation.Value;
import org.springframework.stereotype.Service;

import javax.annotation.PostConstruct;

@Service
public class SnsService {
    @Value("dollarSymbol{cloud.aws.sns.endpoint}")
    private String endpoint;

    @Value("dollarSymbol{cloud.aws.sns.region}")
    private String region;

    @Value("dollarSymbol{cloud.aws.sns.accessKeyId}")
    private String accessKeyId;

    @Value("dollarSymbol{cloud.aws.sns.secretKey}")
    private String secretKey;

    private AmazonSNS snsClient;

    @PostConstruct
    private void init() {
        snsClient = AmazonSNSClientBuilder.standard()
                .withEndpointConfiguration(new AwsClientBuilder.EndpointConfiguration(endpoint, region))
                .withCredentials(new AWSStaticCredentialsProvider(new BasicAWSCredentials(accessKeyId, secretKey)))
                .build();
    }

    public String createTopic(String topicName) {
        CreateTopicRequest createTopicRequest = new CreateTopicRequest(topicName);
        return snsClient.createTopic(createTopicRequest).getTopicArn();
    }

    public String publish(String topicArn, String message) {
        PublishRequest publishRequest = new PublishRequest(topicArn, message);
        return snsClient.publish(publishRequest).getMessageId();
    }

    public String subscribe(String topicArn, String endpointUrl) {
        SubscribeRequest subscribeRequest = new SubscribeRequest(topicArn, "http", endpointUrl);
        return snsClient.subscribe(subscribeRequest).getSubscriptionArn();
    }
}

`;

const snsController = `
package com.nipuna.ivacomms.snsLocalStack;

import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.http.HttpStatus;
import org.springframework.http.ResponseEntity;
import org.springframework.web.bind.annotation.*;

@RestController
@RequestMapping("/sns")
public class SnsController {

    @Autowired
    private SnsService snsService;

    @PostMapping("/createTopic/{topicName}")
    public ResponseEntity<String> createTopic(@PathVariable String topicName) {
        String topicArn = snsService.createTopic(topicName);
        return ResponseEntity.status(HttpStatus.CREATED).body("Topic created. TopicArn: " + topicArn);
    }

    @PostMapping("/publish")
    public ResponseEntity<String> publish(@RequestParam String topicArn, @RequestParam String message) {
        String messageId = snsService.publish(topicArn, message);
        return ResponseEntity.status(HttpStatus.CREATED).body("Message sent. MessageId: " + messageId);
    }

    @PostMapping("/subscribe")
    public ResponseEntity<String> subscribe(@RequestParam String topicArn, @RequestParam String endpointUrl) {
        String subscriptionArn = snsService.subscribe(topicArn, endpointUrl);
        return ResponseEntity.status(HttpStatus.CREATED).body("Subscribed. SubscriptionArn: " + subscriptionArn);
    }
}

`;

const SNSLocalStack: React.FC = () => {
  return (
    <div>
      <h2>SNS LocalStack</h2>
      <p>
        Amazon Simple Notification Service (SNS) is a fully managed messaging
        service that enables you to decouple microservices, distributed systems,
        and serverless applications. SNS supports the publish/subscribe
        (pub/sub) messaging pattern, where messages are sent to a topic and
        delivered to multiple subscribers. SNS supports various protocols such
        as HTTP/HTTPS, email, SMS, SQS, and AWS Lambda. It also allows publishing
        messages to topics and delivering them to multiple subscribers.
      </p>
      <h3>Setting Up SNS LocalStack with Docker Compose:</h3>
      <p>
        Create a <strong>docker-compose.yml</strong> file to set up LocalStack
        as below:
      </p>
      <div className="listitems">
        <pre>{dockerComposeFile}</pre>
      </div>
      <p>
        {" "}
        Start SNS LocalStack using command <strong>docker-compose up</strong> in
        command prompt.
      </p>
      <h3>Spring Boot Application Configuration:</h3>
      <p>
        1. Ensure your <strong>build.gradle</strong> file includes this
        dependency:
      </p>
      <div className="listitems">
        <pre>{dependencies}</pre>
      </div>
      <p>
        2. Add the following configuration to your{" "}
        <strong> application.yml</strong> file to connect to LocalStack:
      </p>
      <div className="listitems">
        <pre>{applicationYml}</pre>
      </div>
      <p>3. Create a service class (SnsService) to manage SNS operations:</p>
      <div className="listitems">
        <pre>{snsService}</pre>
      </div>
      <p>
        <strong>Note:</strong> dollarSymbol text in above code snippets should
        be replaced with dollar symbol
      </p>
      <p>
        5. Create a controller class (SnsController) to manage HTTP endpoints:
      </p>
      <div className="listitems">
        <pre>{snsController}</pre>
      </div>
      <p><strong>Create Topic Endpoint</strong>: Allows you to create a new SNS topic.</p>
      <p>
      <strong>Publish Message Endpoint</strong>: Publishes messages to a specified SNS topic.
      </p>
      <p>
        <strong>Subscribe Endpoint</strong>: Subscribes an endpoint URL to an SNS topic to
        receive messages.
      </p>
      <p>
        This setup allows you to simulate SNS functionality locally using
        LocalStack and Docker Compose, making it easier to develop and test your
        application without relying on AWS services.
      </p>
    </div>
  );
};

export default SNSLocalStack;
