import React from "react";

const MongoDBArchitecture: React.FC = () => {
  return (
    <div>
      <div className="listitems">
        <h2>Capturing realm app events using Mongo DB</h2>
        <img
          src="/mongo_db_realtime_app.png"
          alt="Capturing realtime app events using Mongo DB"
          style={{ width: "1000px"}}
        />
      </div>
    </div>
  );
};

export default MongoDBArchitecture;
