import React from "react";

const messageClass = `
package com.nipuna.codesnippets.gwt.client;

import java.io.Serializable;

public class Message implements Serializable {
    private static final long serialVersionUID = 1L;
    private String message;

    public Message() {}

    public void setMessage(String message) {
        this.message = message;
    }

    public String getMessage() {
        return message;
    }
}
`;

const messageServiceClass = `
package com.nipuna.codesnippets.gwt.client;

import com.google.gwt.user.client.rpc.RemoteService;
import com.google.gwt.user.client.rpc.RemoteServiceRelativePath;

@RemoteServiceRelativePath("message")
public interface MessageService extends RemoteService {
    Message getMessage(String input);
}
`;

const messageServiceAsyncClass = `
package com.nipuna.codesnippets.gwt.client;

import com.google.gwt.user.client.rpc.AsyncCallback;

public interface MessageServiceAsync {
    void getMessage(String input, AsyncCallback<Message> callback);
}
`;

const messageServiceImplClass = `
package com.nipuna.codesnippets.gwt.server;

import com.google.gwt.user.server.rpc.RemoteServiceServlet;
import com.nipuna.codesnippets.gwt.client.Message;
import com.nipuna.codesnippets.gwt.client.MessageService;

public class MessageServiceImpl extends RemoteServiceServlet implements MessageService {
    private static final long serialVersionUID = 1L;

    public Message getMessage(String input) {
        String messageString = "Hello " + input + "!";
        Message message = new Message();
        message.setMessage(messageString);
        return message;
    }
}
`;

const sampleGwtappJavaClass = `
package com.nipuna.codesnippets.gwt.client;

import com.google.gwt.core.client.EntryPoint;
import com.google.gwt.core.client.GWT;
import com.google.gwt.event.dom.client.ClickEvent;
import com.google.gwt.event.dom.client.ClickHandler;
import com.google.gwt.event.dom.client.KeyCodes;
import com.google.gwt.event.dom.client.KeyUpEvent;
import com.google.gwt.event.dom.client.KeyUpHandler;
import com.google.gwt.user.client.Window;
import com.google.gwt.user.client.rpc.AsyncCallback;
import com.google.gwt.user.client.rpc.ServiceDefTarget;
import com.google.gwt.user.client.ui.Button;
import com.google.gwt.user.client.ui.DecoratorPanel;
import com.google.gwt.user.client.ui.HasHorizontalAlignment;
import com.google.gwt.user.client.ui.HorizontalPanel;
import com.google.gwt.user.client.ui.Label;
import com.google.gwt.user.client.ui.RootPanel;
import com.google.gwt.user.client.ui.TextBox;
import com.google.gwt.user.client.ui.VerticalPanel;

public class SampleGWTApp implements EntryPoint {

    private MessageServiceAsync messageService = GWT.create(MessageService.class);

    private class MessageCallBack implements AsyncCallback<Message> {
        @Override
        public void onFailure(Throwable caught) {
            Window.alert("Unable to obtain server response: " + caught.getMessage());
        }

        @Override
        public void onSuccess(Message result) {
            GWT.log("Success: " + result.getMessage());
            Window.alert(result.getMessage());
        }
    }

    public void onModuleLoad() {
        ((ServiceDefTarget) messageService).setServiceEntryPoint(GWT.getModuleBaseURL() + "message");

        GWT.log("onModuleLoad called");

        RootPanel rootPanel = RootPanel.get("gwtContainer");
        if (rootPanel == null) {
            Window.alert("RootPanel gwtContainer not found");
            return;
        }

        final TextBox txtName = new TextBox();
        txtName.setWidth("200");
        txtName.addKeyUpHandler(new KeyUpHandler() {
            @Override
            public void onKeyUp(KeyUpEvent event) {
                if (event.getNativeKeyCode() == KeyCodes.KEY_ENTER) {
                    messageService.getMessage(txtName.getValue(), new MessageCallBack());
                }
            }
        });
        Label lblName = new Label("Enter your name: ");

        Button buttonMessage = new Button("Click Me!");
        buttonMessage.addClickHandler(new ClickHandler() {
            @Override
            public void onClick(ClickEvent event) {
                messageService.getMessage(txtName.getValue(), new MessageCallBack());
            }
        });

        HorizontalPanel hPanel = new HorizontalPanel();
        hPanel.add(lblName);
        hPanel.add(txtName);
        hPanel.setCellWidth(lblName, "130");

        VerticalPanel vPanel = new VerticalPanel();
        vPanel.setSpacing(10);
        vPanel.add(hPanel);
        vPanel.add(buttonMessage);
        vPanel.setCellHorizontalAlignment(buttonMessage, HasHorizontalAlignment.ALIGN_RIGHT);

        DecoratorPanel panel = new DecoratorPanel();
        panel.add(vPanel);
        panel.addStyleName("decorator-panel");

        rootPanel.add(panel);
    }
}
`;

const SampleGWTAppHtml = `
<!doctype html>
<html>
<head>
    <title>Sample GWT App</title>
    <link rel="stylesheet" href="SampleGWTApp.css"/>
    <script type="text/javascript" src="samplegwtapp/samplegwtapp.nocache.js"></script>
</head>
<body>
<h1>RPC Communication Demonstration</h1>
<div id="gwtContainer"></div>
</body>
</html>
`;

const SampleGWTAppCss = `
body {
   text-align: center;
   font-family: verdana, sans-serif;
}

h1 {
   font-size: 2em;
   font-weight: bold;
   color: #777777;
   margin: 40px 0 30px 0;
   text-align: center;
}

#gwtContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 80vh;
   margin-top: -170px;
}

.decorator-panel {
   display: inline-block;
}
`;

const webxml = `
<?xml version="1.0" encoding="UTF-8"?>
<web-app xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
         xsi:schemaLocation="http://java.sun.com/xml/ns/javaee 
              http://java.sun.com/xml/ns/javaee/web-app_2_5.xsd"
         version="2.5"
         xmlns="http://java.sun.com/xml/ns/javaee">

  <!-- Servlets -->
  <servlet>
    <servlet-name>messageService</servlet-name>
    <servlet-class>com.nipuna.codesnippets.gwt.server.MessageServiceImpl</servlet-class>
  </servlet>

  <servlet-mapping>
    <servlet-name>messageService</servlet-name>
    <url-pattern>/samplegwtapp/message</url-pattern>
  </servlet-mapping>

  <welcome-file-list>
    <welcome-file>SampleGWTApp.html</welcome-file>
  </welcome-file-list>

</web-app>

`;

const GWTRpcCall: React.FC = () => {
  return (
    <div>
      <h2>GWT RPC</h2>
      <p>
        Remote Procedure Call (RPC) is a protocol that one program can use to
        request a service from a program located on another computer in a
        network. In GWT, RPC allows you to make server calls from the client
        side (browser) to the server side (Java backend).
      </p>

      <h3>Steps to Implement RPC in GWT</h3>

      <h4>1. Create the GWT Project</h4>
      <p>
        We have already created the project using the following command:
        <pre>
          webAppCreator -out SampleGWTApp -junit
          "C:\gwt-2.11.0\junit-4.13.2.jar"
          com.nipuna.codesnippets.gwt.SampleGWTApp
        </pre>
      </p>

      <h4>2. Define the Data Transfer Object (DTO)</h4>
      <p>
        Create a serializable DTO class for data exchange.
        <br />
        <strong>File Path:</strong>{" "}
        SampleGWTApp/src/com/nipuna/codesnippets/gwt/client/Message.java
      </p>
      <div className="listitems">
        <pre>{messageClass}</pre>
      </div>

      <h4>3. Define the Service Interface</h4>
      <p>
        Define the service interface that declares the method to be called from
        the client.
        <br />
        <strong>File Path:</strong>{" "}
        SampleGWTApp/src/com/nipuna/codesnippets/gwt/client/MessageService.java
      </p>
      <div className="listitems">
        <pre>{messageServiceClass}</pre>
      </div>

      <h4>4. Define the Async Interface</h4>
      <p>
        Define the asynchronous counterpart of the service interface.
        <br />
        <strong>File Path:</strong>{" "}
        SampleGWTApp/src/com/nipuna/codesnippets/gwt/client/MessageServiceAsync.java
      </p>
      <div className="listitems">
        <pre>{messageServiceAsyncClass}</pre>
      </div>

      <h4>5. Implement the Service</h4>
      <p>
        Create the server-side implementation of the service interface.
        <br />
        <strong>File Path:</strong>{" "}
        SampleGWTApp/src/com/nipuna/codesnippets/gwt/server/MessageServiceImpl.java
      </p>
      <div className="listitems">
        <pre>{messageServiceImplClass}</pre>
      </div>

      <h4>6. Configure the Service in web.xml</h4>
      <p>
        Map the service to a URL in your web application's deployment
        descriptor.
        <br />
        <strong>File Path:</strong> SampleGWTApp/war/WEB-INF/web.xml
      </p>
      <div className="listitems">
        <pre>{webxml}</pre>
      </div>

      <h4>7. Create/Update the Entry Point Class</h4>
      <p>
        Create or update the main client-side class that initializes the UI and
        makes the RPC call.
        <br />
        <strong>File Path:</strong>{" "}
        SampleGWTApp/src/com/nipuna/codesnippets/gwt/client/SampleGWTApp.java
      </p>
      <div className="listitems">
        <pre>{sampleGwtappJavaClass}</pre>
      </div>

      <h4>8. Update the HTML and CSS</h4>
      <p>
        Ensure the HTML and CSS files are properly configured to load the GWT
        application and style it.
        <br />
        <strong>File Path:</strong> SampleGWTApp/war/SampleGWTApp.html
      </p>
      <div className="listitems">
        <pre>{SampleGWTAppHtml}</pre>
      </div>
      <p>
        <strong>File Path:</strong> SampleGWTApp/war/SampleGWTApp.css
      </p>
      <div className="listitems">
        <pre>{SampleGWTAppCss}</pre>
      </div>
      <h4>9. Running the Project</h4>
      <p>
        <ol>
          <li>Complile and run the application in GWT development mode.</li>
          <li>
            To access the application, open a web browser and navigate to{" "}
            <code>http://127.0.0.1:8888/SampleGWTApp.html</code>. This URL
            should open the GWT Development Mode and allow you to interact with
            your RPC-based application.
          </li>
          <li>
            To test the application, enter a name in the text box and click the
            "Click Me!" button or press Enter to see the response from the
            server.
          </li>
        </ol>
        <h3>UI screenshots will look like below:</h3>
        <p>
          This screenshot shows the initial state of the application. The user
          sees the "RPC Communication Demonstration" heading and a text box
          labeled "Enter your name:" ready for input. The "Click Me!" button is
          also visible, but no interaction has occurred yet.
        </p>
        <img
          src="/rpc_gwt.png"
          alt="rpc_gwt"
          style={{ width: "500px", height: "400px" }}
        />
        <p>
          This screenshot displays the application after the user has entered
          their name and clicked the "Click Me!" button. A pop-up message
          appears, greeting the user with "Hello [Name]!" This indicates that
          the RPC call was successful, and the server has responded with a
          personalized message.
        </p>
        <img
          src="/rpc_gwt_1.png"
          alt="rpc_gwt_1"
          style={{ width: "500px", height: "400px" }}
        />
        <h3>How RPC Works:</h3>
        <p>
          When a user interacts with the GWT application (e.g., clicks the
          "Click Me!" button), the client-side code calls the asynchronous
          method defined in the service interface. This request is sent to the
          server, which processes it in the corresponding implementation class.
          The server then creates a response (in this case, a Message object)
          and sends it back to the client, where it is handled by the callback
          function. This seamless communication between the client and server
          allows for dynamic web applications that can provide real-time
          feedback and interaction.
        </p>
      </p>
    </div>
  );
};

export default GWTRpcCall;
