import React from "react";

const NgnixAddRawHTML = `
location / {
  proxy_pass http://localhost:3000;
  proxy_set_header Host $host;
  proxy_set_header X-Real-IP $remote_addr;
  proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
  proxy_set_header X-Forwarded-Host $host;
  proxy_set_header X-Forwarded-Server $host;
  proxy_set_header X-Forwarded-Port $server_port;
  proxy_set_header X-Forwarded-Proto $scheme;
  dav_methods PUT DELETE MKCOL COPY MOVE;
}
`;

const NgnixRemoveRawHTML = `
location / {
  root html;
  index index.html index.htm;
}
`;

const ExposingNgrokThroughUI: React.FC = () => {
  return (
    <div>
      <h2>Steps to Point UI from Ngrok </h2>
      <p>Below are the steps we follow to expose Ngrok through UI:</p>
      <li>
        <strong>NGINX Configuration:</strong> Change the ngnix.conf file. Add
        the following code snippet:
      </li>
      <div className="listitems">
        <pre>{NgnixAddRawHTML}</pre>
      </div>
      <p>Remove the existing code snippet:</p>
      <div className="listitems">
        <pre>{NgnixRemoveRawHTML}</pre>
      </div>
      <p>Now, the NGINX configuration has been updated to point to the UI.</p>
      <li>
        <strong>Ngrok Setup:</strong> Start Ngrok on the NGINX port 4200 using
        the command <code>ngrok http 4200</code>. Use the Ngrok URL (e.g.,{" "}
        <a href="https://cde7-2401-4900-1c29-2be0-2d3b-ae56-3e79-fc10.ngrok-free.app">
          https://cde7-2401-4900-1c29-2be0-2d3b-ae56-3e79-fc10.ngrok-free.app
        </a>
        ) to access the UI.
      </li>
      <li>
        By following these steps, you can properly configure environment with
        NGINX ensuring that your UI is accessible through the ngrok URL and all
        necessary settings are synchronized across the relevant platforms.
      </li>
    </div>
  );
};

export default ExposingNgrokThroughUI;
