import React from "react";

interface ContentDisplayProps {
  activeContent: React.ReactNode;
}

const ContentDisplay: React.FC<ContentDisplayProps> = ({ activeContent }) => {
  return (
    <div className="content">
      <>{activeContent}</>
    </div>
  );
};

export default ContentDisplay;
