import React from "react";

const dockerComposeFile = `
version: '3'

services:
  redis:
    image: redis:latest
    ports:
      - "6379:6379"
`;

const dockerComposeUp = `
docker-compose up
`;

const springDependency = `
implementation 'redis.clients:jedis:3.7.0'
`;

const redisServiceFile = `
package com.nipuna.ivacomms.redis;

import org.springframework.stereotype.Service;
import redis.clients.jedis.Jedis;

@Service
public class RedisService {

    private final Jedis jedis;

    public RedisService() {
        this.jedis = new Jedis("localhost");
    }

    public void setValue(String key, String value) {
        jedis.set(key, value);
    }

    public String getValue(String key) {
        return jedis.get(key);
    }
}
`;

const redisController = `
package com.nipuna.ivacomms.redis;

import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.web.bind.annotation.*;

@RestController
@RequestMapping("/redis")
public class RedisController {

    private final RedisService redisService;

    @Autowired
    public RedisController(RedisService redisService) {
        this.redisService = redisService;
    }

    @PostMapping("/set")
    public void setValue(@RequestParam String key, @RequestParam String value) {
        redisService.setValue(key, value);
    }

    @GetMapping("/get")
    public String getValue(@RequestParam String key) {
        return redisService.getValue(key);
    }
}
`;

const npmDependencies = `
npm install express @redis/client body-parser
`;

const appjsFile = `
const express = require('express');
const { createClient } = require('@redis/client');
const bodyParser = require('body-parser');

const app = express();
const port = 3000;

app.use(bodyParser.json());

(async () => {
    const client = createClient();
    
    client.on('error', (err) => console.log('Redis Client Error', err));
    
    await client.connect();
    
    app.post('/redis/set', async (req, res) => {
        const { key, value } = req.body;
        try {
            await client.set(key, value);
            res.send('Value set in Redis');
        } catch (error) {
            console.error(error);
            res.status(500).send('Internal Server Error');
        }
    });
    
    app.get('/redis/get/:key', async (req, res) => {
        const key = req.params.key;
        try {
            const value = await client.get(key);
            if (value === null) {
                res.status(404).send('Key not found in Redis');
            } else {
                res.send(value);
            }
        } catch (error) {
            console.error(error);
            res.status(500).send('Internal Server Error');
        }
    });
    
    app.listen(port, () => {
        console.log('Server is running on http://localhost:dollarSymbol{port}');
    });
})();

`;

const Redis: React.FC = () => {
  return (
    <div>
      <h2>Redis</h2>
      <p>
        Redis (Remote Dictionary Server) is an open-source, in-memory data
        structure store used as a database, cache, and message broker. It
        supports data structures such as strings, hashes, lists, sets, and more.
        Redis is known for its high performance, flexibility, and wide language
        support.
      </p>
      <h3>Setting Up Redis with Docker</h3>
      <p>
        To run Redis locally, you can use Docker. Here is a docker-compose.yml
        file to spin up a Redis container:
      </p>
      <div className="listitems">
        <pre>{dockerComposeFile}</pre>
      </div>
      <p>Navigate to the directory containing docker-compose.yml and run:</p>
      <div className="listitems">
        <pre>{dockerComposeUp}</pre>
      </div>
      <p>
        This command will download the latest Redis image and start a Redis
        container, mapping port 6379 of the container to port 6379 on your local
        machine.
      </p>
      <h3>Java Spring Boot with Redis</h3>
      <p>1. Add the following dependency to your build.gradle file:</p>
      <div className="listitems">
        <pre>{springDependency}</pre>
      </div>
      <h4>RedisService.java</h4>
      <p>This service class interacts with Redis:</p>
      <div className="listitems">
        <pre>{redisServiceFile}</pre>
      </div>
      <p>
        This service class handles the interaction with the Redis database,
        providing methods to set and get values.
      </p>
      <h4>RedisController.java</h4>
      <p>This controller exposes REST endpoints to interact with Redis:</p>
      <div className="listitems">
        <pre>{redisController}</pre>
      </div>
      <p>
        This controller class exposes REST endpoints for setting and retrieving
        values in Redis, utilizing the RedisService class.
      </p>
      <h3>Node.js with Express and Redis</h3>
      <p>1.Install the required npm packages:</p>
      <div className="listitems">
        <pre>{npmDependencies}</pre>
      </div>
      <h4>app.js</h4>
      <p>
        Here is the code for a simple Express application with Redis
        integration:
      </p>
      <div className="listitems">
        <pre>{appjsFile}</pre>
      </div>
      <p>
        This file sets up a basic Express server that connects to Redis and
        provides endpoints to set and retrieve values stored in the Redis
        database.
      </p>
      <h4>Testing Endpoints with Postman</h4>
      <p>
        1. <strong>Set a Value in Redis (POST)</strong>
      </p>
      <p>To set a value in Redis, follow these steps:</p>
      <ul>
        <li>Open Postman.</li>
        <li>Create a new request and set the request type to POST.</li>
        <li>
          Enter the URL of your endpoint:{" "}
          <code>http://localhost:8080/redis/set</code> (for Java Spring Boot) or{" "}
          <code>http://localhost:3000/redis/set</code> (for Node.js).
        </li>
        <li>Click on the "Body" tab below the URL bar.</li>
        <li>
          Select the "raw" option and then select "JSON" from the dropdown.
        </li>
        <li>Add a JSON object in the request body. For example:</li>
        <pre>
          {`
{
  "key": "myKey",
  "value": "myValue"
}
          `}
        </pre>
        <li>Click on the "Send" button to send the request.</li>
      </ul>
      <p>
        2. <strong>Get a Value from Redis (GET)</strong>
      </p>
      <p>To get a value from Redis, follow these steps:</p>
      <ul>
        <li>Open Postman.</li>
        <li>Create a new request and set the request type to GET.</li>
        <li>
          Enter the URL of your endpoint:{" "}
          <code>http://localhost:8080/redis/get?key=myKey</code> (for Java
          Spring Boot) or <code>http://localhost:3000/redis/get/myKey</code>{" "}
          (for Node.js).
        </li>
        <li>Click on the "Send" button to send the request.</li>
      </ul>
    </div>
  );
};

export default Redis;
