import React from "react";
const puginstall = `
yarn add pug
`;

const pugtemplate = `
app.set('view engine', 'pug');
app.set('views','./views');
`;

const pugcode = `
doctype html
html
   head
      title = "Hello Pug"
   body
      p.greetings#people Hello World!
`;

const pugrun = `
app.get('/first_template', function(req, res){
  res.render('first_view');
});
`;

const templating = `
app.get("/first_template", function (req, res) {
  res.render("first_view", {
    name: "NipunaSolutions",
    url: "https://nipunasolutions.co.uk/",
  });
});
`;

const pugexample = `
html
  head
    title Form Tester
    style.
      body {
        font-family: 'Arial', sans-serif;
        background-color: rgb(5 84 141);
        text-align: center;
      }
      form {
        max-width: 400px;
        margin: 20px auto;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: 1px solid black
      }
      label {
        display: block;
        margin-bottom: 8px;
        text-align: left;
      }
      input, select, button {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid black;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
      }
      button {
        background-color: #4caf50;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      button:hover {
        background-color: #45a049;
      }
      .reset-button {
        background-color: #f44336;
      }
      .reset-button:hover {
        background-color: #d32f2f;
      }
      .checkbox-group, .radio-group {
        display: flex;
        align-items: center;
      }
      .checkbox-group label, .radio-group label {
        margin-right: 25px;
      }
  body
    form(action="/submit-form", method="POST", enctype="multipart/form-data",style="background-color: rgb(225 230 233);")
      div
        label(for="firstName") First Name:
        input(type="text", name="firstName", required="")
      br
      div
        label(for="lastName") Last Name:
        input(type="text", name="lastName", required="")
      br
      div
        label(for="dob") Date of Birth:
        input(type="date", name="dob", required="")
      br
      div
        label(for="yearPassed") Year Passed:
        select(name="yearPassed", required="")
          option(value="2022") 2022
          option(value="2021") 2021
          option(value="2020") 2020
      br
      div.checkbox-group
        label(for="subjects") Subjects:
        br
        input(type="checkbox", name="math", value="Math", class="subject")
        label Math
        input(type="checkbox", name="science", value="Science", class="subject")
        label Science
        input(type="checkbox", name="english", value="English", class="subject")
        label English
      br
      div.radio-group
        label(for="grades") Grades:
        br
        input(type="radio", name="grades", value="A")
        label A
        input(type="radio", name="grades", value="B")
        label B
        input(type="radio", name="grades", value="C")
        label C
      br
      div
        label(for="file") Upload CV:
        input(type="file", name="file", id="fileInput", multiple, accept=".pdf, .doc, .docx, .png")
      br
      button(type="submit") Submit
      button(type="reset", class="reset-button") Reset
`;

const pugdeclarartion = `
app.get("/application-data", upload.array(), (req, res) => {
  res.render("application");
});
`;

const Pug: React.FC = () => {
    return (
        <>
            <h3>ExpressJS - Templating, PUG</h3>
            <p>Pug is a templating engine for Express. Templating engines are used to remove the cluttering of our server code with HTML, concatenating strings wildly to existing HTML templates. Pug is a very powerful templating engine which has a variety of features including filters, includes, inheritance, interpolation, etc. </p>
            <p>To use Pug with Express, we need to install it,</p>
            <div className="listitems">
                <pre>{puginstall}</pre>
            </div>
            <p>Now that Pug is installed, set it as the templating engine for your app. </p>
            <div className="listitems">
                <pre>{pugtemplate}</pre>
            </div>
            <p>Now create a new directory called views. Inside that create a file called first_view.pug, and enter your desired data in it.</p>
            <div className="listitems">
                <pre>{pugcode}</pre>
            </div>
            <p>To run this page, add the following route :</p>
            <div className="listitems">
                <pre>{pugrun}</pre>
            </div>
            <p>Pug converts this very simple looking markup to html. We don’t need to keep track of closing our tags, no need to use class and id keywords, rather use '.' and '#' to define them.</p>
            <h4>
                Templating (PUG)
            </h4>
            <div className="listitems">
                <pre>{templating}</pre>
            </div>
            <h3>PUG example</h3>
            <p>This is an example code of PUG, which also contains styles and a live code demo of an application form.</p>
            <div className="listitems">
                <pre>{pugexample}</pre>
            </div>
            <p>using Get method to declare it</p>
            <div className="listitems">
                <pre>{pugdeclarartion}</pre>
            </div>
        </>
    );
};

export default Pug;