import React from "react";

const ExposingNgrokThroughCognito: React.FC = () => {
  return (
    <div>
      <h2>Configure ngrok callback url in Cognito</h2>
      <p>Below are the steps we follow to expose Ngrok through AWS Cognito:</p>
      <li>
        Open AWS Cognito, Select user pool and Open APP Integration. Hosted UI
        configuration should be as in the below screenshot based on the ngrox
        url:
        <div className="listitems">
          <img
            src="/aws_nrok_ui_settings.png"
            alt="Edit app registration - Step 4"
          />
        </div>
      </li>
      <li>
        <strong>Update application.yml:</strong> Update the user pool details in
        the <code>user-api</code> application.yml file.
      </li>
      <li>
        <strong>
          Update <code>ncs-ui</code> LoginPage.tsx:
        </strong>{" "}
        Change the <code>AUTH_PROVIDER</code> and <code>CLIENT_ID</code> in the{" "}
        <code>LoginPage.tsx</code> file of the UI application to align with the
        updated user pool details.
      </li>
      <li>
        By following these steps, you can properly configure ngrok environment
        with AWS Cognito.
      </li>
    </div>
  );
};

export default ExposingNgrokThroughCognito;
