import React from "react";

const corsinst = `
npm install cors

OR

yarn add cors

`;

const cors_ex = `
const express = require('express');
const cors = require('cors');

const app = express();

// Use cors middleware
app.use(cors());

// routes and other middleware go here

const PORT = 3000;
app.listen(PORT, () => {
  console.log('Server is running on http://localhost: 3000');
});
`;

const Cors: React.FC = () => {
  return (
    <>
      <h1>CORS</h1>
      <p>
        CORS, or Cross-Origin Resource Sharing, is a security feature
        implemented by web browsers that restricts web pages from making
        requests to a different domain than the one that served the web page.
        This security measure is in place to prevent potentially harmful
        cross-origin requests.
      </p>
      <h2>Why CORS is Needed?</h2>
      <p>
        Imagine you have a frontend application hosted on one domain and an API
        server hosted on another. If the browser did not enforce CORS, any web
        page could make requests to your API, potentially leading to security
        vulnerabilities.
      </p>
      <p>
        In the vast landscape of the internet, web pages often need to fetch
        resources (like data or assets) from different domains. CORS acts as a
        security checkpoint to prevent malicious websites from making
        unauthorized requests on behalf of a user.
      </p>
      <h2>How CORS Works:</h2>
      <p>
        In an Express application, integrating CORS involves utilizing
        middleware to handle HTTP headers. These headers inform the browser
        about the permissions granted for resource access. With CORS middleware,
        you can define which origins are allowed and what types of requests
        (GET, POST, etc.) are permitted.
      </p>
      <ul>
        <li>
          <strong>Browser Pre-flight Request:</strong>When a web page makes a
          cross-origin request using certain methods or headers (e.g., PUT,
          DELETE, or custom headers), the browser first sends a pre-flight
          request (HTTP OPTIONS) to the server.
        </li>
        <li>
          <strong>Server Approval:</strong> The server needs to respond with
          appropriate CORS headers indicating which origins are allowed to
          access the resources. If the server approves the request, the actual
          request is then made.
        </li>
      </ul>
      <h3>Enabling CORS in Express:</h3>
      <div className="listitems">
        <pre>{corsinst}</pre>
      </div>
      <h3>Using CORS in Express.js</h3>
      <div className="listitems">
        <pre>{cors_ex}</pre>
      </div>
      <p>
        This middleware, when applied to your Express app, automatically adds
        the necessary CORS headers to the responses, allowing cross-origin
        requests.
      </p>
      <h3>Benefits of CORS in Express:</h3>
      <ul>
        <li><strong>Security:</strong> CORS safeguards your application from unauthorized access and potential security threats.</li>
        <li><strong>Flexibility: </strong>It enables controlled sharing of resources, ensuring seamless integration with trusted domains.</li>
        <li><strong>User Experience:</strong> CORS contributes to a positive user experience by facilitating the smooth loading of resources from different origins.</li>
      </ul>
    </>
  );
};

export default Cors;
