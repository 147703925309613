import React from "react";

const dockerComposeFile = `
version: '3.8'

services:
  localstack:
    image: localstack/localstack
    ports:
      - "4566-4599:4566-4599"
    environment:
      - SERVICES=sqs
      - DEFAULT_REGION=eu-west-2
      - AWS_ACCESS_KEY_ID=test
      - AWS_SECRET_ACCESS_KEY=test
    networks:
      - localstack_network

networks:
  localstack_network:
    driver: bridge

`;

const dependencies = `
dependencies {
  implementation 'org.springframework.boot:spring-boot-starter-web'
  implementation 'org.springframework.boot:spring-boot-starter-actuator'
  implementation 'software.amazon.awssdk:sqs'
  implementation 'org.projectlombok:lombok'
  annotationProcessor 'org.projectlombok:lombok'
  testImplementation 'org.springframework.boot:spring-boot-starter-test'
}
`;

const applicationYml = `
localstack:
  sqs:
    endpoint: http://localhost:4566
`;

const sqsService = `
package com.nipuna.sqsLocalStack;

import org.springframework.beans.factory.annotation.Value;
import org.springframework.stereotype.Service;
import software.amazon.awssdk.regions.Region;
import software.amazon.awssdk.services.sqs.SqsClient;
import software.amazon.awssdk.services.sqs.model.*;

import javax.annotation.PostConstruct;
import java.net.URI;
import java.util.List;
import java.util.stream.Collectors;

@Service
public class SqsService {

    @Value("dollarSymbol{localstack.sqs.endpoint}")
    private String localstackEndpoint;

    private SqsClient sqsClient;

    @PostConstruct
    private void init() {
        sqsClient = SqsClient.builder()
                .region(Region.US_EAST_1)
                .endpointOverride(URI.create(localstackEndpoint))
                .build();
    }

    public CreateQueueResponse createQueue(CreateQueueRequest createQueueRequest) {
        return sqsClient.createQueue(createQueueRequest);
    }

    public String getQueueUrl(String queueName) {
        GetQueueUrlRequest getQueueUrlRequest = GetQueueUrlRequest.builder()
                .queueName(queueName)
                .build();
        return sqsClient.getQueueUrl(getQueueUrlRequest).queueUrl();
    }

    public String sendMessage(String queueUrl, String messageBody) {
        SendMessageRequest sendRequest = SendMessageRequest.builder()
                .queueUrl(queueUrl)
                .messageBody(messageBody)
                .build();

        SendMessageResponse sendResponse = sqsClient.sendMessage(sendRequest);
        return sendResponse.messageId();
    }

    public List<MessageDTO> receiveMessages(String queueUrl, int maxMessages) {
        ReceiveMessageRequest receiveRequest = ReceiveMessageRequest.builder()
                .queueUrl(queueUrl)
                .maxNumberOfMessages(maxMessages)
                .waitTimeSeconds(20)
                .build();

        ReceiveMessageResponse receiveResponse = sqsClient.receiveMessage(receiveRequest);
        return receiveResponse.messages().stream()
                .map(message -> new MessageDTO(message.messageId(), message.body()))
                .collect(Collectors.toList());
    }
}

`;

const sqsController = `
package com.nipuna.sqsLocalStack;

import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.http.HttpStatus;
import org.springframework.http.ResponseEntity;
import org.springframework.web.bind.annotation.*;
import software.amazon.awssdk.services.sqs.model.CreateQueueRequest;
import software.amazon.awssdk.services.sqs.model.CreateQueueResponse;

import java.util.List;

@RestController
@RequestMapping("/sqs")
public class SqsController {

    @Autowired
    private SqsService sqsService;

    @PostMapping("/create")
    public ResponseEntity<String> createQueue(@RequestParam String queueName) {
        CreateQueueRequest createQueueRequest = CreateQueueRequest.builder()
                .queueName(queueName)
                .build();
        CreateQueueResponse createQueueResponse = sqsService.createQueue(createQueueRequest);
        return ResponseEntity.status(HttpStatus.CREATED).body("Queue created. QueueUrl: " + createQueueResponse.queueUrl());
    }

    @PostMapping("/send")
    public ResponseEntity<String> sendMessage(@RequestParam String queueName, @RequestParam String messageBody) {
        String queueUrl = sqsService.getQueueUrl(queueName);
        String messageId = sqsService.sendMessage(queueUrl, messageBody);
        return ResponseEntity.status(HttpStatus.CREATED).body("Message sent. MessageId: " + messageId);
    }

    @GetMapping("/receive")
    public ResponseEntity<List<MessageDTO>> receiveMessages(@RequestParam String queueName, @RequestParam int maxMessages) {
        String queueUrl = sqsService.getQueueUrl(queueName);
        List<MessageDTO> messages = sqsService.receiveMessages(queueUrl, maxMessages);
        return ResponseEntity.ok(messages);
    }
}

`;

const messageDto = `
package com.nipuna.sqsLocalStack;

import lombok.AllArgsConstructor;
import lombok.Data;
import lombok.NoArgsConstructor;

@Data
@NoArgsConstructor
@AllArgsConstructor
public class MessageDTO {
    private String messageId;
    private String body;
}

`;

const SQSLocalStack: React.FC = () => {
  return (
    <div>
      <h2>SQS LocalStack</h2>
      <p>
        Amazon Simple Queue Service (SQS) is a fully managed message queuing
        service that enables you to decouple and scale microservices,
        distributed systems, and serverless applications. LocalStack is a fully
        functional local AWS cloud stack that allows you to develop and test
        your cloud applications offline.
      </p>
      <h3>Setting Up LocalStack with Docker Compose:</h3>
      <p>
        Create a <strong>docker-compose.yml</strong> file to set up LocalStack
        as below:
      </p>
      <div className="listitems">
        <pre>{dockerComposeFile}</pre>
      </div>
      <p> Start LocalStack using command <strong>docker-compose up</strong> in command prompt.</p>
      <h3>Spring Boot Application Configuration:</h3>
      <p>
        1. Ensure your <strong>build.gradle</strong> file includes these
        dependencies:
      </p>
      <div className="listitems">
        <pre>{dependencies}</pre>
      </div>
      <p>
        2. Configure the LocalStack SQS endpoint URL in{" "}
        <strong> application.yml</strong> :
      </p>
      <div className="listitems">
        <pre>{applicationYml}</pre>
      </div>
      <p>
        3. Need to add class <strong>MessageDTO.java</strong> simple Data
        Transfer Object (DTO) for SQS messages, using Lombok for boilerplate
        code reduction.
      </p>
      <div className="listitems">
        <pre>{messageDto}</pre>
      </div>
      <p>
        4. Need to add Service class <strong>SqsService.java</strong> that
        handles interactions with the SQS service, such as creating queues,
        sending, and receiving messages.
      </p>
      <div className="listitems">
        <pre>{sqsService}</pre>
      </div>
      <p>
        <strong>Note:</strong> dollarSymbol text in above code snippets should
        be replaced with dollar symbol
      </p>
      <p>
        5. <strong>SqsController.java</strong> controller that provides
        endpoints for creating queues, sending messages, and receiving messages
        from SQS.
      </p>
      <div className="listitems">
        <pre>{sqsController}</pre>
      </div>
      <h3>Testing the Endpoints using Postman</h3>
      <p><strong>Create a Queue:</strong></p>
      <ul>
        <li><strong>Method:</strong> POST</li>
        <li><strong>URL:</strong> http://localhost:8080/sqs/create</li>
        <li><strong>Params:</strong> queueName=your-queue-name</li>
      </ul>
      <p><strong>Send a Message:</strong></p>
      <ul>
        <li><strong>Method:</strong> POST</li>
        <li><strong>URL:</strong> http://localhost:8080/sqs/send</li>
        <li><strong>Params:</strong> queueName=your-queue-name, messageBody=your-message-body</li>
      </ul>
      <p><strong>Receive Messages:</strong></p>
      <ul>
        <li><strong>Method:</strong> GET</li>
        <li><strong>URL:</strong> http://localhost:8080/sqs/receive</li>
        <li><strong>Params:</strong> queueName=your-queue-name, maxMessages=10</li>
      </ul>
      <p>
        This guide covers setting up and using Amazon SQS with LocalStack in a
        local development environment using Docker Compose and a Spring Boot
        application. It includes configuration details, service and controller
        implementations. This setup allows you to develop and test your SQS
        integrations offline, providing a seamless development workflow.
        Configuration settings in application.yml ensure that the application
        knows where to connect for the SQS services.
      </p>
    </div>
  );
};

export default SQSLocalStack;
