import React from "react";

const serverJs = `
const express = require('express');
const crypto = require('crypto');

const app = express();
const PORT = 3000;

// Generate ETag for a given data
function generateETag(data) {
    const hash = crypto.createHash('sha1');
    hash.update(data);
    return hash.digest('hex');
}

// Sample data
const sampleData = {
    message: 'Hello, ETag!',
};

// Route to handle requests
app.get('/', (req, res) => {
    const etag = generateETag(JSON.stringify(sampleData));
    res.set('ETag', etag);

    // Check if the client's ETag matches the server's ETag
    if (req.get('If-None-Match') === etag) {
        res.status(304).end(); // Not Modified
    } else {
        res.json(sampleData);
    }
});

// Start the server
app.listen(PORT, () => {
    console.log('Server is running on http://localhost:dollarSign{PORT}');
});

`;

const Etag: React.FC = () => {
  return (
    <div>
      <h2>ETag</h2>
      <p>
        ETag, short for Entity Tag, is an HTTP header used for web cache
        validation and conditional requests. It's a mechanism for web servers to
        determine whether a resource has changed since the client's last
        request. The server generates an ETag for each version of a resource,
        and the client sends this ETag back to the server with subsequent
        requests. The server then compares the client's ETag with the current
        ETag for the resource to decide whether to send the resource's content
        or a "304 Not Modified" response.
      </p>
      <p>
        In simple terms, an ETag is like a fingerprint for a specific version of
        a resource. If the resource changes, its ETag changes, and the client
        knows it needs to fetch the updated content. If the ETag remains the
        same, the client can use its cached version, reducing unnecessary data
        transfer. ETags are often generated based on the resource's content,
        typically using hash functions like MD5 or SHA1. This ensures that even
        a slight change in the resource results in a different ETag. Overall,
        ETags play a crucial role in optimizing web performance by facilitating
        efficient caching and minimizing unnecessary data transfer between
        clients and servers.
      </p>
      <h2>Setting Up a Node.js Server with ETags</h2>
      <p>
        Step 1: First, make sure you have Node.js installed on your system.
        Then, create a new directory for your project and initialize a new
        Node.js project using npm (Node Package Manager).
      </p>
      <pre>
        <code>
          {`
mkdir etag-example
cd etag-example
npm init -y
          `}
        </code>
      </pre>

      <h2>Install Dependencies</h2>
      <p>
        Step 2: For this project, we'll use the Express framework for creating a
        simple web server.
      </p>
      <pre>
        <code>
          {`
npm install express
          `}
        </code>
      </pre>

      <h2>Create Your Server</h2>
      <p>
        Step 3: Create a new file, let's name it <code>server.js</code>, and 
        below is a sample Node.js code demonstrating how to set up a server that
        generates ETags for resources:
      </p>
      <div className="listitems">
        <pre>{serverJs}</pre>
      </div>
      <p>
        This code sets up an Express server that responds to requests with an
        ETag header. It generates an ETag based on the resource's content and
        compares it with the client's ETag to determine whether to send the
        content or a "304 Not Modified" response.
      </p>
      <p>
        Your server will start running on http://localhost:3000. You can open
        this URL in your browser to see the response.
      </p>

      <h2>Understanding the Code</h2>
      <p>
        - We're using Express to create a simple web server.
        <br />
        - The generateETag function calculates the ETag using SHA1 hash.
        <br />
        - When a client requests the root URL (/), the server sends the ETag
        along with the response.
        <br />
        - The client's ETag is sent back with subsequent requests in the
        If-None-Match header.
        <br />
        - If the client's ETag matches the server's ETag, the server responds
        with a status code 304 Not Modified, indicating that the content hasn't
        changed since the client's last request.
        <br />
        - This setup demonstrates basic ETag generation and validation in
        Node.js using Express. You can expand upon this example as needed for
        the project.
      </p>
    </div>
  );
};

export default Etag;
