import React, { useEffect, useState } from "react";
import "./LeftMenu.css";
import Pug from "../../screens/Pug/Pug";
import CookieParser from "../../screens/CookieParser/CookieParser";
import RabbitMq from "../../screens/RabbitMq/RabbitMq";
import Kafka from "../../screens/Kafka/Kafka";
import CorrelationId from "../../screens/CorrelationId/CorrelationId";
import Etag from "../../screens/Etag/Etag";
import WebSocket from "../../screens/WebSocket/WebSocket";
import SoapApi from "../../screens/SoapApi/SoapApi";
import DynamoDB from "../../screens/DynamoDB/DynamoDB";
import DynamoDBLocal from "../../screens/DynamoDBLocal/DynamoDBLocal";
import SQSLocalStack from "../../screens/SQSLocalStack/SQSLocalStack";
import SNSLocalStack from "../../screens/SNSLocalStack/SNSLocalStack";
import KeyCloak from "../../screens/KeyCloak/KeyCloak";
import GradleDockerCompose from "../../screens/GradleDockerCompose/GradleDockerCompose";
import ElasticSearch from "../../screens/ElasticSearch/ElasticSearch";
import SecretManager from "../../screens/SecretManager/SecretManager";
import Redis from "../../screens/Redis/Redis";
import GitCommands from "../../screens/GitCommands/GitCommands";
// import Java from "../../screens/Java/Java";
import MongoDB from "../../screens/MongoDB/MongoDB";
import ExpressJs from "../../screens/ExpressJs/ExpressJs";
import Nunjucks from "../../screens/Nunjucks/Nunjucks";
import Ngrox from "../../screens/Ngrok/Ngrok";
import ExposingNgrokThroughUI from "../../screens/ExposingNgrokThroughUI/ExposingNgrokThroughUI";
import ExposingNgrokThroughGoogleAuth from "../../screens/ExposingNgrokThroughGoogleAuth/ExposingNgrokThroughGoogleAuth";
import ExposingNgrokThroughCognito from "../../screens/ExposingNgrokThroughCognito/ExposingNgrokThroughCognito";
import AccessingECSApisUsingVpcLinks from "../../screens/AccessingECSApisUsingVpcLinks/AccessingECSApisUsingVpcLinks";
import MongoDBArchitecture from "../../screens/MongoDBArchitecture/MongoDBArchitecture";
import SagaDesignPattern from "../../screens/SagaDesignPattern/SagaDesignPattern";
import SolidPrinciples from "../../screens/SolidPrinciples/SolidPrinciples";
import CQRSPattern from "../../screens/CQRSPattern/CQRSPattern";
import Helmet from "../../screens/Helmet/Helmet";
import ExpressSession from "../../screens/ExpressSession/ExpressSession";
import Cors from "../../screens/Cors/Cors";
import Passport from "../../screens/Passport/Passport";
import APIArchitectureStyles from "../../screens/APIArchitectureStyles/APIArchitectureStyles";
import DistributedSystemPatterns from "../../screens/DistributedSystemPatterns/DistributedSystemPatterns";
import SqlVsNoSql from "../../screens/SqlVsNoSql/SqlVsNoSql";
import MongoDbVsMongoExpress from "../../screens/MongoDbVsMongoExpress/MongoDbVsMongoExpress";
import MongoDBExpress from "../../screens/MongoDBExpress/MongoDBExpress";
import CSSLearningPlan from "../../screens/CSSLearningPlan/CSSLearningPlan";
import HttpClient from "../../screens/HttpClient/HttpClient";
import Nginx from "../../screens/Nginx/Nginx";
import Morgan from "../../screens/Morgan/Morgan";
import GWT from "../../screens/GWT/GWT";
import CreateGWTProject from "../../screens/CreateGWTProject/CreateGWTProject";
import CreateFormUsingPanelGWT from "../../screens/CreateFormUsingPanelGWT/CreateFormUsingPanelGWT";
import EventHandlersGWT from "../../screens/EventHandlersGWT/EventHandlersGWT";
import GWTUIBinders from "../../screens/GWTUIBinders/GWTUIBinders";
import GWTRpcCall from "../../screens/GWTRpcCall/GWTRpcCall";
import Django from "../../screens/Django/Django";
import FlaskApi from "../../screens/FlaskApi/FlaskApi";

import { Module } from "module";
import Module01 from "../../screens/CSSLearningPlan/Module-01/Module-01";
import Module02 from "../../screens/CSSLearningPlan/Module-02/Module-02";
import Module03 from "../../screens/CSSLearningPlan/Module-03/Module-03";
import Module04 from "../../screens/CSSLearningPlan/Module-04/Module-04";
import Module05 from "../../screens/CSSLearningPlan/Module-05/Module-05";
import Module06 from "../../screens/CSSLearningPlan/Module-06/Module-06";
import Module07 from "../../screens/CSSLearningPlan/Module-07/Module-07";
import Module08 from "../../screens/CSSLearningPlan/Module-08/Module-08";
import Module09 from "../../screens/CSSLearningPlan/Module-09/Module-09";
import Module10 from "../../screens/CSSLearningPlan/Module-10/Module-10";
import WinstonConsoleBasedLogging from "../../screens/WinstonConsoleBasedLogging/WinstonConsoleBasedLogging";
import WinstonFileBasedLogging from "../../screens/WinstonFileBasedLogging/WinstonFileBasedLogging";

const LeftMenu: React.FC = () => {
  const [activeContent, setActiveContent] = useState<React.ReactNode>(
    <ExpressJs />
  );

  const [isDropdownOpenExpressJS, setIsDropdownOpenExpressJS] = useState(false);
  const [isDropdownOpenNodeJsLogging, setIsDropdownOpenNodeJsLogging] =
    useState(false);
  const [isDropdownOpenDesignPatterns, setIsDropdownOpenDesignPatterns] =
    useState(false);
  const [isDropdownOpenMongoDB, setIsDropdownOpenMongoDB] = useState(false);
  const [isDropdownOpenJava, setIsDropdownOpenJava] = useState(false);
  const [isDropdownOpenNgrok, setIsDropdownOpenNgrok] = useState(false);
  const [isDropdownOpenCognito, setIsDropdownOpenCognito] = useState(false);
  const [isDropdownOpenPython, setIsDropdownOpenPython] = useState(false);
  const [isDropdownOpenRabbitMq, setIsDropdownOpenRabbitMq] = useState(false);
  const [isDropdownOpenGwt, setIsDropdownOpenGwt] = useState(false);

  const [isDropdownOpenCSSLearningPlan, setIsDropdownOpenCSSLearningPlan] =
    useState(false);

  // Express JS
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenExpressJS(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenExpressJS
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenExpressJS]);

  const handleMenuClickExpressJS = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenExpressJS(false);
  };

  const handleDropdownClickExpressJS = () => {
    setIsDropdownOpenExpressJS(!isDropdownOpenExpressJS);
  };

  const handleExpandClickExpressJS = () => {
    setIsDropdownOpenExpressJS(true);
  };

  const handleCollapseClickExpressJS = () => {
    setIsDropdownOpenExpressJS(false);
  };

  // NodeJs Logging
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenNodeJsLogging(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenNodeJsLogging
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenNodeJsLogging]);

  const handleMenuClickNodeJsLogging = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenNodeJsLogging(false);
  };

  const handleDropdownClickNodeJsLogging = () => {
    setIsDropdownOpenNodeJsLogging(!isDropdownOpenNodeJsLogging);
  };

  const handleExpandClickNodeJsLogging = () => {
    setIsDropdownOpenNodeJsLogging(true);
  };

  const handleCollapseClickNodeJsLogging = () => {
    setIsDropdownOpenNodeJsLogging(false);
  };

  // Design Patterns
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenDesignPatterns(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenDesignPatterns
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenDesignPatterns]);

  const handleMenuClickDesignPatterns = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenDesignPatterns(false);
  };

  const handleDropdownClickesignPatterns = () => {
    setIsDropdownOpenDesignPatterns(!isDropdownOpenDesignPatterns);
  };

  const handleExpandClickesignPatterns = () => {
    setIsDropdownOpenDesignPatterns(true);
  };

  const handleCollapseClickDesignPatterns = () => {
    setIsDropdownOpenDesignPatterns(false);
  };

  // Mongo DB
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenMongoDB(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenMongoDB
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenMongoDB]);

  const handleMenuClickMongoDB = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenMongoDB(false);
  };

  const handleDropdownClickMongoDB = () => {
    setIsDropdownOpenMongoDB(!isDropdownOpenMongoDB);
  };

  const handleExpandClickMongoDB = () => {
    setIsDropdownOpenMongoDB(true);
  };

  const handleCollapseClickMongoDB = () => {
    setIsDropdownOpenMongoDB(false);
  };

  // Java
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenJava(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenJava
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenJava]);

  const handleMenuClickJava = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenJava(false);
  };

  const handleDropdownClickJava = () => {
    setIsDropdownOpenJava(!isDropdownOpenJava);
  };

  const handleExpandClickJava = () => {
    setIsDropdownOpenJava(true);
  };

  const handleCollapseClickJava = () => {
    setIsDropdownOpenJava(false);
  };

  //Ngrok
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenNgrok(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenNgrok
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenNgrok]);

  const handleMenuClickNgrok = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenNgrok(false);
  };

  const handleDropdownClickNgrok = () => {
    setIsDropdownOpenNgrok(!isDropdownOpenNgrok);
  };

  const handleExpandClickNgrok = () => {
    setIsDropdownOpenNgrok(true);
  };

  const handleCollapseClickNgrok = () => {
    setIsDropdownOpenNgrok(false);
  };

  // Cognito
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenCognito(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenCognito
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenCognito]);

  const handleMenuClickCognito = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenCognito(false);
  };

  const handleDropdownClickCognito = () => {
    setIsDropdownOpenCognito(!isDropdownOpenCognito);
  };

  const handleExpandClickCognito = () => {
    setIsDropdownOpenCognito(true);
  };

  const handleCollapseClickCognito = () => {
    setIsDropdownOpenCognito(false);
  };


// Python
useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".dropdown")) {
      setIsDropdownOpenPython(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

useEffect(() => {
  document.documentElement.style.setProperty(
    "--dropdown-height",
    isDropdownOpenPython
      ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
      : "0"
  );
}, [isDropdownOpenPython]);

const handleMenuClickPython = (content: React.ReactNode) => {
  setActiveContent(content);
  setIsDropdownOpenPython(false);
};

const handleDropdownClickPython = () => {
  setIsDropdownOpenPython(!isDropdownOpenPython);
};

const handleExpandClickPython = () => {
  setIsDropdownOpenPython(true);
};

const handleCollapseClickPython = () => {
  setIsDropdownOpenPython(false);
};

// RabbitMq
useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".dropdown")) {
      setIsDropdownOpenRabbitMq(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

useEffect(() => {
  document.documentElement.style.setProperty(
    "--dropdown-height",
    isDropdownOpenRabbitMq
      ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
      : "0"
  );
}, [isDropdownOpenRabbitMq]);

const handleMenuClickRabbitMq = (content: React.ReactNode) => {
  setActiveContent(content);
  setIsDropdownOpenRabbitMq(false);
};

const handleDropdownClickRabbitMq = () => {
  setIsDropdownOpenRabbitMq(!isDropdownOpenRabbitMq);
};

const handleExpandClickRabbitMq = () => {
  setIsDropdownOpenRabbitMq(true);
};

const handleCollapseClickRabbitMq = () => {
  setIsDropdownOpenRabbitMq(false);
};



  //GWT
  const handleMenuClickGwt = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenGwt(false);
  };

  const handleDropdownClickGwt = () => {
    setIsDropdownOpenGwt(!isDropdownOpenGwt);
  };

  const handleExpandClickGwt = () => {
    setIsDropdownOpenGwt(true);
  };

  const handleCollapseClickGwt = () => {
    setIsDropdownOpenGwt(false);
  };

  // CSS Learning Plan
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown")) {
        setIsDropdownOpenCSSLearningPlan(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dropdown-height",
      isDropdownOpenCSSLearningPlan
        ? `${document.querySelector(".dropdown-content")?.clientHeight}px`
        : "0"
    );
  }, [isDropdownOpenCSSLearningPlan]);

  const handleMenuClickCSSLearningPlan = (content: React.ReactNode) => {
    setActiveContent(content);
    setIsDropdownOpenCSSLearningPlan(false);
  };

  const handleDropdownClickCSSLearningPlan = () => {
    setIsDropdownOpenCSSLearningPlan(!isDropdownOpenCSSLearningPlan);
  };

  const handleExpandClickCSSLearningPlan = () => {
    setIsDropdownOpenCSSLearningPlan(true);
  };

  const handleCollapseClickCSSLearningPlan = () => {
    setIsDropdownOpenCSSLearningPlan(false);
  };

  return (
    <div className="container">
      <div className="left-sidebar">
        {/* <h2>Forms by type</h2> */}
        <ul>
          {/* <li onClick={() => handleMenuClick(<Java />)}>Java</li> */}

          <li
            className={`dropdown ${isDropdownOpenExpressJS ? "expanded" : ""}`}
          >
            <span
              className="dropdown-btn"
              onClick={handleDropdownClickExpressJS}
            >
              Express JS
            </span>
            <div className="icons">
              {isDropdownOpenExpressJS ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickExpressJS}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div
                  className="expand-btn"
                  onClick={handleExpandClickExpressJS}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenExpressJS && (
              <div className="dropdown-content">
                <li onClick={() => handleMenuClickExpressJS(<ExpressJs />)}>
                  Express Js
                </li>
                <li onClick={() => handleMenuClickExpressJS(<Nunjucks />)}>
                  Nunjucks
                </li>
                <li onClick={() => handleMenuClickExpressJS(<Pug />)}>Pug</li>
                <li onClick={() => handleMenuClickExpressJS(<CookieParser />)}>
                  Cookie-Parser
                </li>
                <li onClick={() => handleMenuClickExpressJS(<Morgan />)}>
                  Morgan
                </li>
                <li onClick={() => handleMenuClickExpressJS(<Helmet />)}>
                  Helmet
                </li>
                <li
                  onClick={() => handleMenuClickExpressJS(<ExpressSession />)}
                >
                  Express Session
                </li>
                <li onClick={() => handleMenuClickExpressJS(<Cors />)}>Cors</li>
                <li onClick={() => handleMenuClickExpressJS(<Passport />)}>
                  Passport
                </li>
              </div>
            )}
          </li>

          <li
            className={`dropdown ${
              isDropdownOpenNodeJsLogging ? "expanded" : ""
            }`}
          >
            <span
              className="dropdown-btn"
              onClick={handleDropdownClickNodeJsLogging}
            >
              Node Js Logging
            </span>
            <div className="icons">
              {isDropdownOpenNodeJsLogging ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickNodeJsLogging}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div
                  className="expand-btn"
                  onClick={handleExpandClickNodeJsLogging}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenNodeJsLogging && (
              <div className="dropdown-content">
                <li
                  onClick={() =>
                    handleMenuClickNodeJsLogging(<WinstonFileBasedLogging />)
                  }
                >
                  Winston File Based Logging
                </li>
                <li
                  onClick={() =>
                    handleMenuClickNodeJsLogging(<WinstonConsoleBasedLogging />)
                  }
                >
                  Winston Console Based Logging
                </li>
              </div>
            )}
          </li>

          <li
            className={`dropdown ${
              isDropdownOpenDesignPatterns ? "expanded" : ""
            }`}
          >
            <span
              className="dropdown-btn"
              onClick={handleDropdownClickesignPatterns}
            >
              Design Patterns
            </span>
            <div className="icons">
              {isDropdownOpenDesignPatterns ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickDesignPatterns}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div
                  className="expand-btn"
                  onClick={handleExpandClickesignPatterns}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenDesignPatterns && (
              <div className="dropdown-content">
                <li
                  onClick={() =>
                    handleMenuClickDesignPatterns(<SagaDesignPattern />)
                  }
                >
                  Saga Design Pattern
                </li>
                <li
                  onClick={() => handleMenuClickDesignPatterns(<CQRSPattern />)}
                >
                  CQRS Pattern
                </li>
                <li
                  onClick={() =>
                    handleMenuClickDesignPatterns(<SolidPrinciples />)
                  }
                >
                  SOLID Principles
                </li>
                <li
                  onClick={() =>
                    handleMenuClickDesignPatterns(<APIArchitectureStyles />)
                  }
                >
                  API Architecture Styles
                </li>
                <li
                  onClick={() =>
                    handleMenuClickDesignPatterns(<DistributedSystemPatterns />)
                  }
                >
                  Distributed System Patterns
                </li>
              </div>
            )}
          </li>
          <li className={`dropdown ${isDropdownOpenMongoDB ? "expanded" : ""}`}>
            <span className="dropdown-btn" onClick={handleDropdownClickMongoDB}>
              Mongo DB
            </span>
            <div className="icons">
              {isDropdownOpenMongoDB ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickMongoDB}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div className="expand-btn" onClick={handleExpandClickMongoDB}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenMongoDB && (
              <div className="dropdown-content">
                <li onClick={() => handleMenuClickMongoDB(<SqlVsNoSql />)}>
                  Sql Vs No Sql
                </li>
                <li
                  onClick={() =>
                    handleMenuClickMongoDB(<MongoDbVsMongoExpress />)
                  }
                >
                  Mongo DB Vs Mongo Express
                </li>
                <li onClick={() => handleMenuClickMongoDB(<MongoDBExpress />)}>
                  Mongo DB Express
                </li>
                <li onClick={() => handleMenuClickMongoDB(<MongoDB />)}>
                  MongoDB using Java
                </li>
                <li
                  onClick={() =>
                    handleMenuClickMongoDB(<MongoDBArchitecture />)
                  }
                >
                  Mongo DB realm app
                </li>
              </div>
            )}
          </li>
          <li className={`dropdown ${isDropdownOpenMongoDB ? "expanded" : ""}`}>
            <span className="dropdown-btn" onClick={handleDropdownClickJava}>
              Java
            </span>
            <div className="icons">
              {isDropdownOpenJava ? (
                <div className="collapse-btn" onClick={handleCollapseClickJava}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div className="expand-btn" onClick={handleExpandClickJava}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenJava && (
              <div className="dropdown-content">
                <li onClick={() => handleMenuClickJava(<HttpClient />)}>
                  Http Client
                </li>
              </div>
            )}
          </li>
          <li className={`dropdown ${isDropdownOpenNgrok ? "expanded" : ""}`}>
            <span className="dropdown-btn" onClick={handleDropdownClickNgrok}>
              Ngrok
            </span>
            <div className="icons">
              {isDropdownOpenNgrok ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickNgrok}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div className="expand-btn" onClick={handleExpandClickNgrok}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenNgrok && (
              <div className="dropdown-content">
                <li onClick={() => handleMenuClickNgrok(<Ngrox />)}>
                  Introduction
                </li>
                <li
                  onClick={() =>
                    handleMenuClickNgrok(<ExposingNgrokThroughUI />)
                  }
                >
                  Exposing local nginx through ngrok
                </li>
              </div>
            )}
          </li>
          <li className={`dropdown ${isDropdownOpenCognito ? "expanded" : ""}`}>
            <span className="dropdown-btn" onClick={handleDropdownClickCognito}>
              Cognito
            </span>
            <div className="icons">
              {isDropdownOpenCognito ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickCognito}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div className="expand-btn" onClick={handleExpandClickCognito}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenCognito && (
              <div className="dropdown-content">
                <li
                  onClick={() =>
                    handleMenuClickCognito(<ExposingNgrokThroughGoogleAuth />)
                  }
                >
                  Configure ngrok url in GoogleAuth
                </li>
                <li
                  onClick={() =>
                    handleMenuClickCognito(<ExposingNgrokThroughCognito />)
                  }
                >
                  Configure ngrok callback url in Cognito
                </li>
              </div>
            )}
          </li>
          <li className={`dropdown ${isDropdownOpenPython ? "expanded" : ""}`}>
            <span className="dropdown-btn" onClick={handleDropdownClickPython}>
              Python
            </span>
            <div className="icons">
              {isDropdownOpenPython ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickPython}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div className="expand-btn" onClick={handleExpandClickPython}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenPython && (
              <div className="dropdown-content">
                <li
                  onClick={() =>
                    handleMenuClickPython(<Django />)
                  }
                >
                  Django
                </li>
                <li
                  onClick={() =>
                    handleMenuClickPython(<FlaskApi />)
                  }
                >
                  Flask Api
                </li>
              </div>
            )}
          </li>

          <li className={`dropdown ${isDropdownOpenRabbitMq ? "expanded" : ""}`}>
            <span className="dropdown-btn" onClick={handleDropdownClickRabbitMq}>
            RabbitMQ 
            </span>
            <div className="icons">
              {isDropdownOpenRabbitMq ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickRabbitMq}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div className="expand-btn" onClick={handleExpandClickRabbitMq}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenRabbitMq && (
              <div className="dropdown-content">
                <li
                  onClick={() =>
                    handleMenuClickRabbitMq(<RabbitMq />)
                  }
                >
                  RabbitMQ
                </li>
              </div>
            )}
          </li>

          <li className={`dropdown ${isDropdownOpenGwt ? "expanded" : ""}`}>
            <span className="dropdown-btn" onClick={handleDropdownClickGwt}>
              GWT
            </span>
            <div className="icons">
              {isDropdownOpenGwt ? (
                <div className="collapse-btn" onClick={handleCollapseClickGwt}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div className="expand-btn" onClick={handleExpandClickGwt}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenGwt && (
              <div className="dropdown-content">
                <li onClick={() => handleMenuClickGwt(<GWT />)}>GWT</li>
                <li onClick={() => handleMenuClickGwt(<CreateGWTProject />)}>
                  Create GWT Project
                </li>
                <li
                  onClick={() =>
                    handleMenuClickGwt(<CreateFormUsingPanelGWT />)
                  }
                >
                  Create Form Using Panel
                </li>
                <li onClick={() => handleMenuClickGwt(<EventHandlersGWT />)}>
                  Event Handlers
                </li>
                <li onClick={() => handleMenuClickGwt(<GWTUIBinders />)}>
                  UIBinders
                </li>
                <li onClick={() => handleMenuClickGwt(<GWTRpcCall />)}>
                RPC
                </li>
              </div>
            )}
          </li>
          <li onClick={() => handleMenuClickExpressJS(<Kafka />)}>Kafka</li>
          <li onClick={() => handleMenuClickExpressJS(<CorrelationId />)}>
            CorrelationId
          </li>
          <li onClick={() => handleMenuClickExpressJS(<Etag />)}>ETag</li>
          <li onClick={() => handleMenuClickExpressJS(<SoapApi />)}>SoapApi</li>
          <li onClick={() => handleMenuClickExpressJS(<WebSocket />)}>
            WebSocket
          </li>
          <li onClick={() => handleMenuClickExpressJS(<DynamoDB />)}>
            DynamoDB
          </li>
          <li onClick={() => handleMenuClickExpressJS(<GradleDockerCompose />)}>
            Gradle Docker Compose
          </li>
          <li onClick={() => handleMenuClickExpressJS(<DynamoDBLocal />)}>
            DynamoDB Local
          </li>
          <li onClick={() => handleMenuClickExpressJS(<SQSLocalStack />)}>
            SQS LocalStack
          </li>
          <li onClick={() => handleMenuClickExpressJS(<SNSLocalStack />)}>
            SNS LocalStack
          </li>
          <li onClick={() => handleMenuClickExpressJS(<KeyCloak />)}>
            KeyCloak
          </li>
          <li onClick={() => handleMenuClickExpressJS(<ElasticSearch />)}>
            Elastic Search
          </li>
          <li onClick={() => handleMenuClickExpressJS(<SecretManager />)}>
            Secret Manager
          </li>
          <li onClick={() => handleMenuClickExpressJS(<Redis />)}>Redis</li>
          <li onClick={() => handleMenuClickExpressJS(<GitCommands />)}>
            GitCommands
          </li>
          <li onClick={() => handleMenuClickExpressJS(<Nginx />)}>Nginx</li>
          <li
            onClick={() =>
              handleMenuClickExpressJS(<AccessingECSApisUsingVpcLinks />)
            }
          >
            Accessing ECS Apis Using Vpc Links
          </li>
          {/* <li onClick={() => handleMenuClickExpressJS(<CSSLearningPlan />)}>
            CSS Learning Plan
          </li> */}
          <li
            // onClick={() => handleMenuClickExpressJS(<CSSLearningPlan />)}
            className={`dropdown ${
              isDropdownOpenCSSLearningPlan ? "expanded" : ""
            }`}
          >
            <span
              className="dropdown-btn"
              onClick={handleDropdownClickCSSLearningPlan}
            >
              CSS Learning Plan
            </span>
            <div className="icons">
              {isDropdownOpenCSSLearningPlan ? (
                <div
                  className="collapse-btn"
                  onClick={handleCollapseClickCSSLearningPlan}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                  </svg>
                </div>
              ) : (
                <div
                  className="expand-btn"
                  onClick={handleExpandClickCSSLearningPlan}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                  </svg>
                </div>
              )}
            </div>
            {isDropdownOpenCSSLearningPlan && (
              <div className="dropdown-content">
                <li
                  onClick={() =>
                    handleMenuClickCSSLearningPlan(<CSSLearningPlan />)
                  }
                >
                  Content
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module01 />)}
                >
                  Module-01
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module02 />)}
                >
                  Module-02
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module03 />)}
                >
                  Module-03
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module04 />)}
                >
                  Module-04
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module05 />)}
                >
                  Module-05
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module06 />)}
                >
                  Module-06
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module07 />)}
                >
                  Module-07
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module08 />)}
                >
                  Module-08
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module09 />)}
                >
                  Module-09
                </li>
                <li
                  onClick={() => handleMenuClickCSSLearningPlan(<Module10 />)}
                >
                  Module-10
                </li>
              </div>
            )}
          </li>
        </ul>
      </div>
      <div className="content">{activeContent}</div>
    </div>
  );
};

export default LeftMenu;
