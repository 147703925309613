import React from "react";

const SqlVsNoSql: React.FC = () => {
  const tableBorderStyle: number = 1;

  return (
    <div>
      <h2>SQL Vs No SQL</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>SQL</th>
            <th style={{ textAlign: "left" }}>MongoDB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td>
              SQL(Structured Query Language) is a traditional{" "}
              <span style={{ color: "red" }}>
                Relational database management system(RDBMS)
              </span>
              .
            </td>
            <td>MongoDB is a 
              <span style={{ color: "red" }}> document-oriented NoSQL database
              </span>.</td>
          </tr>
          <tr>
            <td>
              SQL uses a <span style={{ color: "red" }}>fixed schema</span>, where the structure of the tables must be
              defined before data can be inserted.
            </td>
            <td>
              MongoDB uses a <span style={{ color: "red" }}>dynamic schema</span>, where documents can have different
              fields.
            </td>
          </tr>
          <tr>
            <td>SQL uses a <span style={{ color: "red" }}>delclarative</span> query language.</td>
            <td>
              MongoDB uses a more <span style={{ color: "red" }}>expressive</span> query language based on JSON.
            </td>
          </tr>
          <tr>
            <td>
              SQL database follow <span style={{ color: "red" }}>ACID properties</span>(Atomicity, COnsistency,
              Isolation and Durability).
            </td>
            <td>
              NoSQL database follows the <span style={{ color: "red" }}>Brewers CAP theorem</span>(Consistency,
              Availability and Partition tolerance).
            </td>
          </tr>
          <tr>
            <td>In SQL, data is stord in <span style={{ color: "red" }}>tables</span> with <span style={{ color: "red" }}>rows</span> and <span style={{ color: "red" }}>columns</span>.</td>
            <td>
              In MongoDB, data is stored in <span style={{ color: "red" }}>collections</span> of <span style={{ color: "red" }}>JSON</span>-like documents.
            </td>
          </tr>
          <tr>
            <td>SQL supports a <span style={{ color: "red" }}>rich</span> set of <span style={{ color: "red" }}>data types</span>.</td>
            <td>MongoDB has a <span style={{ color: "red" }}>limited</span> set of <span style={{ color: "red" }}>data types</span>.</td>
          </tr>
          <tr>
            <td>SQL is used in more <span style={{ color: "red" }}>traditional business</span> applications.</td>
            <td>
              MongoDB is often used in <span style={{ color: "red" }}>big data</span> and <span style={{ color: "red" }}>real-time web applications</span>.
            </td>
          </tr>
          <tr>
            <td>
              A great choice if you have <span style={{ color: "red" }}>structured data</span> and need a traditional
              relational datatbase.
            </td>
            <td>
              An ideal choice if you have <span style={{ color: "red" }}>unstructured</span> and/or structured <span style={{ color: "red" }}>data</span>
              with the potential for <span style={{ color: "red" }}>rapid growth</span>.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SqlVsNoSql;
