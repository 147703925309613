import React from "react";

const settingsPyFile = `
INSTALLED_APPS = [
    ...
    'myapp',
]

`;

const formssPyFile = `
from django import forms
from django.contrib.auth.forms import AuthenticationForm

class LoginForm(AuthenticationForm):
    username = forms.CharField(max_length=254, widget=forms.TextInput(attrs={'autofocus': True}))
    password = forms.CharField(label='Password', widget=forms.PasswordInput)

`;

const viewssPyFile = `
from django.shortcuts import render, redirect
from django.contrib.auth import login
from django.contrib.auth.forms import AuthenticationForm

def login_view(request):
    if request.method == 'POST':
        form = AuthenticationForm(data=request.POST)
        if form.is_valid():
            user = form.get_user()
            login(request, user)
            return redirect('home')  # Redirect to a 'home' page after login
    else:
        form = AuthenticationForm()
    return render(request, 'login.html', {'form': form})


`;

const loginHtmlFile = `
<!DOCTYPE html>
<html>
<head>
    <title>Login</title>
</head>
<body>
    <h2>Login</h2>
    <form method="post">
        {% csrf_token %}
        {{ form.as_p }}
        <button type="submit">Login</button>
    </form>
</body>
</html>

`;

const appUrlsPyFile = `
from django.urls import path
from .views import login_view

urlpatterns = [
    path('login/', login_view, name='login'),
]

`;

const projectUrlsPyFile = `
from django.contrib import admin
from django.urls import path, include

urlpatterns = [
    path('admin/', admin.site.urls),
    path('', include('myapp.urls')),  # Include URLs from 'myapp'
]

`;

const Django: React.FC = () => {
  return (
    <div>
      <h2>Django</h2>
      <p>
        <strong>What is Django?</strong>
        <br />
        Django is a high-level Python web framework that encourages rapid
        development and clean, pragmatic design. It follows the
        "batteries-included" philosophy, providing a robust set of tools and
        features out of the box to simplify the web development process. Django
        is known for its emphasis on reusability, rapid development, and the
        principle of "don't repeat yourself" (DRY).
      </p>

      <h3>Steps to Create a Django Web Application with a Login Form</h3>

      <h4>1. Set Up Your Development Environment</h4>
      <ol>
        <li>
          <strong>Install Python:</strong> Ensure you have Python
          installed.Python is required for running Django. Ensure you download
          the appropriate version for your operating system. Download it from{" "}
          <a href="https://www.python.org/downloads/">python.org</a>.
        </li>
        <li>
          <strong>Install Django:</strong> Django is a Python package so we use
          pip to install Django. Open your command line and run:
          <div className="listitems">
            <pre>pip install django</pre>
          </div>
        </li>
        <li>
          <strong>Create a Project:</strong> Navigate to the directory where you
          want to create your project and run:
          <div className="listitems">
            <pre>django-admin startproject myproject</pre>
          </div>
          Replace <code>myproject</code> with your desired project name. This
          command creates a directory named <code>myproject</code> with the
          necessary files.
        </li>
        <p>
          This command sets up the basic structure of a Django project,
          including directories and default files.
        </p>
        <li>
          <strong>Navigate to Your Project Directory:</strong>

          <div className="listitems">
            <pre>cd myproject</pre>
          </div>
        </li>
        <p>
          Moving into your project directory is necessary to run further Django
          commands.
        </p>
      </ol>

      <h4>2. Create a Django Application</h4>
      <ol>
        <li>
          <strong>Start an Application:</strong> Run the following command to
          create a new app within your project:
          <div className="listitems">
            <pre>python manage.py startapp myapp</pre>
          </div>
          Replace <code>myapp</code> with your app name. This will create a
          directory named <code>myapp</code> with the basic structure.
        </li>
        <p>
          Applications are modular components of a Django project. Each
          application should handle a specific task or feature of the project.
        </p>
        <li>
          <strong>Add the Application to Your Project:</strong> Open{" "}
          <code>myproject/settings.py</code> and add <code>'myapp'</code> to the{" "}
          <code>INSTALLED_APPS</code> list:
          <div className="listitems">
            <pre>{settingsPyFile}</pre>
          </div>
        </li>
        <p>
          Django needs to know about your application, which is why you add it
          to the INSTALLED_APPS list.
        </p>
      </ol>

      <h4>3. Create the Login Form</h4>
      <ol>
        <li>
          <strong>Create a Form:</strong> In <code>myapp/forms.py</code>, create
          a form for user login:
          <div className="listitems">
            <pre>{formssPyFile}</pre>
          </div>
        </li>
        <p>
          Forms handle user input. In Django, you use forms to manage and
          validate user data.
        </p>
        <li>
          <strong>Create a View:</strong> In <code>myapp/views.py</code>, create
          a view to handle the login:
          <div className="listitems">
            <pre>{viewssPyFile}</pre>
          </div>
        </li>
        <p>
          Views handle the logic of your application. The login view processes
          user input and handles authentication.
        </p>
        <li>
          <strong>Create a Template:</strong> Create a file{" "}
          <code>login.html</code> in <code>myapp/templates/</code>:
          <div className="listitems">
            <pre>{loginHtmlFile}</pre>
          </div>
        </li>
        <p>
          Templates define how your application's data is presented to users.
          This example creates a simple HTML form.
        </p>
        <li>
          <strong>Set Up URLs:</strong> In <code>myapp/urls.py</code>, add a URL
          pattern for the login view:
          <div className="listitems">
            <pre>{appUrlsPyFile}</pre>
          </div>
        </li>
        <p>
          URL configurations map URLs to views. This tells Django which view to
          use for different URLs.
        </p>
        <li>
          <strong>Include App URLs in the Project:</strong> In{" "}
          <code>myproject/urls.py</code>, include the URLs from your app:
          <div className="listitems">
            <pre>{projectUrlsPyFile}</pre>
          </div>
        </li>
        <p>
          This step integrates your application's URL patterns into the
          project's URL configuration.
        </p>
      </ol>

      <h4>4. Run the Application</h4>
      <ol>
        <li>
          <strong>Apply Migrations:</strong> Run the following command to set up
          your database:
          <div className="listitems">
            <pre>python manage.py migrate</pre>
          </div>
        </li>
        <p>
          Migrations ensure that your database schema is updated to match your
          models.
        </p>
        <li>
          <strong>Create a Superuser (Admin):</strong> To manage your site,
          create a superuser:
          <div className="listitems">
            <pre>python manage.py createsuperuser</pre>
          </div>
        </li>
        <p>
          A superuser is needed to access Django's admin interface for managing
          your site's data.
        </p>
        <li>
          <strong>Run the Development Server:</strong> Start the Django
          development server:
          <div className="listitems">
            <pre>python manage.py runserver</pre>
          </div>
          Visit <code>http://127.0.0.1:8000/login/</code> in your web browser to
          see your login form.
        </li>
      </ol>
      <p>
        This starts a local server where you can view and test your application.
      </p>

      <p>
        When ready, you can deploy your application to a production server using
        platforms like Heroku, AWS, or any other web hosting services.
      </p>
    </div>
  );
};

export default Django;
