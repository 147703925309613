import React from "react";

const create_express_app = `
const express = require('express');
const app = express();
`;

const definearoute = `
app.get('/', (req, res) => {
  res.send('Hello, Express!');
});
`;

const start_the_server = `
const port = 3000;
app.listen(port, () => {
  console.log("Server is running on port 3000");
});
`;
const basicrouting = `
app.get("/", (req, res) => {
  res.send("CMS App !");
});

app.get('/about', (req, res) => {
  res.send('About Page');
});
`;

const getmethod = `
app.get("/home", function (req, res) {
  console.log(" Got a GET request for the homepage");
  res.send("Hello Home!");
});


app.get("/*list", function (req, res) {
  console.log("Got a GET request for list");
  res.send("Page listing");
});
`;

const postmethod = `
app.post("/post-data", function (req, res) {
  // Access data from the request body
  const postData = req.body;
  console.log("Received POST data: hey", postData);
  res.json({ success: true, receivedData: postData });
});


app.post("/hello", function (req, res) {
  res.send("You just called the post method at '/hello'!\n");
});
`;

const postmethod2 = `
const bodyParser = require('body-parser');

// Parse JSON bodies for this app
app.use(bodyParser.json());

// Parse URL-encoded bodies for this app
app.use(bodyParser.urlencoded({ extended: true }));

`;

const getpostmethod = `
const express = require('express');
const bodyParser = require('body-parser');
const app = express();
const port = 3000;

// Middleware to parse request body
app.use(bodyParser.json());
app.use(bodyParser.urlencoded({ extended: true }));

// GET request for the home page
app.get('/', (req, res) => {
  res.send('Hello, this is the home page!');
});

// POST request
app.post("/post-data", function (req, res) {
  // Access data from the request body
  const postData = req.body;
  console.log("Received POST data: hey", postData);
  res.json({ success: true, receivedData: postData });
});

// Start the server
app.listen(port, () => {
  console.log('Server is running on port 3000');
});

`;

const middleware = `
app.use("/things", function (req, res, next) {
  console.log("A request for things received at " + Date.now());
  next();
});
`;

// const puginstall = `
// yarn add pug
// `;

// const pugtemplate = `
// app.set('view engine', 'pug');
// app.set('views','./views');
// `;

// const pugcode = `
// doctype html
// html
//    head
//       title = "Hello Pug"
//    body
//       p.greetings#people Hello World!
// `;

// const pugrun = `
// app.get('/first_template', function(req, res){
//   res.render('first_view');
// });
// `;

// const templating = `
// app.get("/first_template", function (req, res) {
//   res.render("first_view", {
//     name: "NipunaSolutions",
//     url: "https://nipunasolutions.co.uk/",
//   });
// });
// `;

// const pugexample = `
// html
//   head
//     title Form Tester
//     style.
//       body {
//         font-family: 'Arial', sans-serif;
//         background-color: rgb(5 84 141);
//         text-align: center;
//       }
//       form {
//         max-width: 400px;
//         margin: 20px auto;
//         padding: 20px;
//         background-color: #fff;
//         border-radius: 8px;
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//         border: 1px solid black
//       }
//       label {
//         display: block;
//         margin-bottom: 8px;
//         text-align: left;
//       }
//       input, select, button {
//         width: 100%;
//         padding: 10px;
//         margin-bottom: 15px;
//         border: 1px solid black;
//         border-radius: 4px;
//         box-sizing: border-box;
//         font-size: 16px;
//       }
//       button {
//         background-color: #4caf50;
//         color: #fff;
//         cursor: pointer;
//         transition: background-color 0.3s;
//       }
//       button:hover {
//         background-color: #45a049;
//       }
//       .reset-button {
//         background-color: #f44336;
//       }
//       .reset-button:hover {
//         background-color: #d32f2f;
//       }
//       .checkbox-group, .radio-group {
//         display: flex;
//         align-items: center;
//       }
//       .checkbox-group label, .radio-group label {
//         margin-right: 25px;
//       }
//   body
//     form(action="/submit-form", method="POST", enctype="multipart/form-data",style="background-color: rgb(225 230 233);")
//       div
//         label(for="firstName") First Name:
//         input(type="text", name="firstName", required="")
//       br
//       div
//         label(for="lastName") Last Name:
//         input(type="text", name="lastName", required="")
//       br
//       div
//         label(for="dob") Date of Birth:
//         input(type="date", name="dob", required="")
//       br
//       div
//         label(for="yearPassed") Year Passed:
//         select(name="yearPassed", required="")
//           option(value="2022") 2022
//           option(value="2021") 2021
//           option(value="2020") 2020
//       br
//       div.checkbox-group
//         label(for="subjects") Subjects:
//         br
//         input(type="checkbox", name="math", value="Math", class="subject")
//         label Math
//         input(type="checkbox", name="science", value="Science", class="subject")
//         label Science
//         input(type="checkbox", name="english", value="English", class="subject")
//         label English
//       br
//       div.radio-group
//         label(for="grades") Grades:
//         br
//         input(type="radio", name="grades", value="A")
//         label A
//         input(type="radio", name="grades", value="B")
//         label B
//         input(type="radio", name="grades", value="C")
//         label C
//       br
//       div
//         label(for="file") Upload CV:
//         input(type="file", name="file", id="fileInput", multiple, accept=".pdf, .doc, .docx, .png")
//       br
//       button(type="submit") Submit
//       button(type="reset", class="reset-button") Reset
// `;

// const pugdeclarartion = `
// app.get("/application-data", upload.array(), (req, res) => {
//   res.render("application");
// });
// `;

const ExpressJs: React.FC = () => {
  return (
    <>
      <h1>Express Js</h1>
      <h3>What is Express?</h3>
      <p>
        Express.js, also known as Express, is a framework designed for building
        web applications using Node.js. It is an open-source framework created
        and actively supported by the Node.js foundation. Express simplifies the
        development of powerful and scalable web applications by offering a
        range of features and tools. These include capabilities for managing
        routes, handling middleware, and processing HTTP requests. In essence,
        Express provides a user-friendly way to develop robust web applications
        by streamlining common tasks in web development.
      </p>
      <h3>Why is Express.js Used?</h3>
      <ul>
        <li>
          <strong>Simplicity:</strong>Express provides a straightforward and
          flexible toolkit for building web applications. This simplicity allows
          developers to quickly start projects and choose additional libraries
          based on their preferences.
        </li>

        <li>
          <strong>Middleware:</strong> The framework employs middleware
          functions to handle various tasks such as request processing,
          authentication, and serving static files. This modular approach
          enhances the organization and maintainability of code.
        </li>

        <li>
          <strong> Routing: </strong> Express simplifies the definition of
          routes for different parts of an application. This makes it easy to
          create well-organized APIs, facilitating code management and
          expansion.
        </li>

        <li>
          <strong> HTTP Utility Methods:</strong> Express provides a set of
          convenient methods for handling HTTP requests and responses. These
          methods streamline the development process by offering useful
          utilities.
        </li>

        <li>
          <strong> Community and Ecosystem: </strong> As one of the most widely
          used Node.js frameworks, Express benefits from a large and active
          community. It boasts an extensive ecosystem of plugins and modules,
          making it effortless to find solutions for common challenges.
        </li>
      </ul>
      <h3>
        How to Set Up Express.js with Yarn:
      </h3>
      <ul>
        <li>
          <strong>Install Node.js:</strong>
          Ensure Node.js is installed on your machine. You can download it from
          nodejs.org.
        </li>
        <li>
          <strong>Create a Project Folder:</strong>
          Create a new folder for your Express project.
        </li>
        <li>
          <strong>Initialize Package.json:</strong>
          In your project folder, run 'yarn init' to create a package.json file.
        </li>
        <li>
          <strong>Install Express with Yarn:</strong>
          Run 'yarn add express' to install the Express package.
        </li>
      </ul>
      <h3>Sample code snippets</h3>
      <h3>1.Create an Express App:</h3>
      <div className="listitems">
        <pre>{create_express_app}</pre>
      </div>
      <h3>2.Start the Server:</h3>
      <div className="listitems">
        <pre>{start_the_server}</pre>
      </div>
      <h3>3.Define a Route:</h3>
      <div className="listitems">
        <pre>{definearoute}</pre>
      </div>
      <h3>4.Basic Routing:</h3>
      <div className="listitems">
        <pre>{basicrouting}</pre>
      </div>
      <h3>5.GET Method:</h3>
      <p>
        The GET method is used to request data from a specified resource. It
        should be used only for the data retrieval and should not have any side
        effects.
      </p>
      <div className="listitems">
        <pre>{getmethod}</pre>
      </div>
      <h3>6.POST Method</h3>
      <p>
        The POST method is used to submit data to be processed to a specified
        resource. POST is commonly used when uploading a file or submitting a
        form.
      </p>
      <div className="listitems">
        <pre>{postmethod}</pre>
      </div>
      <p>
        <strong>Note:</strong>
        When using POST, it's common to send data in the request body,
        especially for form submissions. To handle this in Express, one will
        often need middleware to parse the request body. For example, one might
        use body-parser middleware:
      </p>
      <div className="listitems">
        <pre>{postmethod2}</pre>
      </div>
      <p>
        Ensure to install body-parser using 'yarn add body-parser' or 'npm
        install body-parser'.
      </p>
      <h3>7.MiddleWare</h3>
      <p>
        Middleware functions are functions that have access to the request
        object (req), the response object (res), and the next middleware
        function in the application's request-response cycle. These functions
        are used to modify req and res objects for tasks like parsing request
        bodies, adding response headers, etc.
      </p>
      <p>
        Middleware functions enhance the functionality of your web server by
        allowing you to intervene in the process, inspect and modify requests
        and responses, and perform additional tasks as needed. They provide a
        way to inject custom logic into the standard request-handling pipeline.
      </p>
      <div className="listitems">
        <pre>{middleware}</pre>
      </div>
      <h3>8.Example of Using Both GET and POST :</h3>
      <div className="listitems">
        <pre>{getpostmethod}</pre>
      </div>
      {/* <h3>9.ExpressJS - Templating, PUG</h3>
      <p>
        Pug is a templating engine for Express. Templating engines are used to
        remove the cluttering of our server code with HTML, concatenating
        strings wildly to existing HTML templates. Pug is a very powerful
        templating engine which has a variety of features including filters,
        includes, inheritance, interpolation, etc.{" "}
      </p>
      <p>To use Pug with Express, we need to install it,</p>
      <div className="listitems">
        <pre>{puginstall}</pre>
      </div>
      <p>
        Now that Pug is installed, set it as the templating engine for your app.{" "}
      </p>
      <div className="listitems">
        <pre>{pugtemplate}</pre>
      </div>
      <p>
        Now create a new directory called views. Inside that create a file
        called first_view.pug, and enter your desired data in it.
      </p>
      <div className="listitems">
        <pre>{pugcode}</pre>
      </div>
      <p>To run this page, add the following route :</p>
      <div className="listitems">
        <pre>{pugrun}</pre>
      </div>
      <p>
        Pug converts this very simple looking markup to html. We don’t need to
        keep track of closing our tags, no need to use class and id keywords,
        rather use '.' and '#' to define them.
      </p>
      <h4>Templating (PUG)</h4>
      <div className="listitems">
        <pre>{templating}</pre>
      </div>
      <h3>PUG example</h3>
      <p>
        This is an example code of PUG, which also contains styles and a live
        code demo of an application form.
      </p>
      <div className="listitems">
        <pre>{pugexample}</pre>
      </div>
      <p>using Get method to declare it</p>
      <div className="listitems">
        <pre>{pugdeclarartion}</pre>
      </div> */}
    </>
  );
};

export default ExpressJs;
