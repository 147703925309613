import React from "react";
const FontCodeSnippetRawHTML = `selector {
  font: font-style font-variant font-weight font-size/line-height font-family;
}
`;
const FontExampleCodeSnippetRawHTML = `body {
  font: italic small-caps bold 16px/1.5 "Times New Roman", Times, serif;
}
`;
const FontKeywordExampleCodeSnippetRawHTML = `/* Using Keywords */
div {
  background-color: blue;
}

/* Using Hexadecimal Value */
p {
  color: #00ff00; /* Green */
}

/* Using RGB Value */
span {
  border: 2px solid rgb(255, 0, 0); /* Red border */
}

/* Using RGBA Value */
a {
  background-color: rgba(0, 0, 255, 0.3); /* Semi-transparent blue background */
}

/* Using HSL Value */
h1 {
  color: hsl(120, 100%, 50%); /* Green */
}

/* Using HSLA Value */
h2 {
  background-color: hsla(240, 100%, 50%, 0.5); /* Semi-transparent blue background */
}
`;
const SelectorImageCodeSnippetRawHTML = `/* Universal Selector */
* {
  margin: 0;
  padding: 0;
}

/* Type Selector */
h1 {
  color: blue;
}

/* Class Selector */
.button {
  background-color: #3498db;
}

/* ID Selector */
#navbar {
  font-size: 18px;
}

/* Attribute Selector */
input[type="checkbox"] {
  margin-right: 5px;
}

/* Descendant Selector */
article p {
  font-size: 16px;
}

/* Child Selector */
ul > li {
  list-style-type: disc;
}

/* Adjacent Sibling Selector */
h2 + p {
  margin-top: 10px;
}

/* General Sibling Selector */
h3 ~ p {
  color: #e74c3c;
}

/* Pseudo-class */
a:hover {
  color: #2ecc71;
}

/* Pseudo-element */
p::first-line {
  font-weight: bold;
}

`;
const Module03: React.FC = () => {
  return (
    <div>
      <h2>CSS Fonts:</h2>
      <p>
        In CSS, the font property is used to specify the font style for text. It
        is a shorthand property that allows you to set various font-related
        properties in a single declaration. Here's the basic syntax:
      </p>
      <div className="listitems">
        <pre>{FontCodeSnippetRawHTML}</pre>
      </div>
      <h3>Properties:</h3>

      <p>
        <b>font-style:</b> It specifies the font style for the text. It can take
        values like normal, italic, or oblique.
        <br />
        <b>font-variant:</b> It specifies whether the text should be displayed
        in normal or small-caps variant. It can take values like normal or
        small-caps.
        <br />
        <b>font-weight: </b>It specifies the thickness of the characters in the
        text. It can take values like normal, bold, or a numeric value (e.g.,
        400, 700).
        <br />
        <b>font-size:</b> It specifies the size of the font. It can be an
        absolute size (e.g., 12px), a relative size (e.g., 1.2em), or one of the
        predefined values (small, medium, large).
        <br />
        <b>line-height:</b> It specifies the height of a line of text. It can be
        a number, a percentage, or a length value.
        <br />
        <b>font-family:</b> It specifies the font family for the text. You can
        provide multiple font family names as a fallback, separated by commas.
        The browser will use the first available font in the list.
        <br />
      </p>
      <div className="listitems">
        <pre>{FontExampleCodeSnippetRawHTML}</pre>
      </div>
      <p>
        In this example, the text in the body element will have an italic style,
        small-caps variant, bold weight, a font size of 16 pixels with a line
        height of 1.5, and will use the font family "Times New Roman" if
        available. If not, it will fall back to the generic serif font.
      </p>
      <h2> CSS Colors:</h2>
      <p>
        In CSS, colors can be specified using various formats, including
        keywords, hexadecimal values, RGB values, RGBA values, HSL values, and
        HSLA values. Here's an overview of each:
      </p>
      <h3>Keywords: </h3>
      <p>
        CSS provides a set of color keywords like red, blue, green, etc.
        <br />
        <b>Example: </b>color: red; <br />
        <br />
        <b>Hexadecimal Values:</b> <br />
        Hex codes represent colors using a combination of six hexadecimal
        digits. <br />
        Example: color: #ff0000; (red) <br />
        <br />
        <b>RGB Values:</b> RGB stands for Red, Green, Blue. Each component is
        specified as a number between 0 and 255. <br />
        Example: color: rgb(255, 0, 0); (red)
        <br />
        <br />
        <b>RGBA Values:</b>Similar to RGB, but with an additional alpha channel
        for transparency. <br />
        Example: color: rgba(255, 0, 0, 0.5); (semi-transparent red)
        <br />
        <br /> <b>HSL Values:</b> HSL stands for Hue, Saturation, Lightness. Hue
        is represented as an angle in degrees, and saturation and lightness are
        percentages. <br />
        Example: color: hsl(0, 100%, 50%); (red) <br />
        <br />
        <b>HSLA Values:</b> Similar to HSL, but with an additional alpha channel
        for transparency.
        <br />
        <h4>Example:</h4> color: hsla(0, 100%, 50%, 0.5); (semi-transparent red)
      </p>
      <div className="listitems">
        <pre>{FontKeywordExampleCodeSnippetRawHTML}</pre>
      </div>
      <h2> CSS Selector :</h2>
      <p>
        In CSS, selectors are used to target and style HTML elements. Selectors
        define the criteria for selecting elements, and the associated style
        rules specify how those elements should be presented. Here are some
        common CSS selectors:
      </p>
      <h4>Universal Selector (*):</h4>

      <p>
        Selects all elements on the page.
        <h5>Example:</h5> * &#123; color: blue; &#125;
        <h4>Type Selector: </h4>
        Selects all elements of a specified type.
        <h5>Example:</h5> p &#123;font-size: 16px; &#125;
        <h4>Class Selector (.):</h4> Selects all elements with a specific class
        attribute.
        <h5>Example:</h5> .highlight &#123; background-color: yellow; &#125;
        <h4>ID Selector (#):</h4>
        Selects a single element with a specific ID attribute.
        <h5>Example:</h5> #header &#123; font-size: 24px; &#125;
        <h4> Attribute Selector ([]): </h4>
        Selects elements based on the presence or value of their attributes.
        <h5>Example:</h5> input[type="text"] &#123; border: 1px solid
        black;&#125;
        <h4> Descendant Selector (Whitespace):</h4>
        Selects an element that is a descendant of another specified element.
        <h5>Example:</h5> div p &#123; color: red;&#125;
        <h4> Child Selector (&gt;): </h4>
        Selects an element that is a direct child of another specified element.
        <h5>Example:</h5> ul &gt; li &#123; list-style-type: square; &#125;
        <h4>Adjacent Sibling Selector (+):</h4>
        Selects an element that is the adjacent sibling of another specified
        element.
        <h5>Example:</h5> h2 + p &#123; font-style: italic; &#125;
        <h4>General Sibling Selector (~):</h4>
        Selects elements that are siblings of another specified element.
        <h5>Example:</h5> h2 ~ p &#123; color: green; &#125;
        <h4> Pseudo-classes (:): </h4>
        Selects elements based on their state or position.
        <h5>Example:</h5> a:hover &#123; text-decoration: underline; &#125;{" "}
        <h4> Pseudo-elements (::): </h4>
        Selects parts of an element or a specific position within an element.{" "}
        <h5>Example:</h5> p::first-line &#123; font-weight: bold; &#125;
      </p>
      <h3>Example: </h3>
      <div className="listitems">
        <pre>{SelectorImageCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module03;
