import React from "react";

const SelectorCodeSnippetRawHTML = `body {
  /* Styles applied to the body element */
}

h1 {
  /* Styles applied to h1 elements */
}


}
`;
const DeclarationCodeSnippetRawHTML = `/* CSS Rule with Selector and Declaration Block */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
}

/* Another CSS Rule */
h1 {
  color: #333;
}

/* Multiple Property-Value Pairs */
p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Applying Styles to a Class */
.my-class {
  color: red;
  text-decoration: underline;
}

/* CSS Comment */
/* This is a comment in CSS */


`;

const PropertynCodeSnippetRawHTML = `/* CSS Rule with Selector and Declaration Block */
/* CSS Rule with Selector and Declaration Block */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
}

/* Another CSS Rule */
h1 {
  color: #333;
}

/* Multiple Property-Value Pairs */
p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Applying Styles to a Class */
.my-class {
  color: red;
  text-decoration: underline;
}

/* CSS Comment */
/* This is a comment in CSS */


`;

const Method1CodeSnippetRawHTML = `/* styles.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
}

h1 {
  color: #333;
}

/* Additional styles */
Link the CSS file in the HTML file:
<!-- index.html -->
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>My Webpage</title>

  <!-- Link to external CSS file -->
  <link rel="stylesheet" href="styles.css">
</head>
<body>
  <h1>Hello, World!</h1>
  <p>This is a sample webpage.</p>
</body>
</html>
`;
const Method2CodeSnippetRawHTML = `<!-- index.html -->
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>My Webpage</title>

  <!-- Internal CSS using <style> tag -->
  <style>
    body {
      font-family: 'Arial', sans-serif;
      background-color: #f0f0f0;
    }

    h1 {
      color: #333;
    }

    /* Additional styles */
  </style>
</head>
<body>
  <h1>Hello, World!</h1>
  <p>This is a sample webpage.</p>
</body>
</html>


`;
const Module01: React.FC = () => {
  return (
    <div>
      <h1>About CSS:</h1>
      <ul>
        <li>
          CSS (Cascading Style Sheets) is a styling language used to describe
          the presentation of a document written in HTML or XML, including
          aspects such as layout, colors, and fonts.
        </li>
        <li>
          CSS is case-insensitive. Each CSS rule should end with a semicolon
          (;).
        </li>
      </ul>
      <h2>Syntax:</h2>
      <p>
        CSS syntax consists of rules that define how HTML elements should be
        displayed on a webpage.
      </p>
      <p>
        <b> CSS Rule: </b>A CSS rule consists of a selector and a declaration
        block. <br />
      </p>
      <p>
        <b> Selector:: </b>A selector is used to target HTML elements for
        styling.
      </p>
      <h3>Example:</h3>
      <div className="listitems">
        <pre>{SelectorCodeSnippetRawHTML}</pre>
      </div>
      <h2>Declaration Block:</h2>
      <p>
        The declaration block is enclosed in curly braces {} and contains one or
        more property-value pairs. Each property-value pair is separated by a
        semicolon ;.
      </p>
      <p>
        <b> Property: </b>Specifies the style attribute you want to change.
      </p>
      <p>
        <b> Value: </b>Specifies the value of the property.
      </p>
      <h3>Example:</h3>
      <div className="listitems">
        <pre>{DeclarationCodeSnippetRawHTML}</pre>
      </div>
      <h2>Importing CSS to HTML:</h2>
      <p>
        To import CSS into an HTML file, you can use the element or include the
        CSS directly within a tag in the HTML file.
        <br />
      </p>
      <h3>Method 1: External CSS </h3>
      <p>Create a separate CSS file (e.g., styles.css):</p>
      <div className="listitems">
        <pre>{Method1CodeSnippetRawHTML}</pre>
      </div>
      <h3>Method 2: Inline CSS</h3>
      <p>Include the CSS directly within a tag in the HTML file:</p>
      <div className="listitems">
        <pre>{Method2CodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module01;
