import React from "react";

const appPyFile = `
from flask import Flask, request, jsonify

app = Flask(__name__)
app.secret_key = 'supersecretkey'  # Replace with a secure key

users = {
    "user": "password"
}

@app.route('/login', methods=['POST'])
def login():
    data = request.get_json()
    username = data.get('username')
    password = data.get('password')

    if users.get(username) == password:
        response = {
            "status": "success",
            "message": f"Welcome, {username}!"
        }
    else:
        response = {
            "status": "error",
            "message": "Invalid credentials, please try again."
        }

    return jsonify(response)

if __name__ == '__main__':
    app.run(debug=True)
`;

const json = `
 "username": "user",
  "password": "password"
`;

const FlaskApi: React.FC = () => {
  return (
    <div>
      <h2>Flask API Documentation</h2>
      <h3>What is Flask API?</h3>
      <p>
        Flask is a lightweight and flexible web framework for Python. It allows
        you to build web applications and RESTful APIs with minimal overhead.
        Flask provides tools and libraries to handle HTTP requests, routing, and
        responses, making it an excellent choice for creating APIs.
      </p>
      <h3>Steps to Create a Flask Project with a Login Endpoint</h3>
      <p>
        <strong>Install Python:</strong> Download and install Python from
        python.org. Ensure Python is added to your system’s PATH.
      </p>
      <ol>
        <li>
          <strong>Set Up Your Environment</strong>
          <ul>
            <li>Create and activate a virtual environment:</li>
            <ol>
              <li>
                <strong>Create a Project Directory:</strong>

                <div className="listitems">
                  <pre>
                    <code>mkdir flask_login_project</code>
                  </pre>
                </div>
                <p>
                  This command creates a new directory named{" "}
                  <code>flask_login_project</code>. This directory will contain
                  all your project files, helping to keep them organized and
                  separate from other files on your system.
                </p>
              </li>
              <li>
                <strong>Navigate to the Project Directory:</strong>
                <div className="listitems">
                  <pre>
                    <code>cd flask_login_project</code>
                  </pre>
                </div>
                <p>
                  The <code>cd</code> command stands for "change directory."
                  This command navigates into the{" "}
                  <code>flask_login_project</code> directory, where you will set
                  up your virtual environment and other project files.
                </p>
              </li>
              <li>
                <strong>Set Up and Activate a Virtual Environment:</strong>
                <ul>
                  <li>
                    <strong>Create the Virtual Environment:</strong>
                    <div className="listitems">
                      <pre>
                        <code>python -m venv venv</code>
                      </pre>
                    </div>
                    <p>
                      This command creates a virtual environment named{" "}
                      <code>venv</code> within your project directory. A virtual
                      environment isolates your project dependencies from your
                      global Python installation.
                    </p>
                  </li>
                  <li>
                    <strong>Activate the Virtual Environment:</strong>
                    <div className="listitems">
                      <pre>
                        <code>
                          # macOS/Linux source venv/bin/activate <br></br>#
                          Windows venv\Scripts\activate
                        </code>
                      </pre>
                    </div>
                    <p>
                      Activating the virtual environment adjusts your shell’s
                      path so that Python packages are installed in the virtual
                      environment. The command varies slightly between operating
                      systems: <code>source venv/bin/activate</code> for
                      macOS/Linux and <code>venv\Scripts\activate</code> for
                      Windows.
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
            <li>Install Flask:</li>
            <div className="listitems">
              <pre>
                <code>pip install Flask</code>
              </pre>
            </div>
            <p>Installs the Flask framework within your virtual environment.</p>
          </ul>
        </li>
        <li>
          <strong>Create Your Flask Project</strong>
          <ul>
            <li>Create the file `app.py`:</li>
            <div className="listitems">
              <pre>
                <code>touch app.py</code>
              </pre>
            </div>
            <p>
              Creates an empty file named app.py where you will write your Flask
              application code.
            </p>
            <li>Add Flask code to `app.py`:</li>
            <div className="listitems">
              <pre>{appPyFile}</pre>
            </div>
          </ul>
          <p>
            The /login endpoint in app.py is a POST route that accepts JSON data
            for username and password, validates credentials, and returns a JSON
            response indicating success or failure.
          </p>
        </li>
        <li>
          <strong>Run Your Flask App</strong>
          <ul>
            <li>Start the Flask server:</li>
            <div className="listitems">
              <pre>
                <code>python app.py</code>
              </pre>
            </div>
            <li>
              Access the app at <code>http://127.0.0.1:5000/</code>.
            </li>
          </ul>
        </li>
      </ol>
      <h3>How to Test the Flask API with Postman</h3>
      <ol>
        <li>Open Postman.</li>
        <li>Create a new request.</li>
        <li>
          Set up the request with the POST method and URL{" "}
          <code>http://127.0.0.1:5000/login</code>.
        </li>
        <li>Configure the request body with JSON data:</li>
        <div className="listitems">
          <pre>{json}</pre>
        </div>
        <li>Send the request and view the response.</li>
        <p>
          Receives JSON data containing username and password, checks
          credentials, and returns a JSON response indicating success or
          failure.
        </p>
      </ol>
    </div>
  );
};

export default FlaskApi;
