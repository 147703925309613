import React from "react";
const UnitsCodeSnippetRawHTML = `/* Absolute Units */
font-size: 16px;
width: 2in;
margin-top: 10mm;

/* Relative Units */
width: 50%;
font-size: 2em;
height: 80vh;
margin-bottom: 1rem;
`;
const StaticCodeSnippetRawHTML = `.static-example {
  position: static;
}
`;
const RelativeCodeSnippetRawHTML = `.static-example {
  position: static;
}
`;
const AbsoluteCodeSnippetRawHTML = `.absolute-example {
  position: absolute;
  top: 30px;
  right: 10px;
}
`;
const FixedCodeSnippetRawHTML = `.fixed-example {
  position: fixed;
  bottom: 0;
  right: 0;
}
`;
const StickyCodeSnippetRawHTML = `.sticky-example {
  position: sticky;
  top: 20px;
}
`;
const Module04: React.FC = () => {
  return (
    <div>
      <h2> CSS Units:</h2>
      <p>
        CSS Units are used to specify the dimensions of various properties like
        length, width, margin, padding, font size, and more. There are several
        types of units available in CSS, and they can be broadly categorized
        into two types:
        <ul>
          <li>absolute units</li>
          <li>relative units.</li>
        </ul>
      </p>
      <h3>Absolute Units: </h3>
      <p>
        <b>Pixels (px):</b>
        Represents a single dot on a computer screen.
      </p>
      Example: font-size: 16px;
      <p> <b>Points (pt): </b>
      Commonly used in the print media, where 1 point is equal to 1/72 of an
      inch.</p>
       Example:font-size: 12pt;
      <p>
        <b>Points (pt):</b>
        Commonly used in the print media, where 1 point is equal to 1/72 of an
      inch.
      </p>Example:font-size: 12pt;
      <p><b>Inches (in):</b>
      Represents physical inches on a printed page or screen.</p>
       Example:width: 2in;
      <p><b>Centimeters (cm):</b>
      Represents physical centimeters on a printed page or screen.</p>
    Example: height: 3cm;
     <p> <b>Millimeters (mm): </b>
      Represents physical millimeters on a printed page or screen.</p>
       Example: margin: 5mm;
    
      <h3>Relative Units:</h3>
      <p>
        <b> Percentage (%) &nbsp; :</b> &nbsp; Represents a percentage of the
        parent element's size.
      </p>
      Example: width: 50%;
      <p>
        <b>Viewport Width (vw):</b>
        Represents a percentage of the viewport's width.
      </p>
      Example:font-size: 5vw;
      <p>
        <b> Viewport Height (vh):</b>
        Represents a percentage of the viewport's height.
      </p>
      Example:height: 80vh;
      <p>
        <b>Viewport Minimum (vmin):</b>
        Represents the smaller of vw or vh.
      </p>
      Example: min-height: 20vmin;
      <p>
        <b>Viewport Maximum (vmax):</b>
        Represents the larger of vw or vh.
      </p>
      Example: max-width: 70vmax;
      <p>
        <b>Em (em):</b> Represents the font size of the element.
      </p>
      Example: margin: 2em;
      <p>
        <b>Root Em (rem):</b> Represents the font size of the root element
        (&lt;html&gt;).
      </p>
      Example:font-size: 1.5rem;
      <p>
        <b>Ch (Character Width): </b>
        Represents the width of the "0" (zero) character in the element's font.{" "}
      </p>
      Example: width: 20ch;
      <p>
        <b> Ex (X-Height):</b> Represents the height of the "x" character in the
        element's font.
      </p>
      Example: line-height: 2ex;
      <h3>Examples:</h3>
      <div className="listitems">
        <pre>{UnitsCodeSnippetRawHTML}</pre>
      </div>
      <h2> CSS Positions:</h2>
      <p>
        In CSS, the position property is used to specify the positioning method
        for an element. The position property can take several values, each
        determining how the element is positioned in the document flow. Here are
        the main values for the position property: <br />
      </p>
      <p>
        <b>Static (position: static;):</b> <br /> <ul><li>Default value.</li> <li>Elements are
        positioned according to the normal flow of the document.</li> <li>The top, right,
        bottom, and left properties have no effect.</li></ul>
      </p>
      <div className="listitems">
        <pre>{StaticCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Relative (position: relative;):</b>  <ul><li>Element is positioned relative to
        its normal position in the document flow. </li><li>Using top, right, bottom, and
        left properties will move the element relative to its normal position.</li></ul>
      </p>
      <div className="listitems">
        <pre>{RelativeCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Absolute (position: absolute;):</b> <br />
       <ul> <li>Element is removed from the normal document flow.</li> <li>Positioned
        relative to its nearest positioned ancestor (if any); otherwise, it is
        positioned relative to the initial containing block.</li>
        <li>If there is no positioned ancestor, it is positioned relative to the
        document bod</li></ul>
      </p>
      <div className="listitems">
        <pre>{AbsoluteCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Fixed (position: fixed;):</b> 
        <ul><li>Element is removed from the normal document flow.</li>
       
        <li>Positioned relative to the browser window.</li>
        
        <li>It stays fixed at its specified position even when the page is scrolled.
        css</li></ul>
      </p>
      <div className="listitems">
        <pre>{FixedCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Sticky (position: sticky;):</b><br />
     <ul>   <li>Introduced to create a hybrid of relative and fixed positioning.</li> <br />
       <li> The element is treated as relative positioned until it crosses a
        specified point during scrolling, then it is treated as fixed
        positioned.</li></ul>
      </p>
      <div className="listitems">
        <pre>{StickyCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module04;
