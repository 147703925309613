import React from "react";

const dependenciesSnippet = `
implementation 'software.amazon.awssdk:dynamodb:2.17.98'
implementation 'com.amazonaws:aws-java-sdk-dynamodb:1.12.123'
implementation 'software.amazon.awssdk:dynamodb-enhanced:2.17.0'
`;

const awsConfigurationSnippet = `
aws:
  accessKeyId: YOUR_ACCESS_KEY
  secretAccessKey: YOUR_SECRET_ACCESS_KEY

dynamodb:
  tableName: users
`;

const dynamoDbClientSnippet = `
package com.nipuna.dynamoDB.model;

import com.amazonaws.auth.AWSStaticCredentialsProvider;
import com.amazonaws.auth.BasicAWSCredentials;
import com.amazonaws.services.dynamodbv2.AmazonDynamoDB;
import com.amazonaws.services.dynamodbv2.AmazonDynamoDBClientBuilder;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBMapper;
import org.springframework.beans.factory.annotation.Value;
import org.springframework.context.annotation.Bean;
import org.springframework.context.annotation.Configuration;

@Configuration
public class DynamoDBConfig {
    @Value("dollarSymbol{aws.accessKeyId}")
    private String amazonAWSAccessKey;

    @Value("dollarSymbol{aws.secretAccessKey}")
    private String amazonAWSSecretKey;

    @Bean
    public AmazonDynamoDB amazonDynamoDB() {
        return AmazonDynamoDBClientBuilder.standard()
                .withCredentials(new AWSStaticCredentialsProvider(new BasicAWSCredentials(amazonAWSAccessKey, amazonAWSSecretKey)))
                .withRegion("eu-west-2")
                .build();
    }
    @Bean
    public DynamoDBMapper dynamoDBMapper() {
        return new DynamoDBMapper(amazonDynamoDB());
    }
}

`;

const usersRequestSnippet = `
package com.nipuna.dynamoDB.model;

import lombok.Builder;
import lombok.Data;
import lombok.Getter;
import lombok.Setter;
import lombok.extern.jackson.Jacksonized;

@Data
@Getter
@Setter
@Builder(toBuilder = true)
@Jacksonized
public class CreateUserRequest {
    private String userId;
    private String userName;
    private String email;
    private String age;
    private String creationDate;
    private String fullName;
    private String isActive;
    private String lastLoginDate;
    private String phoneNumber;
    private RolesEntity roles;

}
`;

const usersEntitySnippet = `
package com.nipuna.dynamoDB.model;

import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBAttribute;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBHashKey;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBRangeKey;
import lombok.Data;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBTable;

@Data
@DynamoDBTable(tableName = "users")
public class UsersEntity {

    @DynamoDBHashKey
    private String userId;

    @DynamoDBRangeKey
    private String userName;

    @DynamoDBAttribute(attributeName = "email")
    private String email;
    @DynamoDBAttribute(attributeName = "age")
    private String age;
    @DynamoDBAttribute(attributeName = "creationDate")
    private String creationDate;
    @DynamoDBAttribute(attributeName = "fullName")
    private String fullName;
    @DynamoDBAttribute(attributeName = "isActive")
    private String isActive;
    @DynamoDBAttribute(attributeName = "lastLoginDate")
    private String lastLoginDate;
    @DynamoDBAttribute(attributeName = "phoneNumber")
    private String phoneNumber;
    @DynamoDBAttribute(attributeName = "roles")
    private RolesEntity roles;


    public void setRoles(RolesEntity roles) {
        this.roles = roles;
    }
}

`;

const rolesEntitySnippet = `
package com.nipuna.dynamoDB.model;

import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBDocument;
import lombok.Data;
import software.amazon.awssdk.enhanced.dynamodb.mapper.annotations.DynamoDbBean;

@Data
@DynamoDbBean
@DynamoDBDocument
public class RolesEntity {

    private String roleName;
    private String roleDescription;
}

`;

const dynamoDbControllerSnippet = `
package com.nipuna.dynamoDB.controller;

import com.nipuna.dynamoDB.model.CreateUserRequest;
import com.nipuna.dynamoDB.model.UsersEntity;
import com.nipuna.dynamoDB.service.DynamoDBService;
import lombok.AllArgsConstructor;
import org.springframework.http.HttpStatus;
import org.springframework.http.ResponseEntity;
import org.springframework.web.bind.annotation.*;

import static org.springframework.http.MediaType.APPLICATION_JSON_VALUE;

@RestController
@AllArgsConstructor
public class DynamoDBController {

    private final DynamoDBService dynamoDBService;

    @GetMapping("/{userId}/{userName}")
    public ResponseEntity<Object> getUserById(@PathVariable String userId, @PathVariable String userName) {
        UsersEntity userEntity = dynamoDBService.getUserByIdAndName(userId, userName);
        if (userEntity != null) {
            return ResponseEntity.ok().body(userEntity);
        } else {
            return ResponseEntity.status(HttpStatus.NOT_FOUND).body("User not found");
        }
    }

    @PostMapping(value = "/insert/users", consumes = APPLICATION_JSON_VALUE)
    public ResponseEntity<Object> createUser(@RequestBody CreateUserRequest request) {
        try {
            dynamoDBService.createUser(request);
            return ResponseEntity.status(HttpStatus.CREATED).body("User created successfully");
        } catch (Exception e) {
            return ResponseEntity.status(HttpStatus.INTERNAL_SERVER_ERROR).body("Failed to create user");
        }
    }
}

`;

const dynamoDbServiceSnippet = `
package com.nipuna.dynamoDB.service;

import com.amazonaws.services.dynamodbv2.AmazonDynamoDB;
import com.amazonaws.services.dynamodbv2.AmazonDynamoDBClientBuilder;
import com.amazonaws.services.dynamodbv2.datamodeling.DynamoDBMapper;
import com.amazonaws.services.dynamodbv2.document.DynamoDB;
import com.amazonaws.services.dynamodbv2.document.Table;
import com.nipuna.dynamoDB.model.CreateUserRequest;
import com.nipuna.dynamoDB.model.RolesEntity;
import com.nipuna.dynamoDB.model.UsersEntity;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.beans.factory.annotation.Value;
import org.springframework.stereotype.Service;

@Service
public class DynamoDBService {

    private final AmazonDynamoDB amazonDynamoDB;
    private final DynamoDB dynamoDB;
    private final Table table;

    private DynamoDBMapper ddbMapper =
            new DynamoDBMapper((AmazonDynamoDB) AmazonDynamoDBClientBuilder.standard().build());

    @Autowired
    public DynamoDBService(AmazonDynamoDB amazonDynamoDB, @Value("dollarSymbol{dynamodb.tableName}") String tableName) {
        this.amazonDynamoDB = amazonDynamoDB;
        this.dynamoDB = new DynamoDB(amazonDynamoDB);
        this.table = dynamoDB.getTable(tableName);
    }

    public UsersEntity getUserByIdAndName(String userId, String userName) {
        return ddbMapper.load(UsersEntity.class, userId, userName);
    }

    public void createUser(CreateUserRequest request) {
        UsersEntity newUser = new UsersEntity();
        newUser.setUserId(request.getUserId());
        newUser.setUserName(request.getUserName());
        newUser.setEmail(request.getEmail());
        newUser.setAge(request.getAge());
        newUser.setCreationDate(request.getCreationDate());
        newUser.setFullName(request.getFullName());
        newUser.setIsActive(request.getIsActive());
        newUser.setLastLoginDate(request.getLastLoginDate());
        newUser.setPhoneNumber(request.getPhoneNumber());

        RolesEntity roles = new RolesEntity();
        roles.setRoleName(request.getRoles().getRoleName());
        roles.setRoleDescription(request.getRoles().getRoleDescription());
        newUser.setRoles(roles);

        ddbMapper.save(newUser);
    }

}

`;

const DynamoDB: React.FC = () => {
  return (
    <div>
      <h2>Dynamo DB</h2>
      <p>
        DynamoDB is a fully managed NoSQL database service provided by Amazon
        Web Services (AWS). It's designed to deliver high performance and
        scalability while ensuring low-latency responses to queries.
      </p>
      <h3>Steps for Creating the users Table in DynamoDB:</h3>
      <ol>
        <li>Sign in to the AWS Management Console</li>
        <li>
          Open the DynamoDB Console
          <ul>
            <li>
              Once logged in, search for "DynamoDB" in the AWS Management
              Console search bar or find it under the "Databases" category.
              Click on "DynamoDB" to open the DynamoDB Console.
            </li>
          </ul>
        </li>
        <li>
          Create a Table
          <ul>
            <li>
              In the DynamoDB Console, click on the "Create table" button.
            </li>
            <li>
              Enter a name for your table in the "Table name" field, e.g.,
              "users".
            </li>
            <li>
              Specify the primary key for your table:
              <ul>
                <li>
                  For the partition key, enter "userId" as the attribute name
                  and select "String" as the data type.
                </li>
                <li>
                  Optionally, you can specify a sort key (range key). If you
                  want to use "userName" as the sort key, select "String" as the
                  data type.
                </li>
              </ul>
            </li>
            <li>
              Under "Provisioned capacity", choose either "On-demand capacity"
              or "Provisioned capacity" based on your application's needs.
              <ul>
                <li>
                  On-demand capacity automatically scales your table's capacity
                  based on traffic.
                </li>
                <li>
                  Provisioned capacity allows you to specify the read and write
                  capacity units (RCUs and WCUs) for your table.
                </li>
              </ul>
            </li>
            <li>Click on the "Create" button to create the table.</li>
          </ul>
        </li>
        <li>
          Define Additional Attributes
          <ul>
            <li>Define the additional attributes for your users table.</li>
            <li>
              <strong>email</strong>: String
            </li>
            <li>
              <strong>age</strong>: String 
            </li>
            <li>
              <strong>creationDate</strong>: String 
            </li>
            <li>
              <strong>fullName</strong>: String
            </li>
            <li>
              <strong>isActive</strong>: String
            </li>
            <li>
              <strong>lastLoginDate</strong>: String 
            </li>
            <li>
              <strong>phoneNumber</strong>: String
            </li>
            <li>
              <strong>roles</strong>: Map with nested attributes:
              <ul>
                <li>
                  <strong>roleName</strong>: String
                </li>
                <li>
                  <strong>roleDescription</strong>: String
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Access Control (Optional)
          <ul>
            <li>
              Configure IAM policies to control access to your DynamoDB table.
              You can define fine-grained access control using IAM roles and
              policies to restrict access to specific users or applications.
            </li>
          </ul>
        </li>
        <li>Review and Create</li>
        <li>
          Access the Table
          <ul>
            <li>
              Once the table is created, you can access it programmatically
              using the AWS SDKs or through the AWS Management Console. You can
              perform CRUD operations (Create, Read, Update, Delete) on the
              users table to manage user data as needed.
            </li>
          </ul>
        </li>
      </ol>
      <h3>
        Accessing and managing DynamoDB table from Spring java application:
      </h3>
      <ol>
        <li>
          <strong>Setup AWS SDK Dependencies:</strong> To integrate with the AWS
          SDK in your Java project, you need to add dependencies for the AWS SDK
          for Java and DynamoDB. These dependencies provide the necessary
          libraries and classes to interact with AWS services. In your project's
          build.gradle file, include the following dependencies:
          <div className="listitems">
            <pre>{dependenciesSnippet}</pre>
          </div>
        </li>
        <li>
          <strong>
            Configuring AWS Credentials and DynamoDB Table Name in
            application.yml:
          </strong>{" "}
          Here's how you can specify AWS credentials and DynamoDB table name in
          your application.yml file:
          <div className="listitems">
            <pre>{awsConfigurationSnippet}</pre>
          </div>
          <p>
            This configuration will provide your AWS access key ID and secret
            access key, which are required for authenticating with AWS services
            like DynamoDB. Additionally, it specifies the name of the DynamoDB
            table you want to interact with, which in this case is named
            "users".
          </p>
          <p>
            Make sure to replace "YOUR_ACCESS_KEY" and "YOUR_SECRET_ACCESS_KEY"
            with your actual AWS credentials. These credentials should have
            appropriate permissions to access DynamoDB resources. Additionally,
            ensure that the DynamoDB table name matches the one you've created
            in your AWS account.
          </p>
        </li>
        <li>
          <strong>Create DynamoDB Client:</strong> Instantiate an instance of
          the AmazonDynamoDB class, which represents the client for interacting
          with DynamoDB. AWS credentials(from application.yml) and region configuration
          to initialize the client as below:
          <div className="listitems">
            <pre>{dynamoDbClientSnippet}</pre>
          </div>
          <p>
            <strong>Note:</strong> dollarSymbol text in above code should be
            replaced with dollar symbol
          </p>
        </li>
        <li>
          <strong>Add Entity Class for Users Table ans roles:</strong> Create Java classes
          representing the structure of the users table and roles map in DynamoDB. Define
          attributes corresponding to the table's columns and annotate them with
          DynamoDB annotations to map them to table attributes.
          <div className="listitems">
            <pre>{usersEntitySnippet}</pre>
          </div>
          <div className="listitems">
            <pre>{rolesEntitySnippet}</pre>
          </div>
        </li>
        <li>
          <strong>Add Request Class for Data Transfer:</strong> Create a request
          class to represent the data needed for inserting a new user into the
          users table. Include fields corresponding to the attributes of the
          users table.
          <div className="listitems">
            <pre>{usersRequestSnippet}</pre>
          </div>
        </li>
        <li>
          <strong>Add Controller Class and Endpoints:</strong> Create a Spring
          MVC controller class to handle HTTP requests related to managing users
          in DynamoDB. Define endpoints for inserting a new item into the users
          table and retrieving user data by userId and userName. Inject the
          DynamoDB service into the controller to perform CRUD operations.
          <div className="listitems">
            <pre>{dynamoDbControllerSnippet}</pre>
          </div>
        </li>
        <li>
          <strong>Add Service Class for Endpoints:</strong> Create a service
          class to encapsulate the business logic for interacting with DynamoDB.
          Define methods for inserting a new user and retrieving user data by
          userId and userName. Use the DynamoDB mapper to perform CRUD
          operations on the DynamoDB table.
          <div className="listitems">
            <pre>{dynamoDbServiceSnippet}</pre>
          </div>
          <p>
            <strong>Note:</strong> dollarSymbol text in above code should be
            replaced with dollar symbol
          </p>
        </li>
      </ol>
    </div>
  );
};

export default DynamoDB;
