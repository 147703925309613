import React from "react";


const importGWTClasses = `
import com.google.gwt.user.client.ui.VerticalPanel; 
import com.google.gwt.user.client.ui.HorizontalPanel; 
import com.google.gwt.user.client.ui.Label; 
import com.google.gwt.user.client.ui.TextBox; 
import com.google.gwt.user.client.ui.Button; 
import com.google.gwt.user.client.ui.RootPanel;
`;

const declarePanels = `

private VerticalPanel formPanel = new VerticalPanel();
private HorizontalPanel buttonPanel = new HorizontalPanel();
`;

const setPanelStyles=`
formPanel.addStyleName("formPanel");
`;

const createFormElements=`
Label firstNameLabel = new Label("First Name:");
TextBox firstNameBox = new TextBox();
`;

const AddFormElementsToPanel=`
HorizontalPanel firstNameGroup = new HorizontalPanel();
firstNameGroup.add(firstNameLabel);
firstNameGroup.add(firstNameBox);
formPanel.add(firstNameGroup);
`;

const addSubmitButton=`
Button submitButton = new Button("Submit");
buttonPanel.add(submitButton);
buttonPanel.setHorizontalAlignment(HorizontalPanel.ALIGN_CENTER);
formPanel.add(buttonPanel);
`;

const addFormPanelToRootPanel=`
RootPanel.get("formContainer").add(formPanel);
`;

const SampleGWTAppHtml = `
<!doctype html>
<html>
<head>
    <meta charset="UTF-8">
    <link type="text/css" rel="stylesheet" href="SampleGWTApp.css">
    <title>SampleGWTApp</title>
    <script type="text/javascript" src="samplegwtapp/samplegwtapp.nocache.js"></script>
</head>
<body>
<h1>Registration Form</h1>
<div id="formContainer" style="background-color: #f0f8ff;"></div>
<noscript>
    <div style="color: red;">
        Your web browser must have JavaScript enabled for this application to display correctly.
    </div>
</noscript>
</body>
</html>

`;

const SampleGWTAppCss = `
body {
    font-family: Arial, sans-serif;
    background-color: lightblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    color: darkblue;
}

#formContainer {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin-left: -50px;
    margin-top: -20px;
}

.formPanel {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formPanel label {
    font-weight: bold;
    margin-bottom: 5px;
    color: darkblue;
}

.formPanel input,
.formPanel select,
.formPanel textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    background-color: #007BFF;
}

button:hover {
    background-color: #0056b3;
}
`;

const SampleGWTAppJava = `
package com.nipuna.codesnippets.gwt.client;

import com.google.gwt.core.client.EntryPoint;
import com.google.gwt.event.dom.client.ClickEvent;
import com.google.gwt.event.dom.client.ClickHandler;
import com.google.gwt.user.client.Window;
import com.google.gwt.user.client.ui.Button;
import com.google.gwt.user.client.ui.Label;
import com.google.gwt.user.client.ui.ListBox;
import com.google.gwt.user.client.ui.RadioButton;
import com.google.gwt.user.client.ui.RootPanel;
import com.google.gwt.user.client.ui.TextArea;
import com.google.gwt.user.client.ui.TextBox;
import com.google.gwt.user.client.ui.VerticalPanel;
import com.google.gwt.user.client.ui.HorizontalPanel;

public class SampleGWTApp implements EntryPoint {

    private VerticalPanel formPanel = new VerticalPanel();

    // Create labels for each field
    private Label firstNameLabel = new Label("First Name:");
    private Label lastNameLabel = new Label("Last Name:");
    private Label genderLabel = new Label("Gender:");
    private Label mobileLabel = new Label("Mobile:");
    private Label emailLabel = new Label("Email:");
    private Label countryLabel = new Label("Country:");
    private Label notesLabel = new Label("Notes:");

    private TextBox firstNameBox = new TextBox();
    private TextBox lastNameBox = new TextBox();
    private RadioButton maleRadioButton = new RadioButton("gender", "Male");
    private RadioButton femaleRadioButton = new RadioButton("gender", "Female");
    private TextBox mobileBox = new TextBox();
    private TextBox emailBox = new TextBox();
    private ListBox countryDropdown = new ListBox();
    private TextArea notesArea = new TextArea();
    private Button submitButton = new Button("Submit");

    public void onModuleLoad() {
        // Dropdown items
        countryDropdown.addItem("Select a country");
        countryDropdown.addItem("USA");
        countryDropdown.addItem("Canada");
        countryDropdown.addItem("UK");
        countryDropdown.addItem("Australia");
        countryDropdown.addItem("India");

        // Add labels and fields to the form panel
        formPanel.addStyleName("formPanel");
        formPanel.add(createFormGroup(firstNameLabel, firstNameBox));
        formPanel.add(createFormGroup(lastNameLabel, lastNameBox));
        formPanel.add(createFormGroup(genderLabel, maleRadioButton, femaleRadioButton));
        formPanel.add(createFormGroup(mobileLabel, mobileBox));
        formPanel.add(createFormGroup(emailLabel, emailBox));
        formPanel.add(createFormGroup(countryLabel, countryDropdown));
        formPanel.add(createFormGroup(notesLabel, notesArea));

        // Add the submit button in a centered horizontal panel
        HorizontalPanel buttonPanel = new HorizontalPanel();
        buttonPanel.setWidth("100%");
        buttonPanel.setHorizontalAlignment(HorizontalPanel.ALIGN_CENTER);
        buttonPanel.add(submitButton);
        formPanel.add(buttonPanel);

        // Add the form panel to the RootPanel
        RootPanel.get("formContainer").add(formPanel);
    }

    private HorizontalPanel createFormGroup(Label label, com.google.gwt.user.client.ui.Widget... widgets) {
        HorizontalPanel groupPanel = new HorizontalPanel();
        groupPanel.setSpacing(10);
        label.setWidth("100px");
        groupPanel.add(label);
        for (com.google.gwt.user.client.ui.Widget widget : widgets) {
            groupPanel.add(widget);
        }
        return groupPanel;
    }
}
`;

const CreateFormUsingPanelGWT: React.FC = () => {
  return (
    <div>
      <h2>Create Form Using Panel in GWT Project</h2>
      <p>
        In GWT, Panels are crucial for organizing and laying out user
        interfaces. This guide will walk you through creating a registration
        form using a <code>VerticalPanel</code> and <code>HorizontalPanel</code>
        .
      </p>

      <h3>Steps to Create a Form Using Panel</h3>

      <ol>
        <li>
          <strong>Set Up Your Project:</strong> Ensure your GWT project is set
          up as detailed in the previous sections.
        </li>
        <li>
          <strong>Import Necessary Classes:</strong> In your main application
          class, import the required GWT classes:
          <div className="listitems">
            <pre>{importGWTClasses}</pre>
          </div>
        </li>
        <li>
          <strong>Create Panel Instances:</strong> Declare and instantiate your
          panels:
          <div className="listitems">
            <pre>{declarePanels}</pre>
          </div>
        </li>
        <li>
          <strong>Set Panel Styles:</strong> Add styles to your panels to
          enhance the UI:
          <div className="listitems">
            <pre>{setPanelStyles}</pre>
          </div>
        </li>
        <li>
          <strong>Create Form Elements:</strong> Create labels and input fields:
          <div className="listitems">
            <pre>{createFormElements}</pre>
          </div>
        </li>
        <li>
          <strong>Add Form Elements to the Panel:</strong> Use{" "}
          <code>HorizontalPanel</code> to group each label and its corresponding
          input field:
          <div className="listitems">
            <pre>{AddFormElementsToPanel}</pre>
          </div>
        </li>
        <li>
          <strong>Add Submit Button:</strong> Create a button and add it to the
          button panel:
          <div className="listitems">
            <pre>{addSubmitButton}</pre>
          </div>
        </li>
        <li>
          <strong>Add the Form Panel to the Root Panel:</strong> Finally, add
          your <code>formPanel</code> to the main application view:
          <div className="listitems">
            <pre>{addFormPanelToRootPanel}</pre>
          </div>
        </li>
      </ol>

      <h3>Example Code Snippet</h3>
      <p>
        Here's how your <code>SampleGWTApp.java</code> might look:
      </p>
      <div className="listitems">
        <pre>{SampleGWTAppJava}</pre>
      </div>

      <h3>Sample HTML Structure</h3>
      <p>Your HTML file should look like this:</p>
      <div className="listitems">
        <pre>{SampleGWTAppHtml}</pre>
      </div>

      <h3>Sample CSS Styling</h3>
      <p>Include the following CSS for styling:</p>
      <div className="listitems">
        <pre>{SampleGWTAppCss}</pre>
      </div>
      <div className="listitems">
      <h3>Registration form UI created with panel looks as below:</h3>
        <img
          src="/ui_panel_gwt.png"
          alt="ui_panel_gwt"
           style={{ width: "500px" , height: "500px"}}
        />
      </div>
      <p>
        By following these steps, you can create forms using Panel components in
        your GWT project, improving the layout and usability of your
        application.
      </p>
    </div>
  );
};

export default CreateFormUsingPanelGWT;
