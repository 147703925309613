import "./Header.css";
import { FC } from "react";

export const Header: FC = () => {
  return (
    <>
      <div className="nav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title">
            <div className="logo">
              <h3>Code Snippets</h3>
            </div>
          </div>
        </div>
        <div className="nav-btn">
          <label>
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div className="nav-links">
          {/* <ul>
            <li className="sub-nav">
              <a href="/">Java</a>
            </li>
            <li>
              <a href="/">Express</a>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};
