import axios from "axios";
import { useEffect, useState } from "react";
import LeftMenu from "./components/LeftMenu/LeftMenu";
import { Header } from "./components/Header/Header";
import ContentDisplay from "./components/ContentDisplay/ContentDisplay";
import Footer from "./components/Footer/Footer";

function App() {
  const [selectedContent] =
    useState<React.ReactNode | null>(null);

  useEffect(() => {
    const dataToSend = { message: "Hello from React!" };

    axios
      .post("http://localhost:4200/webhooks", dataToSend)
      .then((response: { data: any }) => {
        console.log("Webhook response:", response.data);
      })
      .catch((error: any) => {
        console.error("Error triggering webhook:", error);
      });
  }, []);

  return (
    <div>
      <Header /> 
      <LeftMenu />
      <ContentDisplay activeContent={selectedContent} />
      <Footer />
    </div>
  );
}

export default App;