import React from "react";
const TableCodeSnippetRawHTML = `
/* Apply styles to the entire table */
table {
  width: 100%;
  border-collapse: collapse; /* Merge adjacent borders into a single border */
  margin-bottom: 20px;
}

/* Style for table headers (th) */
th {
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 8px;
  text-align: left;
}

/* Style for alternating rows */
tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Style for table cells (td) */
td {
  padding: 8px;
  border-bottom: 1px solid #ddd; /* Add a bottom border to separate cells */
}

/* Hover effect on rows */
tr:hover {
  background-color: #e6e6e6;
}
`;
const FloatLeftCodeSnippetRawHTML = `
.float-left {
  float: left;
}
.float-right {
  float: right;
}`;
const FlexCodeSnippetRawHTML = `
.flex-container {
  display: flex;
  justify-content: space-between;
}`;
const GridCodeSnippetRawHTML = `
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}`;
const PositioningCodeSnippetRawHTML = `
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}`;
const ResponsiveCodeSnippetRawHTML = `
@media screen and (max-width: 600px) {
  /* Styles for small screens */
}`;
const FloatsCodeSnippetRawHTML = `
@media screen and (max-width: 600px) {
  /* Styles for small screens */
}`;
const BlockCodeSnippetRawHTML = `
.block-element {
  display: block;
}`;
const InlineCodeSnippetRawHTML = `
.inline-element {
  display: inline;
}`;
const InlineBlockCodeSnippetRawHTML = `
.inline-block-element {
  display: inline-block;
}`;
const NoneCodeSnippetRawHTML = `
.hidden-element {
  display: none;
}`;
const FlexContainerCodeSnippetRawHTML = `
.flex-container {
  display: flex;
}`;
const FlexGridCodeSnippetRawHTML = `
.grid-container {
  display: grid;
}`;
const TableContainerCodeSnippetRawHTML = `
.table-container {
  display: table;
}`;
const Module06: React.FC = () => {
  return (
    <div>
      <h2> CSS Tablestyle:</h2>
      <p>
        <ul>
          <li>
            This CSS applies styles to the different components of the table:
          </li>
          <li>
            The table selector styles the entire table, setting its width to
            100%, collapsing borders, and adding some bottom margin for spacing.
          </li>
          <li>
            The th selector styles table headers, setting a background color,
            making text bold, and adding padding.
          </li>
          <li>
            The tr:nth-child(even) selector styles alternating rows with a
            different background color.
          </li>
          <li>
            The td selector styles table cells, adding padding and a bottom
            border.
          </li>
          <li>
            The tr:hover selector adds a hover effect to rows when they are
            hovered over.
          </li>
        </ul>
      </p>
      <h2> CSS Layouts:</h2>
      <p>
        layouts are the structures define how elements are visually arranged on
        a web page. There are several ways to create layouts in CSS, and some
        commonly used layout techniques include:
      </p>
      <h3>Float Layouts:</h3>
      <p>
        Use the float property to move elements to the left or right.
        <br />
        Elements following a floated element will flow around it.
      </p>
      <div className="listitems">
        <pre>{FloatLeftCodeSnippetRawHTML}</pre>
      </div>
      <h3>Flexbox Layouts:</h3>
      <p>
        Use the Flexbox model for one-dimensional layouts. <br />
        Align and distribute space among items in a container. <br />
      </p>
      <div className="listitems">
        <pre>{FlexCodeSnippetRawHTML}</pre>
      </div>
      <h3>Grid Layouts:</h3>
      <p>
        Use the CSS Grid layout for two-dimensional layouts. Define rows and
        columns to create a grid-based structure.
      </p>
      <div className="listitems">
        <pre>{GridCodeSnippetRawHTML}</pre>
      </div>
      <h3>Positioning: </h3>
      <p>
        Use the position property to control the position of elements. <br />
        Common values include
        <ul>
          <li>relative</li>
          <li> absolute</li> <li>fixed</li>
        </ul>
      </p>
      <div className="listitems">
        <pre>{PositioningCodeSnippetRawHTML}</pre>
      </div>
      <h3>Responsive Design: </h3>
      <p>
        Use media queries to apply styles based on the device or screen size.
        Create flexible and adaptive layouts for different devices..
      </p>
      <div className="listitems">
        <pre>{ResponsiveCodeSnippetRawHTML}</pre>
      </div>
      <h2>CSS Floats:</h2>
      <p>
        Use the property to control how an element should behave regarding
        floating elements.
      </p>
      <div className="listitems">
        <pre>{FloatsCodeSnippetRawHTML}</pre>
      </div>
      <h2>CSS Frameworks:</h2>
      <p>
        Utilize CSS frameworks like Bootstrap or Tailwind CSS for pre-built
        responsive layouts and components.
      </p>
      <h3>Display Property:</h3>
      <p>
        The display property in CSS is used to specify how an element should be
        displayed on the web page. It determines the type of box used for an
        HTML element and influences the layout of the surrounding elements.
      </p>
      <h3>Here are some common values for the display property:</h3>
      <h4>block:</h4>
      <p>
        The element generates a block-level box.
        <br />
        It starts on a new line and takes up the full width available.
      </p>
      <div className="listitems">
        <pre>{BlockCodeSnippetRawHTML}</pre>
      </div>
      <h4>inline:</h4>
      <p>
        The element generates a inline-level box.
        <br />
        It does not start on a new line and only takes up as much width as
        necessary.
      </p>
      <div className="listitems">
        <pre>{InlineCodeSnippetRawHTML}</pre>
      </div>
      <h4>inline-block:</h4>
      <p>
        The element generates an inline-level box, but it behaves as a
        block-level box.
        <br />
        It does not start on a new line and allows other elements to sit next to
        it.
      </p>
      <div className="listitems">
        <pre>{InlineBlockCodeSnippetRawHTML}</pre>
      </div>
      <h4>none:</h4>
      <p>
        The element is completely removed from the document flow, and it is not
        displayed.
        <br />
      </p>
      <div className="listitems">
        <pre>{NoneCodeSnippetRawHTML}</pre>
      </div>
      <h4>flex:</h4>
      <p>
        The element generates a block-level container with a flexible box inside
        it.
        <br />
        It enables a flex context for its direct children.
      </p>
      <div className="listitems">
        <pre>{FlexContainerCodeSnippetRawHTML}</pre>
      </div>
      <h4>grid:</h4>
      <p>
        The element generates a block-level container with a grid inside it.{" "}
        <br />
        It enables a grid context for its direct children.
      </p>
      <div className="listitems">
        <pre>{FlexGridCodeSnippetRawHTML}</pre>
      </div>
      <h4>table:</h4>
      <p>
        The element generates a block-level container box that will be used for
        table layout. <br />
        It allows the use of table-related properties like table-row and
        table-cell.
      </p>
      <div className="listitems">
        <pre>{TableContainerCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module06;
