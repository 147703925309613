import React from "react";

const logLevels = `
{
  error:0,
  warn:1,
  info:2,
  http:3,
  verbose:4,
  debug:5,
  silly:6
}
`;

const initialiseNodeProject = `
npm init -y
`;
const installExpress = `
npm install express
`;
const installWinston = `
npm install winston
`;

const createProjectFiles = `
touch index.js logger.js
mkdir logs
`;

const loggerJsFile = `
import { createLogger, transports, format } from 'winston';
const { combine, timestamp, printf } = format;

const logger = createLogger({
    level: 'info', // Set default logging level
    format: combine(
        timestamp(),
        printf(({ level, message, timestamp }) => {
            return dollarSign{timestamp} [dollarSign{level.toUpperCase()}]: dollarSign{message};
        })
    ),
    transports: [
        new transports.Console(), 
        new transports.File({ 
            filename: 'logs/combined.log',
            format: format.combine(
                format.timestamp(),
                format.json()
            ),
            maxsize: 5242880,
            maxFiles: 5,
            tailable: true
        })
    ]
});

export default logger;
`;

const indexJsFile = `
import express from 'express';
import logger from './logger.js';

const app = express();
const port = process.env.PORT || 4000;

app.get('/file-logging', (req, res) => {
    logger.error('Error message'); 
    logger.warn('Warning message'); 
    logger.info('Info message'); 
    logger.debug('Debug message'); 
    logger.verbose('Verbose message'); 
    logger.silly('Silly message');
    res.send('File-based logging');
});

app.listen(port, () => {
    logger.info('Server is running on port dollarSign{port}');
});

`;

const runCommand = `
node index.js
`;

const WinstonFileBasedLogging: React.FC = () => {
  return (
    <div>
      <h2>Winston File Based Logging</h2>
      <p>
        Winston is a versatile logging library for Node.js applications that
        provides support for logging messages to various destinations, such as
        files, the console, databases, and more. It offers flexibility in
        configuring logging levels, formats, transports, and metadata.
      </p>
      <p>
        File-based logging involves writing log messages to files on disk. This
        method is useful for long-term storage, auditing, and debugging
        purposes. Here's a breakdown of how Winston handles file-based logging:
      </p>
      <p>
        1. <strong>Logger Setup:</strong> Have to tell Winston to create a logger that will
        handle logging messages.
      </p>
      <p>
        2. <strong>Specify File Transport:</strong> Need to instruct Winston to use a file
        transport to save log messages to a file.
      </p>
      <p>
        3. <strong>Define File Details:</strong> Provide details such as the file name, maximum
        file size, and how many log files to keep.
      </p>
      <p>
        4. <strong>Format Logs:</strong> Set up how log messages will appear in the file, like
        adding timestamps.
      </p>
      <p>
        5. <strong>Use the Logger:</strong> Once configured, we can start using the logger to log
        messages to the file.
      </p>
      <p>
        Winston supports six log levels by default.Each level is given an
        integer priority with the most severe being the lowest number and the
        least one being the highest. Below are different log levels:
      </p>
      <ul className="listitems">
        <pre>{logLevels}</pre>
      </ul>
      <p>
        The idea is that we can take logger and you can set log level globally
        and then it will only log the log level and the ones below it. For
        example,if we have info logger,it will write out all messages for
        log.info,log.warn and log.error.But if you have log.debug,its not going
        to log it out because the current level is set to info or below.
      </p>

      <h3>Setting Up Your Project: </h3>
      <p>
        1. Install Node.js and npm: If you haven't already, download and install
        Node.js from nodejs.org. npm (Node Package Manager) comes bundled with
        Node.js.
      </p>
      <p>
        2. Create a new directory for your project.
      </p>
      <p>
        3. Navigate to the Project Directory.
      </p>
      <p>
        4. Initialize a Node.js project: Run npm init and follow the prompts to
        create a package.json file.
      </p>
      <ul className="listitems">
        <pre>{initialiseNodeProject}</pre>
      </ul>
      <p>
        5. Install Express.js as a dependency for your
        project.
      </p>
      <ul className="listitems">
        <pre>{installExpress}</pre>
      </ul>
      <p>6. Install Winston for logging purposes.</p>
      <ul className="listitems">
        <pre>{installWinston}</pre>
      </ul>
      <p>7. Create Project Files</p>
      <ul className="listitems">
        <pre>{createProjectFiles}</pre>
      </ul>

      <p>8. Configure File-based Logging.'logger.js' file will look like:</p>
      <ul className="listitems">
        <pre>{loggerJsFile}</pre>
      </ul>
      <p>
        <strong>Note:</strong> dollarSymbol text in above code should be replaced with dollar
        symbol
      </p>

      <p>
        9. Create Express.js Server with File-based Logging.Edit index.js as
        below:
      </p>
      <ul className="listitems">
        <pre>{indexJsFile}</pre>
      </ul>
      <p>
      <strong>Note:</strong> dollarSymbol text in above code should be replaced with dollar
        symbol
      </p>

      <p>10. Run the File-based Logging Project using command:</p>
      <ul className="listitems">
        <pre>{runCommand}</pre>
      </ul>

      <p>
        11. Access the endpoint: 'http://localhost:4000/file-logging' and we can
        see logs in the logs folder.{" "}
      </p>
    </div>
  );
};

export default WinstonFileBasedLogging;
