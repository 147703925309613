import React from "react";

const Ngrok: React.FC = () => {
  return (
    <div>
      <h2>Ngrok Introduction</h2>
      <p>
        Ngrok is a known and widely used service that allows you to make your
        local development environment accessible over the internet. It creates
        pathways from a public access point to your hosted web service, making
        it convenient to share and test your web applications during the
        development phase.
      </p>

      <h2>Key Features of Ngrok:</h2>
      <li>
        <strong>Local Development Exposure:</strong> Easily showcase your work
        by exposing local servers to the internet during development.
      </li>
      <li>
        <strong>Secure Tunnels:</strong> Ngrok encrypts data for secure
        communication between your server and Ngrok, ensuring privacy.
      </li>
      <li>
        <strong>HTTP and HTTPS Support:</strong> Test both HTTP and HTTPS
        features in your web service.
      </li>
      <li>
        <strong>Subdomain Convenience:</strong> Ngrok assigns a subdomain for
        easy sharing, simplifying access to your application.
      </li>
      <li>
        <strong>Web Interface:</strong> A web-based dashboard allows you to
        inspect, replay requests, and monitor traffic passing through the
        tunnel.
      </li>
      <h2>Basic Usage:</h2>
      <ol>
        <li>
          <strong>Install Ngrok:</strong> Download and install Ngrok from the
          official website:{" "}
          <a href="https://ngrok.com/download">https://ngrok.com/download</a>
        </li>
        <li>
          <strong>Run Ngrok:</strong> Open a terminal and navigate to the
          directory where Ngrok is installed. Run the following command to
          expose a local web server (replace 3000 with your local server port):
          <pre className="listitems">ngrok http 3000</pre>
        </li>
        <li>
          <strong>Access the Public URL:</strong> Ngrok will generate a public
          URL (e.g.,{" "}
          <a href="https://cde7-2401-4900-1c29-2be0-2d3b-ae56-3e79-fc10.ngrok-free.app">
            https://cde7-2401-4900-1c29-2be0-2d3b-ae56-3e79-fc10.ngrok-free.app
          </a>
          ). Share this URL with others to access your local web service.
        </li>
      </ol>

      <p>
        Remember that while Ngrok is convenient for development, it's not
        intended for production use. It's crucial to keep your Ngrok tunnels
        secure and avoid exposing sensitive data in a public environment.
      </p>
    </div>
  );
};

export default Ngrok;
