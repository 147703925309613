import React from "react";

const CQRSPattern: React.FC = () => {
  return (
    <div>
      <div className="listitems">
        <h2>Command Query Responsibility Segregation Pattern</h2>
        <img
          src="/cqrs.png"
          alt="CQRS Pattern"
        />
      </div>
    </div>
  );
};

export default CQRSPattern;
