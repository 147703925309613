import React from "react";

const DistributedSystemPatterns: React.FC = () => {
  return (
    <div>
      <h1>Distributed System Patterns</h1>
      <h2>Ambassador:</h2>
      <p>
        The Ambassador pattern involves placing a proxy (ambassador) between
        clients and services to offload common functionalities such as
        authentication, load balancing, and logging. This helps in simplifying
        client code and ensures consistent behavior across services.
      </p>
      <img
          src="/ambassador.png"
          alt="Ambassador"
          style={{ width: "800px"}}
        />

      <h2>Circuit Breaker:</h2>
      <p>
        The Circuit Breaker pattern is used to prevent a network or service
        failure from cascading to other parts of the system. It monitors
        failures, and if a predefined threshold is reached, it opens the
        circuit, preventing further requests. This can improve system
        resilience and prevent overloading during outages.
      </p>
      <img
          src="/circuit_breaker.png"
          alt="Circuit Breaker"
          style={{ width: "800px"}}
        />

      <h2>CQRS (Command Query Responsibility Segregation):</h2>
      <p>
        CQRS involves separating the read and write operations of a system. It
        uses different models for reading and writing data, allowing for
        optimized data structures and independent scalability. This pattern is
        useful in scenarios where the read and write workloads vary.
      </p>
      <img
          src="/cqrs_distributed_system_patterns.png"
          alt="CQRS"
          style={{ width: "800px"}}
        />

      <h2>Event Sourcing:</h2>
      <p>
        Event Sourcing is a pattern where the state of a system is determined
        by a sequence of events. Instead of storing the current state, events
        that led to the state are stored. This enables auditability,
        scalability, and the ability to reconstruct system state at any point
        in time.
      </p>
      <img
          src="/event_sourcing.png"
          alt="Event Sourcing"
          style={{ width: "600px"}}
        />

      <h2>Leader Election:</h2>
      <p>
        Leader Election is a pattern used in distributed systems to ensure
        coordination and consistency. It involves selecting a leader from a
        group of nodes, and the leader takes responsibility for certain tasks.
        If the leader fails, a new leader is elected to maintain system
        functionality.
      </p>
      <img
          src="/leader_election.png"
          alt="Leader Election"
          style={{ width: "800px"}}
        />

      <h2>Publisher/Subscriber:</h2>
      <p>
        The Publisher/Subscriber pattern (or Pub-Sub) is a messaging pattern
        where senders (publishers) of messages do not specifically target
        receivers (subscribers). Instead, messages are broadcasted, and
        subscribers express interest in specific types of messages. This
        pattern facilitates loose coupling between components.
      </p>
      <img
          src="/publisher_subscriber.png"
          alt="Publisher Subscriber"
          style={{ width: "800px"}}
        />

      <h2>Sharding:</h2>
      <p>
        Sharding involves partitioning data across multiple databases or
        servers. Each partition, or shard, handles a subset of the overall
        data. Sharding is used to improve scalability and performance by
        distributing the workload, especially in large-scale distributed
        systems.
      </p>
      <img
          src="/sharding.png"
          alt="Sharding"
          style={{ width: "800px"}}
        />
    </div>
  );
};
export default DistributedSystemPatterns;
