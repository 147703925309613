import React from "react";

const ElasticSearch: React.FC = () => {
  return (
    <div>
      <h2>Elastic Search</h2>
      <p>
        Elasticsearch is a distributed, RESTful search and analytics engine
        designed for horizontal scalability, reliability, and real-time search
        and analytics use cases. It's built on top of Apache Lucene and provides
        a distributed, multi-tenant-capable full-text search engine with an HTTP
        web interface and schema-free JSON documents.
      </p>
      <p>
        {" "}
        Elasticsearch is a powerful tool for searching, analyzing, and
        visualizing large volumes of data in real-time, making it widely used in
        various industries including e-commerce, finance, healthcare, and more.
      </p>
      <h3>Steps to install Elasticsearch using Docker: </h3>
      <ol>
        <li>
          Pull the Elasticsearch Docker Image: We'll start by pulling the
          Elasticsearch image from the official Elastic repository. Open your
          terminal or command prompt and enter the following command:
          <pre>
            docker pull docker.elastic.co/elasticsearch/elasticsearch:8.13.0
          </pre>
        </li>
        <li>
          Create a Docker Network: Elasticsearch needs a network for
          communication. Let's create a Docker network named "elastic":
          <pre>docker network create elastic</pre>
        </li>
        <li>
          Run the Elasticsearch Container: Now, let's run the Elasticsearch
          container using the image we pulled earlier. We'll name the container
          "elasticsearch," connect it to the "elastic" network, and map port
          9200 (Elasticsearch HTTP) to the host:
          <pre>
            docker run --name elasticsearch --net elastic -p 9200:9200 -p
            9300:9300 -e "discovery.type=single-node" -t
            docker.elastic.co/elasticsearch/elasticsearch:8.13.0
          </pre>
        </li>
        <li>
          Copy the credentials which are generated and save for future login.
        </li>
        <li>
          Handling Memory Exceptions (if needed): If you encounter memory
          exceptions while running the container, use the following command to
          increase the vm.max_map_count value:
          <pre>
            wsl -d docker-desktop sh -c "sysctl -w vm.max_map_count=262144"
          </pre>
        </li>
        <p>
          Open{" "}
          <a href="https://localhost:9200" target="_blank">
            https://localhost:9200
          </a>{" "}
          in browser and login with credentials to check the status if elastic
          serach is running or not.
        </p>
        <p>
          We also have a browser extension called "Elasticvue" which we can use
          to check the elastic search status(if its runnning in the machine or
          not) and also can manage the indexes.
        </p>
      </ol>
    </div>
  );
};

export default ElasticSearch;
