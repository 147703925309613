import React from "react";

const ExposingNgrokThroughGoogleAuth: React.FC = () => {
  return (
    <div>
      <h2>Configure Ngrok Url in GoogleAuth</h2>
      <p>
        Below are the steps we follow to expose Ngrok through Google Developer
        Console:
      </p>
      <li>
        <strong>Google Developer Console OAuth Consent Screen:</strong>
        <p>
          Change google developer console OAuth consent screen settings. Select
          Edit app registration and change Application home page and Authorized
          domain1 settings as below screenshot to match the ngrox url:
        </p>
        <div className="listitems">
          <img
            src="/google_developer_console_ngrok_ui_settings.png"
            alt="Google Developer Console OAuth Consent Screen - Step 3"
          />
        </div>
      </li>
      <li>
        By following these steps, you can properly configure ngrok environment
        with Google Developer Console OAuth consent.
      </li>
    </div>
  );
};

export default ExposingNgrokThroughGoogleAuth;
