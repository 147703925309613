import React from "react";

const plugin = `
plugins {
  id "com.avast.gradle.docker-compose" version "0.14.0"
}

`;

const dockerComposeTest = `
dockerCompose {
  isRequiredBy(tasks.test)
}
`;

const GradleDockerCompose: React.FC = () => {
  return (
    <div>
      <h2>Gradle Docker Compose</h2>
      <p>
        The <strong>com.avast.gradle.docker-compose</strong> plugin is a Gradle
        plugin designed to facilitate the integration of Docker Compose into the
        Gradle build process. This plugin allows you to manage Docker containers
        and services defined in a docker-compose.yml file directly from Gradle
        tasks, such as building images, starting and stopping containers, and
        even running tests against services running inside these containers.
      </p>
      <p>
        To use the plugin, you need to include it in your build.gradle file:
      </p>
      <div className="listitems">
        <pre>{plugin}</pre>
      </div>
      <p>
        The plugin automatically creates several Gradle tasks related to Docker
        Compose:
      </p>
      <ul>
        <li>
          <strong>composeBuild:</strong> Builds the Docker images defined in
          your docker-compose.yml.
        </li>
        <li>
          <strong>composeUp:</strong> Starts the services defined in your
          docker-compose.yml and keeps them running in the background.
        </li>
        <li>
          <strong>composeDown:</strong> Stops and removes containers of
          docker-compose project
        </li>
        <li>
          <strong>composeDownForced:</strong> Builds the Docker images defined
          in your docker-compose.yml.
        </li>
        <li>
          <strong>composeLogs:</strong> Stores log output from services in
          containers of docker-compose project
        </li>
        <li>
          <strong>composePull:</strong> Builds and pulls images of
          docker-compose project
        </li>
        <li>
          <strong>composePush:</strong> Pushes images for services of
          docker-compose project
        </li>
      </ul>

      <h3>Docker Compose Integration with Gradle</h3>
      <p>
        The primary configuration block for this plugin is dockerCompose. Within
        this block, you can specify various options to customize the behavior of
        the Docker Compose integration.
      </p>
      <p>
        Here’s an example configuration(in build.gradle file) that integrates
        Docker Compose with your test tasks:
      </p>
      <div className="listitems">
        <pre>{dockerComposeTest}</pre>
      </div>
      <p>
        Using Gradle and Docker Compose makes it simple to run integration tests
        that rely on external systems. This method keeps the test suite
        organized with distinct separation of concerns, ensuring that the build
        process remains consistent, reproducible, and efficient.
      </p>
    </div>
  );
};

export default GradleDockerCompose;
