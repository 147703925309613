import React from "react";
const BorderCodeSnippetRawHTML = `selector {
  border: [border-width] [border-style] [border-color];
}
`;
const RedBorderCodeSnippetRawHTML = `.example {
  border: 2px solid red;
}
`;
const BlueBorderCodeSnippetRawHTML = `.example {
  border: 1px dashed blue;
}
`;
const BlackBorderCodeSnippetRawHTML = `.example {
  border: 3px double black;
}
`;
const PaddingCodeSnippetRawHTML = `selector {
  padding: value;
}
`;
const EqualCodeSnippetRawHTML = `.example {
  padding: 10px;
}
`;
const DifferentPaddingCodeSnippetRawHTML = `.example {
  padding-top: 20px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 5px;
}
`;
const ShorthandCodeSnippetRawHTML = `body {
  font: italic small-caps bold 16px/1.5 "Times New Roman", Times, serif;
}
`;
const PercentageCodeSnippetRawHTML = `body {
  .example {
    padding: 5%;
  }
}
`;
const WHCodeSnippetRawHTML = `.example {
  width: 200px;
  height: 100px;
}
`;
const MaxWHCodeSnippetRawHTML = `.example {
  width: 200px;
  height: 100px;
}
`;
const MinWHCodeSnippetRawHTML = `
.example {
  min-width: 100px;
  min-height: 50px;
}
`;
const ViewPortCodeSnippetRawHTML = `
.example {
  width: 50vw; /* 50% of viewport width */
  height: 25vh; /* 25% of viewport height */
}
`;
const FontSizeCodeSnippetRawHTML = `
.example {
  font-size: 16px;
}
`;
const LineCodeSnippetRawHTML = `
.example {
  line-height: 1.5; /* 1.5 times the font size */
}
`;
const MarginSnippetRawHTML = `
/* Shorthand syntax (applies to all sides) */
selector {
  margin: value;
}

/* Individual sides */
selector {
  margin-top: value;
  margin-right: value;
  margin-bottom: value;
  margin-left: value;
}
`;
const MarginAllSnippetRawHTML = `
.example {
  margin: 10px; /* Applies 10px margin to all sides */
}
`;
const MarginIndividualSnippetRawHTML = `
.example {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 15px;
  margin-left: 20px;
}
`;
const ValueCodeSnippetRawHTML = `
.example {
  margin: 10px 20px 15px 5px;
  /* Top: 10px, Right: 20px, Bottom: 15px, Left: 5px */
}
`;
const BodyCodeSnippetRawHTML = `
body {
  margin: 0;
}
`;
const BoxCodeSnippetRawHTML = `
selector {
  box-sizing: content-box | border-box;
}
`;
const ContentBoxCodeSnippetRawHTML = `
.example {
  box-sizing: content-box;
}
`;
const BorderBoxCodeSnippetRawHTML = `
.example {
  box-sizing: border-box;
}
`;
const BorderBoxSizeCodeSnippetRawHTML = `
/* Apply border-box sizing to all elements */
* {
  box-sizing: border-box;
}

/* Example of an element with padding and border */
.box {
  width: 200px;
  height: 100px;
  padding: 20px;
  border: 2px solid #333;
  box-sizing: border-box; /* Border-box sizing */
}
`;
const BorderBoxSizeResetCodeSnippetRawHTML = `
/* CSS Reset with box-sizing: border-box; */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
`;
const BoxPDMCodeSnippetRawHTML = `
/* CSS for a box with padding, border, and margin */
.box {
  width: 200px;
  height: 100px;
  padding: 20px;
  border: 2px solid #333;
  margin: 10px;
}
`;
const Module05: React.FC = () => {
  return (
    <div>
      <h2>CSS Border:</h2>
      <p>
        In CSS, the border property is used to set the border around an element.
        It can be applied to various HTML elements, such as divs, paragraphs,
        images, and more. The border property can be customized to control the
        width, style, and color of the border.
      </p>
      <h3>Syntax: </h3>
      <div className="listitems">
        <pre>{BorderCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>border-width:</b> Specifies the width of the border. It can be set to
        a specific value (e.g., 1px, 2px, etc.) or one of the predefined values
        (thin, medium, thick).
        <br />
        <b>border-style:</b> Specifies the style of the border. It can be set to
        values such as solid, dashed, dotted, double, and more.
        <br />
        <b>border-color:</b> Specifies the color of the border. It can be set to
        a color value (e.g., red, #00ff00, rgb(255, 0, 0)) or one of the
        predefined color names.
        <br />
      </p>
      <h3>Examples: </h3>
      <p>Setting a solid red border with a width of 2 pixels:</p>
      <div className="listitems">
        <pre>{RedBorderCodeSnippetRawHTML}</pre>
      </div>
      <p>Setting a dashed blue border with a width of 1 pixel:</p>
      <div className="listitems">
        <pre>{BlueBorderCodeSnippetRawHTML}</pre>
      </div>
      <p>Setting a double black border with a width of 3 pixels:</p>
      <div className="listitems">
        <pre>{BlackBorderCodeSnippetRawHTML}</pre>
      </div>
      <h3>Syntax: </h3>
      <div className="listitems">
        <pre>{PaddingCodeSnippetRawHTML}</pre>
      </div>
      <p>
        The value can be specified in various units like pixels (px), ems (em),
        rems (rem), percentages (%), etc.
      </p>
      <h3>Examples: </h3>
      <p>Setting equal padding on all sides:</p>
      <div className="listitems">
        <pre>{EqualCodeSnippetRawHTML}</pre>
      </div>
      <p>This will apply 10 pixels of padding to all sides of the element.</p>

      <p>Setting different padding for each side:</p>
      <div className="listitems">
        <pre>{DifferentPaddingCodeSnippetRawHTML}</pre>
      </div>
      <p>
        This will apply 20 pixels of padding to the top, 15 pixels to the right,
        10 pixels to the bottom, and 5 pixels to the left.
      </p>

      <p>Using shorthand notation:</p>
      <div className="listitems">
        <pre>{ShorthandCodeSnippetRawHTML}</pre>
      </div>
      <p>
        This is a shorthand notation for setting padding for the top, right,
        bottom, and left sides, respectively.
      </p>

      <p>Using percentage values:</p>
      <div className="listitems">
        <pre>{PercentageCodeSnippetRawHTML}</pre>
      </div>
      <h2>CSS Size:</h2>
      <p> Size can refer to different properties and concepts.</p>
      <h3>Examples: </h3>
      <p>
        <b>Width and Height:</b>
      </p>

      <p>
        To set the width and height of an element, you can use the width and
        height properties.
      </p>
      <div className="listitems">
        <pre>{WHCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Max Width and Max Height:</b>
      </p>

      <p>
        maximum width and height of an element can be set using max-width and
        max-height properties.
      </p>
      <div className="listitems">
        <pre>{MaxWHCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Min Width and Min Height:</b>
      </p>

      <p>
        The minimum width and height of an element can be defined using
        min-width and min-height properties.
      </p>
      <div className="listitems">
        <pre>{MinWHCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Viewport Units:</b>
      </p>

      <p>
        Viewport units are relative to the size of the browser window. You can
        use vw (viewport width) and vh (viewport height) units.
      </p>
      <div className="listitems">
        <pre>{ViewPortCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Font Size:</b>
      </p>

      <p>
        The font-size property is used to set the size of text within an
        element.
      </p>
      <div className="listitems">
        <pre>{FontSizeCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>Line Height:</b>
      </p>

      <p>Line-height determines the height of a line of text.</p>
      <div className="listitems">
        <pre>{LineCodeSnippetRawHTML}</pre>
      </div>
      <h2>CSS Margin:</h2>
      <p>
        The margin property is used to control the space outside the borders of
        an element. It defines the spacing between the element's border and
        adjacent elements. The margin property can be set for all sides or
        individually for each side (top, right, bottom, left).
      </p>
      <h3>Syntax:</h3>
      <div className="listitems">
        <pre>{MarginSnippetRawHTML}</pre>
      </div>
      <h3>Examples: </h3>
      <h4>Setting Margin for All Sides (Shorthand):</h4>
      <div className="listitems">
        <pre>{MarginAllSnippetRawHTML}</pre>
      </div>
      <h4>Setting Margin for Individual Sides (Shorthand):</h4>
      <div className="listitems">
        <pre>{MarginIndividualSnippetRawHTML}</pre>
      </div>
      <h2>Values:</h2>
      <p>
        <b>Length Values (e.g., px, em, rem):</b> Specifies a fixed size.
        <br />
        <b>Percentage (%):</b> Relative to the width of the containing element.
        <br />
        <b>Auto:</b> The browser calculates the margin.
        <br />
        <b>Shorthand Margin:</b>
        <ul>
          {" "}
          <li>When a single value is provided, it applies to all sides.</li>
          <li>
            {" "}
            When two values are provided, they represent top/bottom and
            left/right.
          </li>
          <li>
            When three values are provided, they represent top, left/right, and
            bottom.
          </li>
          <li>
            When four values are provided, they represent top, right, bottom,
            and left.
          </li>
        </ul>
      </p>
      <h3>Example</h3>
      <div className="listitems">
        <pre>{ValueCodeSnippetRawHTML}</pre>
      </div>
      <h4>Resetting Margin:</h4>
      <p>To remove default margins, you can use the following:</p>
      <div className="listitems">
        <pre>{BodyCodeSnippetRawHTML}</pre>
      </div>
      <p>
        This sets the margin of the body element to zero, removing any default
        margins applied by browsers.
      </p>
      <h2>CSS Box Sizing:</h2>
      <p>
        The box-sizing property in CSS is used to control the sizing behavior of
        the box model. It determines how the total width and height of an
        element are calculated, taking into account the element's content,
        padding, and border.
      </p>
      <h3>Syntax:</h3>
      <div className="listitems">
        <pre>{BoxCodeSnippetRawHTML}</pre>
      </div>
      <h4>content-box:</h4>
      <p>
        The default value. The width and height properties include only the
        content of the element, not the padding, border, or margin.
      </p>
      <div className="listitems">
        <pre>{ContentBoxCodeSnippetRawHTML}</pre>
      </div>
      <h4>border-box:</h4>
      <p>
        The width and height properties include the content, padding, and
        border, but not the margin.
      </p>
      <div className="listitems">
        <pre>{BorderBoxCodeSnippetRawHTML}</pre>
      </div>
      <h3>Example:</h3>
      <div className="listitems">
        <pre>{BorderBoxSizeCodeSnippetRawHTML}</pre>
      </div>
      <p>
        In this example, the box-sizing: border-box; property is applied
        globally to all elements using the * selector, and it's also applied to
        a specific element with the class .box. This ensures that the width and
        height of the .box element include the padding and border.
      </p>
      <h4>Box Sizing in Reset Styles:</h4>
      <p>
        When using a CSS reset or normalization, setting box-sizing: border-box;
        on all elements is a common practice to ensure consistent sizing
        behavior across different browsers.
      </p>
      <div className="listitems">
        <pre>{BorderBoxSizeResetCodeSnippetRawHTML}</pre>
      </div>
      <h2>CSS Box Model :</h2>
      <p>
        Box Model is a fundamental concept that describes the layout of elements
        on a web page. It defines how each element is represented as a
        rectangular box with properties such as content, padding, border, and
        margin. <br />
        The box model consists of the following components: <br />
        <h4> Content:</h4>
        <ul>
          <li>
            The actual content of the box, where text, images, and other media
            are displayed.
          </li>{" "}
          <li>Controlled by the width and height properties.</li>
        </ul>
        <h4> Padding:</h4>
        <ul>
          <li>The transparent area around the content, inside the border.</li>
          <li>
            Controlled by the padding property and its individual properties
            (padding-top, padding-right, padding-bottom, padding-left).
          </li>
        </ul>
        <h4> Border: </h4>
        <ul>
          <li>The border surrounding the content and padding. </li>
          <li>
            Controlled by the border property and its individual properties
            (border-width, border-style, border-color), as well as specific
            properties for each side (border-top, border-right, border-bottom,
            border-left).
          </li>
        </ul>
        <h4> Margin: </h4>
        <ul>
         
          <li>
            The transparent area outside the border, creating space between
            adjacent boxes.
          </li>
          <li>
            Controlled by the margin property and its individual properties
            (margin-top, margin-right, margin-bottom, margin-left).
          </li>
        </ul>
      </p>
      <br />
      <div className="listitems">
        <pre>{BoxPDMCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module05;
