import React from "react";
const LinearCodeSnippetRawHTML = `
/* Linear Gradient Syntax */
linear-gradient([direction], color-stop1, color-stop2, ...);
`;
const LinearExampleCodeSnippetRawHTML = `
background: linear-gradient(to right, #ffcc00, #ff6666);
`;
const RadialCodeSnippetRawHTML = `
/* Radial Gradient Syntax */
radial-gradient([shape] [size] at position, color-stop1, color-stop2, ...);
`;
const RadialExampleCodeSnippetRawHTML = `
/* Radial Gradient Example */
background: radial-gradient(circle at 50% 50%, #3399ff, #3366ff);`;
const GradientExampleCodeSnippetRawHTML = `
/* Gradient with Color Stops */
background: linear-gradient(to right, #ffcc00 0%, #ff6666 50%, #ffcc00 100%);
`;
const BrowserCodeSnippetRawHTML = `
/* Vendor Prefixed Linear Gradient */
background: -webkit-linear-gradient(left, #ffcc00, #ff6666);
background: linear-gradient(to right, #ffcc00, #ff6666);`;
const KeyframeCodeSnippetRawHTML = `
@keyframes animationName {
  0% {
    /* styles at the start of the animation */
  }
  50% {
    /* styles in the middle of the animation */
  }
  100% {
    /* styles at the end of the animation */
  }
}
/* Applying the Animation */
element {
  animation: animationName duration timing-function delay iteration-count direction fill-mode play-state;
}
`;
const KeyframeExampleCodeSnippetRawHTML = `
/* Keyframe Animation Definition */
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Applying the Animation */
.slide-in-element {
  animation: slide-in 1s ease-in-out 0s 1 normal forwards running;
}
}
`;
const AnimationCodeSnippetRawHTML = `
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.element-to-bounce {
  animation: bounce 1s infinite;
}
`;
const HoverExampleCodeSnippetRawHTML = `
/* Applying Transition to Hover Effect */
.button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

/* Changing Background Color on Hover */
.button:hover {
  background-color: #2980b9;
}
`;
const TransitionsCodeSnippetRawHTML = `
/* Applying Transitions to Multiple Properties */
.box {
  width: 100px;
  height: 100px;
  background-color: #f39c12;
  transition: width 0.3s ease-in-out, height 0.5s linear 0.2s, background-color 0.4s ease;
}

/* Changing Properties on Hover */
.box:hover {
  width: 150px;
  height: 150px;
  background-color: #e74c3c;
}
`;
const ShorthandTransitionCodeSnippetRawHTML = `
.element {
  transition: property1 duration1 timing-function1 delay1, property2 duration2 timing-function2 delay2, ...;
}
`;
const Module10: React.FC = () => {
  return (
    <div>
      <h2>CSS Gradients:</h2>
      <p>
        Gradients are used to create smooth transitions between multiple colors.
        Gradients can be applied to backgrounds, borders, and other style
        properties. <br />
        There are two types of gradients in CSS:
        <ul>
          <li>linear gradients</li>
          <li>radial gradients</li>
        </ul>
      </p>
      <h3>Linear Gradients:</h3>
      <p>
        A linear gradient is defined by specifying two or more color stops along
        a straight line.
      </p>
      <h4>Syntax:</h4>
      <div className="listitems">
        <pre>{LinearCodeSnippetRawHTML}</pre>
      </div>
      <h4>Example:</h4>
      <div className="listitems">
        <pre>{LinearExampleCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>In the example above:</b>
        <br />
        to right indicates the direction of the gradient (left to right).
        <br />
        #ffcc00 is the color at the start.
        <br />
        #ff6666 is the color at the end.
      </p>
      <h3>Radial Gradients:</h3>
      <p>
        A radial gradient is defined by specifying two or more color stops along
        a radius.
      </p>
      <h4>Syntax:</h4>
      <div className="listitems">
        <pre>{RadialCodeSnippetRawHTML}</pre>
      </div>
      <h4>Example:</h4>
      <div className="listitems">
        <pre>{RadialExampleCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>In the example above:</b>
        <br />
        circle is the shape of the gradient.
        <br />
        at 50% 50% specifies the position at the center of the element.
        <br />
        #3399ff is the color at the center.
        <br />
        #3366ff is the color at the outer edge.
      </p>
      <h3>Gradient Color Stops:</h3>
      <p>
        A gradient can have multiple color stops, and you can control the
        position and color of each stop.
      </p>
      <div className="listitems">
        <pre>{GradientExampleCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>In the example above:</b>
        <br />
        0%, 50%, and 100% represent the positions of the color stops.
      </p>
      <h3>Browser Support:</h3>
      <p>
        Gradients are widely supported in modern browsers. However, it's
        essential to check for compatibility, especially when using advanced
        features.
      </p>
      <h3>Browser Prefixes:</h3>
      <p>
        In older versions of browsers, you might need to use vendor prefixes for
        gradient properties.
      </p>
      <div className="listitems">
        <pre>{BrowserCodeSnippetRawHTML}</pre>
      </div>
      <h3> CSS Animation:</h3>
      <p>
        CSS animations allow you to create dynamic and interactive effects on
        elements, bringing web pages to life. Animations can be used to change
        the appearance of an element over time, creating smooth transitions and
        engaging user experiences.
      </p>
      <h3> Keyframe Animations:</h3>
      <p>
        Keyframe animations are a powerful feature in CSS that enable you to
        define a set of styles at specific points in the animation. Keyframes
        specify how the element should look at each frame of the animation.
      </p>
      <h4>Syntax:</h4>
      <div className="listitems">
        <pre>{KeyframeCodeSnippetRawHTML}</pre>
      </div>
      <h4>Example:</h4>
      <div className="listitems">
        <pre>{KeyframeExampleCodeSnippetRawHTML}</pre>
      </div>
      <p>
        <b>slide-in</b> is the name of the animation.
        <br />
        <b>translateX(-100%)</b> is the starting position.
        <br />
        <b>translateX(0)</b> is the ending position.
        <br />
        <b>1s</b> is the duration of the animation.
        <br />
        <b>ease-in-out</b> is the timing function.
        <br />
        <b>0s</b> is the delay before the animation starts.
        <br />
        <b>1</b> is the number of iterations.
        <br />
        <b>forwards</b> means the element retains the styles of the final keyframe.
        <br />
        <b>running</b> means the animation is active.
      </p>
      <h3>Animation Properties:</h3>
      <p>
        <b>animation-name:</b> Specifies the name of the keyframe animation. <br />
        <b>animation-duration:</b> Specifies the duration of the animation. <br />
        <b>animation-timing-function:</b> Specifies the timing function for the
        animation (e.g., ease, linear, ease-in-out). <br />
        <b>animation-delay:</b> Specifies the delay before the animation starts. <br />
        <b>animation-iteration-count:</b> Specifies the number of times the animation
        should run. <br />
        <b>animation-direction:</b> Specifies whether the animation should play
        forward, backward, or alternate. <br />
        <b>animation-fill-mode:</b> Specifies how styles are applied before and after
        the animation. <br />
        <b>animation-play-state:</b> Specifies whether the animation is running or
        paused.
      </p>
      <h3>Browser Support:</h3>
      <p>
        CSS animations are widely supported in modern browsers. Ensure
        compatibility by checking for browser support and using vendor prefixes
        if needed.
      </p>
      <h4>Example Animation:</h4>
      <p>Here's a simple example of a bounce animation:</p>
      <div className="listitems">
        <pre>{AnimationCodeSnippetRawHTML}</pre>
      </div>
      <p>
        This animation causes the element to bounce up and down continuously.
      </p>
      <h2> CSS Transition:</h2>
      <p>
        Transitions provide a way to smoothly animate changes in property values
        over a specified duration. Unlike keyframe animations, transitions apply
        the animation between two states without explicitly defining every
        intermediate step. Transitions are often used for hover effects,
        fade-ins, and other subtle animations.
      </p>
      <h3>Transition Properties:</h3>
      <p>
        The transition property is used to define the transition effect and its
        properties. It has the following syntax:
      </p>
      <p>
        <b>transition:</b> property duration timing-function delay;
        <br />
        <b>property:</b> Specifies the CSS property to which the transition should be
        applied (e.g., opacity, color, transform).
        <br />
        <b>duration:</b> Specifies the duration of the transition in seconds or
        milliseconds.
        <br />
        <b>timing-function:</b> Specifies the timing function to control the speed of
        the transition (e.g., ease, linear, ease-in-out).
        <br />
        <b>delay:</b> Specifies the delay before the transition starts.
      </p>
      <h4>Example:</h4>
      <div className="listitems">
        <pre>{HoverExampleCodeSnippetRawHTML}</pre>
      </div>
      <p>
        In the example above, the background color of the button smoothly
        transitions from #3498db to #2980b9 over a duration of 0.3s with an
        ease-in-out timing function.
      </p>
      <h3>Multiple Property Transitions:</h3>
      <p>
        You can apply transitions to multiple properties by separating them with
        commas. Each property can have its own duration, timing function, and
        delay.
      </p>
      <div className="listitems">
        <pre>{TransitionsCodeSnippetRawHTML}</pre>
      </div>
      <h2>CSS Transition Shorthand:</h2>
      <p>
        To apply the same transition effect to multiple properties, you can use
        the shorthand version:
      </p>
      <div className="listitems">
        <pre>{ShorthandTransitionCodeSnippetRawHTML}</pre>
      </div>
      <h2>Browser Support:</h2>
      <p>
        CSS transitions are well-supported in modern browsers. Ensure
        compatibility by checking for browser support and using vendor prefixes
        if needed.
      </p>
      <h2>Benefits of CSS Transitions:</h2>
      <p>
        <b>Simplicity:</b> Transitions are simpler to implement compared to keyframe
        animations, making them suitable for simple effects.
        <br />
        <b>Ease of Use:</b> You can apply transitions using a single property, making
        the code concise.
        <br />
        <b>Performance:</b> Transitions are often hardware-accelerated, providing
        smoother animations.
        <br />
        <b>Limitations:</b>
        <br />
        While transitions are suitable for simple animations, more complex
        scenarios may require keyframe animations for greater control over each
        animation step.
        <br />
      </p>
    </div>
  );
};

export default Module10;
