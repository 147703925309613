import React from "react";

const APIArchitectureStyles: React.FC = () => {
  const tableBorderStyle: number = 1;

  return (
    <div>
      <h1>API Architecture Styles</h1>
      <img
          src="/api_architecture_styles.png"
          alt="Api Architecture Styles"
          style={{ width: "800px"}}
        />
      {/* SOAP Section */}
      <h2>SOAP (Simple Object Access Protocol):</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left" }}>
              SOAP
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              SOAP is a protocol for exchanging structured information in web
              services. It uses XML for message formatting and typically relies
              on HTTP or SMTP as the transport protocol.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use Cases</strong>
            </td>
            <td>
              Often used in enterprise-level applications, especially in
              scenarios where a strict contract and protocol are necessary.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Pros</strong>
            </td>
            <td>
              <ol>
                <li>Strong standardization and protocol.</li>
                <li>Built-in error handling.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Cons</strong>
            </td>
            <td>
              <ol>
                <li>Verbosity of XML can lead to larger message sizes.</li>
                <li>
                  Can be more complex and harder to implement compared to other
                  styles.
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>

      {/* RESTful Section */}
      <h2>RESTful (Representational State Transfer):</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left" }}>
              RESTful
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              REST is an architectural style that uses a set of constraints and
              principles for building web services. It typically uses standard
              HTTP methods (GET, POST, PUT, DELETE) and is stateless.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use Cases</strong>
            </td>
            <td>
              Widely used in web and mobile applications where simplicity,
              scalability, and statelessness are crucial.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Pros</strong>
            </td>
            <td>
              <ol>
                <li>Simple and easy to understand.</li>
                <li>Stateless communication makes it scalable and easy to cache.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Cons</strong>
            </td>
            <td>
              <ol>
                <li>Lack of standardization in some aspects.</li>
                <li>
                  Over-reliance on HTTP can limit functionality in certain scenarios.
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>

      {/* GraphQL Section */}
      <h2>GraphQL:</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left" }}>
              GraphQL
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              GraphQL is a query language for APIs that allows clients to request
              only the data they need. It provides a more efficient, flexible,
              and powerful alternative to REST.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use Cases</strong>
            </td>
            <td>
              Suitable for scenarios where clients have varying data
              requirements, such as in complex and dynamic applications.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Pros</strong>
            </td>
            <td>
              <ol>
                <li>Clients can request only the data they need, reducing over-fetching.</li>
                <li>Single endpoint for multiple operations.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Cons</strong>
            </td>
            <td>
              <ol>
                <li>Requires a learning curve for both server and client implementations.</li>
                <li>More complexity in terms of security due to expressive queries.</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>

      {/* gRPC Section */}
      <h2>gRPC (Remote Procedure Call):</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left" }}>
              gRPC
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              gRPC is a high-performance RPC (Remote Procedure Call) framework
              developed by Google. It uses Protocol Buffers as the interface
              definition language.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use Cases</strong>
            </td>
            <td>
              Suitable for scenarios requiring high-performance communication
              between microservices and where protocol buffers are preferred.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Pros</strong>
            </td>
            <td>
              <ol>
                <li>Strongly typed interfaces with Protocol Buffers.</li>
                <li>Efficient binary serialization and deserialization.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Cons</strong>
            </td>
            <td>
              <ol>
                <li>May be more challenging to debug and troubleshoot compared to REST.</li>
                <li>Not as human-readable as JSON, which can complicate debugging.</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>

      {/* WebSocket Section */}
      <h2>WebSocket:</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left" }}>
              WebSocket
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              WebSocket provides a full-duplex communication channel over a
              single, long-lived connection. It is suitable for real-time
              applications where low latency is crucial.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use Cases</strong>
            </td>
            <td>
              Real-time applications such as chat applications, live streaming,
              or online gaming.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Pros</strong>
            </td>
            <td>
              <ol>
                <li>Low latency and real-time communication.</li>
                <li>Efficient for scenarios requiring frequent updates.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Cons</strong>
            </td>
            <td>
              <ol>
                <li>May not be suitable for scenarios that don't require real-time communication.</li>
                <li>More complex to implement and maintain than traditional HTTP.</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Webhook Section */}
      <h2>Webhook:</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left" }}>
              Webhook
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              Webhooks are HTTP callbacks triggered by specific events. Instead
              of constantly polling for updates, a server sends data to a
              predefined URL when an event occurs.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use Cases</strong>
            </td>
            <td>
              Ideal for event-driven architectures where real-time reactions to
              specific events are necessary.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Pros</strong>
            </td>
            <td>
              <ol>
                <li>Efficient as it eliminates the need for continuous polling.</li>
                <li>Enables real-time response to events.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Cons</strong>
            </td>
            <td>
              <ol>
                <li>Requires a publicly accessible endpoint for receiving callbacks.</li>
                <li>
                  Error handling can be challenging if the receiving server is temporarily unreachable.
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default APIArchitectureStyles;
