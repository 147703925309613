import React from "react";

const CSSLearningPlan: React.FC = () => {
  const tableBorderStyle: number = 1;

  return (
    <div>
      <h2>CSS Learning Plan</h2>
      <table border={tableBorderStyle}>
        <thead>
          <tr>
            <th style={{ textAlign: "left" ,width: "200px"}}>Module</th>
            <th style={{ textAlign: "left" ,width: "400px"}}>Plan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Module-01</td>
            <td>
              <ul>
                <li>About CSS</li>
                <li>Syntax</li>
                <li>Importing CSS to HTML</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-02</td>
            <td>
              <ul>
                <li>background-attachment</li>
                <li>background-position</li>
                <li>background-image</li>
                <li>background-color</li>

                <li>background-repeat</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-03</td>
            <td>
              <ul>
                <li>CSS Fonts</li>
                <li>CSS Colors</li>
                <li>CSS Selector</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-04</td>
            <td>
              <ul>
                <li>CSS Units</li>
                <li>CSS Positions</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-05</td>
            <td>
              <ul>
                <li>CSS Border</li>
                <li>CSS Pading</li>
                <li>CSS Size</li>
                <li>CSS Margin</li>
                <li>CSS Box Sizing</li>
                <li>CSS Box Model</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-06</td>
            <td>
              <ul>
                <li>CSS</li>
                <li>CSS Tablestyle</li>
                <li>CSS Layouts</li>
                <li>Display Property</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-07</td>
            <td>
              <ul>
                <li>CSS Opacity</li>
                <li>CSS Grid</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-08</td>
            <td>
              <ul>
                <li>CSS Overflow</li>
                <li>CSS Flexbox</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-09</td>
            <td>
              <ul>
                <li>CSS Pseudo Element</li>
                <li>CSS Pseudo Classes</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Module-10</td>
            <td>
              <ul>
                <li>CSS Gradients</li>
                <li>CSS Animation</li>
                <li>CSS Transition</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CSSLearningPlan;
