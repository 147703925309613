import React from "react";

const dockerComposeFile = `
version: '3'

services:
  keycloak:
    image: quay.io/keycloak/keycloak:latest
    environment:
      DB_VENDOR: h2
      KEYCLOAK_ADMIN: admin
      KEYCLOAK_ADMIN_PASSWORD: admin
    ports:
      - "28080:8080"
    command:
      - start-dev

`;

const dockerComposeCommand = `
docker-compose up
`;

const dependencies = `
npm install express body-parser axios
`;

const serverCode = `
const express = require('express');
const bodyParser = require('body-parser');
const axios = require('axios');

const app = express();
const port = 3000;

app.use(bodyParser.json());
app.use(bodyParser.urlencoded({ extended: true }));

const keycloakUrl = 'http://localhost:28080';
const realm = 'nipuna';
const clientId = 'nipuna-client';
const clientSecret = '5PBM5g8qTs1DEqm3SHnSBwzQHp1KsMqN';
const adminUsername = 'admin'; // Keycloak admin username
const adminPassword = 'admin'; // Keycloak admin password

// Function to get admin access token
const getAdminToken = async () => {
  const response = await axios.post(
    'dollarSymbol{keycloakUrl}/realms/master/protocol/openid-connect/token',
    new URLSearchParams({
      client_id: 'admin-cli',
      username: adminUsername,
      password: adminPassword,
      grant_type: 'password',
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  return response.data.access_token;
};

// Endpoint to create a new user
app.post('/auth/register', async (req, res) => {
  const { username, email, password, firstName, lastName } = req.body;

  try {
    const adminToken = await getAdminToken();
    await axios.post(
      'dollarSymbol{keycloakUrl}/admin/realms/dollarSymbol{realm}/users',
      {
        username,
        email,
        firstName,
        lastName,
        enabled: true,
        emailVerified: true,
        credentials: [
          {
            type: 'password',
            value: password,
            temporary: false,
          },
        ],
      },
      {
        headers: {
          Authorization: 'Bearer dollarSymbol{adminToken}',
          'Content-Type': 'application/json',
        },
      }
    );
    res.json({ message: 'User created successfully' });
  } catch (error) {
    res.status(500).json({ error: 'Failed to create user', details: error.response.data });
  }
});

// Existing login endpoint
app.post('/auth/login', async (req, res) => {
  const { username, password } = req.body;

  try {
    const response = await axios.post(
      'dollarSymbol{keycloakUrl}/realms/dollarSymbol{realm}/protocol/openid-connect/token',
      new URLSearchParams({
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: 'password',
        username,
        password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    const token = response.data.access_token;
    res.json({ token });
  } catch (error) {
    res.status(500).json({ error: 'Failed to obtain token', details: error.response.data });
  }
});

app.listen(port, () => {
  console.log('Server running on http://localhost:dollarSymbol{port}');
});

`;

const startServer = `
node server.js
`;

const registerRequest = `
{
  "username": "newuser",
  "email": "newuser@example.com",
  "password": "password",
  "firstName": "New",
  "lastName": "User"
}
`;
const loginRequest = `
{
  "username": "newuser@example.com",
  "password": "password"
}

`;

const KeyCloak: React.FC = () => {
  return (
    <div>
      <h2>KeyCloak</h2>
      <p>
        Keycloak is an open-source Identity and Access Management solution aimed
        at modern applications and services. It provides features such as Single
        Sign-On (SSO), Identity Brokering, and User Federation.
      </p>
      <h3>Running Keycloak with Docker</h3>
      <h4>1: Create docker-compose.yml file</h4>
      <p>
        Create a <strong>docker-compose.yml</strong> file with the following
        content:
      </p>
      <div className="listitems">
        <pre>{dockerComposeFile}</pre>
      </div>
      <h4>2: Start Keycloak</h4>
      <p>
        Run the following command in the directory containing your
        docker-compose.yml file:
      </p>
      <div className="listitems">
        <pre>{dockerComposeCommand}</pre>
      </div>
      <p>This command will start Keycloak and expose it on port <strong>28080</strong>.</p>
      <h3>Configuring Keycloak</h3>
      <h4>Step 1: Access Keycloak Administration Console</h4>
      <p>
        Open your browser and go to{" "}
        <a href="http://localhost:28080" target="_blank">
          http://localhost:28080
        </a>{" "}
        . Log in using the credentials specified in your <strong>docker-compose.yml</strong> file
        (<strong>admin/admin</strong>).
      </p>
      <h4>Step 2: Create a Realm</h4>
      <ul>
        <li>
          Click on the dropdown in the top-left corner (default realm is master)
          and select <strong>Add realm</strong>.
        </li>
        <li>Name your realm for e.g, <strong>nipuna</strong> and click <strong>Create</strong>.</li>
      </ul>
      <h4>Step 3: Create a Client</h4>
      <ul>
        <li>Go to the <strong>Clients</strong> section and click <strong>Create</strong>.</li>
        <li>Set <strong>Client ID</strong> to some unique name for e.g, <strong>nipuna-client</strong>.</li>
        <li>Select <strong>OpenID Connect</strong> as the <strong>client protocol</strong>.</li>
        <li>
          Set the <strong>Root URL</strong> to <strong>http://localhost:3000</strong> (or your application URL).
        </li>
        <li>Save the client.</li>
      </ul>
      <h4>Step 4: Configure the Client</h4>
      <ul>
        <li>Set <strong>Access Type</strong> to <strong>confidential</strong>.</li>
        <li>
          Copy the generated Secret under the <strong>Credentials</strong> tab for later use.
        </li>
      </ul>
      <h3>Express.js Endpoints for User Creation and Token Generation</h3>
      <h4>Step 1: Create Express.js Project</h4>
      <p>Create a new directory for your project.</p>
      <p>
        Initialize a new Node.js project using <strong>npm init -y</strong>
      </p>
      <p>Install necessary dependencies:</p>
      <div className="listitems">
        <pre>{dependencies}</pre>
      </div>
      <p>Create a file named server.js and add the following code:</p>
      <div className="listitems">
        <pre>{serverCode}</pre>
      </div>
      <p>
        <strong>Note:</strong> dollarSymbol text in above code should be
        replaced with dollar symbol
      </p>
      <h4>Step 2: Start the Express.js Server</h4>
      <p>Run the following command to start your server:</p>
      <div className="listitems">
        <pre>{startServer}</pre>
      </div>
      <p>
        Your Express.js server will now be running on{" "}
        <strong>http://localhost:3000</strong>.
      </p>
      <h3> Testing the Endpoints with Postman</h3>
      <h4>1. Register a new user:</h4>
      <ul>
        <li>Method: POST</li>
        <li>URL: http://localhost:3000/auth/register</li>
        <li>Body (JSON):</li>
      </ul>
      <div className="listitems">
        <pre>{registerRequest}</pre>
      </div>
      <h4>2. Login with the created user:</h4>
      <ul>
        <li>Method: POST</li>
        <li>URL: http://localhost:3000/auth/register</li>
        <li>Body (JSON):</li>
      </ul>
      <div className="listitems">
        <pre>{loginRequest}</pre>
      </div>
      <p>
        We have set up Keycloak using Docker, configured realms and clients, and
        created Express.js endpoints to register users and generate tokens. This
        setup provides a robust authentication mechanism for your application.
      </p>
    </div>
  );
};

export default KeyCloak;
