import React from "react";
const logLevels = `
{
  error:0,
  warn:1,
  info:2,
  http:3,
  verbose:4,
  debug:5,
  silly:6
}
`;

const initialiseNodeProject = `
npm init -y
`;
const installExpress = `
npm install express
`;
const installWinston = `
npm install winston
`;

const createProjectFiles = `
touch index.js logger.js
`;

const loggerJsFile = `
import { createLogger, transports, format } from 'winston';
const { timestamp, combine, printf, errors } = format;

const logger = createLogger({
    level: 'silly', 
    format: combine(
        format.colorize(),
        timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }),
        errors({ stack: true }),
        printf(({ level, message, timestamp }) => {
            return 'dollarSign{timestamp} [dollarSign{level.toUpperCase()}]: dollarSign{message}';
        })
    ),
    transports: [
        new transports.Console({ 
            format: format.combine(
                format.timestamp(),
                format.json()
            ),
            handleExceptions: true,
            json: false
        })
    ]
});

export default logger;

`;

const indexJsFile = `
import express from 'express';
import logger from './logger.js';

const app = express();
const port = process.env.PORT || 4000; // Different port for console logging project

app.get('/console-logging', (req, res) => {
    logger.error('Error message'); 
    logger.warn('Warning message'); 
    logger.info('Info message'); 
    logger.debug('Debug message');
    logger.verbose('Verbose message');
    logger.silly('Silly message');
    res.send('Console logging');
});

app.listen(port, () => {
    logger.info('Server is running on port dollarSign{port}');
});


`;

const runCommand = `
node index.js
`;

const WinstonConsoleBasedLogging: React.FC = () => {
  return (
    <div>
      <h2>Winston Console Based Logging</h2>
      <p>
        Winston is a versatile logging library for Node.js applications.It
        allows to write to different sources and you'll have fine-grained
        control over where you write and the principle there is actually quite
        simple. Console-based logging involves writing log messages to the
        console, making it visible during development, debugging, or when
        running the application in a terminal. We can display log messages
        directly in your terminal or command prompt window.Here's how Winston
        handles console-based logging:
      </p>
      <p>
        1. <strong>Logger Setup:</strong> Set up a logger with Winston to handle logging messages.
      </p>
      <p>
        2. <strong>Specify Console Transport:</strong>  Tell Winston to use the console as a
        transport to show log messages.
      </p>
      <p>
        <p>
          3. <strong> ormat Logs:</strong>  Configure how log messages will look in the console,
          including timestamps and colors.
        </p>
        <p>
          4. <strong> Use the Logger:</strong>  With the logger set up, we can log messages to the
          console for immediate viewing.
        </p>
        Winston supports six log levels by default.Each level is given an
        integer priority with the most severe being the lowest number and the
        least one being the highest. Below are different log levels:
      </p>
      <ul className="listitems">
        <pre>{logLevels}</pre>
      </ul>
      <p>
        The idea is that we can take logger and you can set log level globally
        and then it will only log the log level and the ones below it. For
        example,if we have info logger,it will write out all messages for
        log.info,log.warn and log.error.But if you have log.debug,its not going
        to log it out because the current level is set to info or below.
      </p>
      <h3>Setting Up Your Project: </h3>
      <p>
        1. Install Node.js and npm: If you haven't already, download and install
        Node.js from nodejs.org. npm (Node Package Manager) comes bundled with
        Node.js.
      </p>
      <p>
        2. Create a new directory for your project.
      </p>
      <p>
        3. Navigate to your Project Directory.
      </p>
      <p>
        4. Initialize a Node.js project: Run npm init and follow the prompts to
        create a package.json file.
      </p>
      <ul className="listitems">
        <pre>{initialiseNodeProject}</pre>
      </ul>
      <p>
        5. Install Express.js as a dependency for your
        project.
      </p>
      <ul className="listitems">
        <pre>{installExpress}</pre>
      </ul>
      <p>6. Install Winston for logging purposes.</p>
      <ul className="listitems">
        <pre>{installWinston}</pre>
      </ul>
      <p>7. Create Project Files</p>
      <ul className="listitems">
        <pre>{createProjectFiles}</pre>
      </ul>

      <p>
        8. Configure Console Logging in 'logger.js' and this file will look
        like:
      </p>
      <ul className="listitems">
        <pre>{loggerJsFile}</pre>
      </ul>
      <p>
      <strong>Note:</strong> dollarSymbol text in above code should be replaced with dollar
        symbol
      </p>

      <p>
        9. Create Express.js Server with Console Logging.Edit index.js as below:
      </p>
      <ul className="listitems">
        <pre>{indexJsFile}</pre>
      </ul>
      <p>
      <strong>Note:</strong> dollarSymbol text in above code should be replaced with dollar
        symbol
      </p>

      <p>10. Run the Console-based Logging Project using command:</p>
      <ul className="listitems">
        <pre>{runCommand}</pre>
      </ul>

      <p>
        11. Access the endpoint: 'http://localhost:4000/console-logging' and we
        can see logs in the logs folder.{" "}
      </p>
    </div>
  );
};

export default WinstonConsoleBasedLogging;
