import React from "react";
import '../../App.css';

const connectionDetailsRawHTML = `
spring:
  data:
    mongodb:
      host: localhost
      port: 27017
      database: test_database
`;

const entityRawHTML = `
@Document(collection = "user")
public class UserDocument {
    @Id
    private String id;
    private String name;
    private int age;
    private String city;
}
`;

const insertRawHTML = `
public UserDocument addUser(UserDocument userDocument) {
  return userRepository.save(userDocument);
}
`;

const selectRawHTMLWithPagination = `
public List<User> getAllUsers(Pagination pagination) {
  List<User> usersList = new ArrayList<>();
  PageRequest pageable = PageRequest.of(pagination.getCurrentPage(), pagination.getPageSize());
  Page<UserDocument> usersPage = userRepository.findAll(pageable);
  List<UserDocument> users = usersPage.stream().toList();
  users.forEach(user->{
      User user1 = User.builder()
              .id(user.getId())
              .city(user.getCity())
              .age(user.getAge())
              .name(user.getName())
              .build();
      usersList.add(user1);
  });
  return usersList;
}
`;

const filterRepoRawHTML = `
public interface UserRepository extends MongoRepository<UserDocument, String> {
  List<UserDocument> findByCity(String city);

}
`;

const filterServiceRawHTML = `
public List<User> getUsersByCity(String city) {
  List<User> usersList = new ArrayList<>();
  List<UserDocument> users = userRepository.findByCity(city);
  users.forEach(user->{
      User user1 = User.builder()
              .id(user.getId())
              .city(user.getCity())
              .age(user.getAge())
              .name(user.getName())
              .build();
      usersList.add(user1);
  });
  return usersList;
}
`;

const updateRawHTML = `
public void updateCity(String city, String newCity) {
  Query query = new Query(Criteria.where("city").is(city));
  Update update = new Update().set("city", newCity);

  mongoTemplate.updateMulti(query, update, UserDocument.class);
}
`;

const deleteRawHTML = `
public void deleteUserByCityAndAge(String city, int age) {
  Query query = new Query(Criteria.where("city").is(city).and("age").is(age));
  mongoTemplate.remove(query, UserDocument.class);
}
`;

const filterUsersWithMissingAgeRawHTML = `
public List<User> getUsersWithMissingAge() {
  Query query = new Query(Criteria.where("age").exists(false));
  List<UserDocument> users = mongoTemplate.find(query, UserDocument.class);

  return users.stream()
          .map(UserDocument -> User.builder()
                  .id(UserDocument.getId())
                  .city(UserDocument.getCity())
                  .age(UserDocument.getAge())
                  .name(UserDocument.getName())
                  .build())
          .collect(Collectors.toList());
}
`;

const MongoDB: React.FC = () => {
  return (
    <div>
      <h2>MongoDB Introduction</h2>
      <p>
        MongoDB is a NoSQL database that provides high performance, high
        availability, and easy scalability. In MongoDB, instead of tables, you
        work with collections. Collections are groups of MongoDB documents, and
        documents are JSON-like BSON data structures. It stores data in
        flexible, JSON-like documents, allowing you to model your data in a way
        that fits your application's needs.
      </p>
      <h2>MongoDB Atlas Setup Guide</h2>
      <p>
        MongoDB Atlas is a fully-managed cloud database service provided by
        MongoDB. It allows you to deploy, operate, and scale MongoDB databases
        in the cloud, eliminating the need for manual infrastructure management.
        Here's a step-by-step guide to using MongoDB Atlas:
      </p>
      <h3>1. Sign Up or Log In</h3>
      <p>
        Go to the MongoDB Atlas website:{" "}
        <a href="https://www.mongodb.com/cloud/atlas" target="_blank" rel="noreferrer">
          MongoDB Atlas
        </a>
        <br />
        If you don't have an account, click on "Try Free" to create a new
        account. If you already have an account, click on "Sign In."
      </p>
      <h3>2. Create a New Cluster</h3>
      <p>
        After logging in, you will be directed to the Atlas dashboard.
        <br />
        Click on the "Build a Cluster" button to create a new MongoDB cluster.
      </p>
      <h3>3. Configure Cluster Settings</h3>
      <p>
        Configure your cluster settings, including the cluster name, cluster
        tier, and additional settings such as backups and monitoring.
      </p>
      <div className="listitems">
        <img
          src="/mongo_db_cluster_settings.png"
          alt="MongoDB cluster settings"
          style={{ width: "1000px"}}
        />
      </div>
      <h3>4. Create Database Users</h3>
      <p>
        Go to the "Database Access" tab and click on "Add New Database User."
        <br />
        Create a database user with a username and password. Remember these
        credentials for later use.
      </p>
      <h3>5. Add IP Whitelist Entry</h3>
      <p>
        Go to the "Network Access" tab and click on "Add IP Address."
        <br />
        Add your current IP address to the whitelist to allow connections to
        your cluster.
      </p>
      <h3>6. Review and Deploy</h3>
      <p>
        Review your cluster configuration and click on the "Create Cluster"
        button to deploy your MongoDB Atlas cluster.
      </p>
      <h3>7. Connect to Your Cluster</h3>
      <p>
        Go to the "Clusters" tab and click on the "Connect" button for your
        cluster.
        <br />
        Choose "Connect Your Application" and copy the connection string.
      </p>
      <div className="listitems">
        <img
          src="/mongo_db_connect_to_cluster.png"
          alt="MongoDB connect to cluster"
          style={{ width: "1000px"}}
        />
      </div>
      <h3>8. Create a Database and Collection</h3>
      <p>
        Open your MongoDB shell or MongoDB Compass.
        <br />
        Use the connection string to connect to your MongoDB Atlas cluster.
        <br />
        Select "Create Database" option in Data services to create new database.{" "}
        <br />
        Go to your database created and select "Create Collection" option to add
        new collection.
      </p>
      <div className="listitems">
        <img
          src="/mongo_db_create_database_collection.png"
          alt="MongoDB create database collection"
          style={{ width: "1000px"}}
        />
      </div>
      <h2>MongoDB Template</h2>
      <p>
        The <code>MongoTemplate</code> class from Spring Data MongoDB is a
        central class for MongoDB interaction. It provides a high-level API to
        perform various operations such as querying, updating, and deleting
        documents. Code should include Spring Data MongoDB, which provides a
        template-based approach for interacting with MongoDB.
      </p>
      <h2>Imports and Dependencies</h2>
      <p>
        The code should include the following dependency for MongoDB along with
        Spring Boot:
      </p>
      <code>org.springframework.boot:spring-boot-starter-data-mongodb </code>
      <br />
      Provides the necessary dependencies for Spring Data MongoDB.
      <h2>MongoDB Imports in Code</h2>
      <p>
        In a Spring Boot application, MongoDB operations are facilitated by
        Spring Data MongoDB. Here's an explanation of the key MongoDB-related
        imports in the code:
      </p>
      <ol>
        <li>
          <code>org.springframework.data.mongodb.core.MongoTemplate</code>
          <br /> This class serves as the primary interaction point with
          MongoDB. It provides methods for executing various MongoDB operations,
          including querying, updating, and deleting documents.
        </li>
        <li>
          <code>org.springframework.data.mongodb.core.query.Query</code>
          <br /> The <code>Query</code> class is part of Spring Data MongoDB and
          is used to define queries for MongoDB operations. It allows you to
          specify criteria for selecting documents from a collection.
        </li>
        <li>
          <code>org.springframework.data.mongodb.core.MongoTemplate</code>
          <br />
          This class serves as the primary interaction point with MongoDB. It
          provides methods for executing various MongoDB operations, including
          querying, updating, and deleting documents.
        </li>
        <li>
          <code>org.springframework.data.mongodb.core.query.Query</code>
          <br /> The <code>Query</code> class is part of Spring Data MongoDB and
          is used to define queries for MongoDB operations. It allows you to
          specify criteria for selecting documents from a collection.
        </li>
        <li>
          <code>org.springframework.data.mongodb.core.query.Criteria</code>
          <br /> The <code>Criteria</code> class is used to define conditions or
          criteria within a MongoDB query. It helps in building complex query
          conditions.
        </li>
        <li>
          <code>org.springframework.data.mongodb.core.query.Update</code>
          <br /> The <code>Update</code> class is used to define update
          operations in MongoDB. It allows you to specify how documents should
          be modified or updated.
        </li>
        <li>
          <code>org.springframework.data.annotation.Id</code>
          <br /> The <code>@Id</code> annotation is used to mark a field as the
          primary identifier of a MongoDB document. In the code, it should be
          applied to the <code>id</code> field in the <code>UserDocument</code>{" "}
          class.
        </li>
        <li>
          <code>
            org.springframework.data.mongodb.repository.MongoRepository
          </code>
          <br /> The <code>MongoRepository</code> interface is provided by
          Spring Data MongoDB and extends the base <code>Repository</code>{" "}
          interface. It offers methods for common CRUD operations and additional
          query methods.
        </li>
        <li>
          <code>org.springframework.data.mongodb.repository.Query</code>
          <br /> The <code>@Query</code> annotation is used to define custom
          queries for MongoDB repositories. It allows you to write MongoDB
          queries using the MongoDB query language.
        </li>
        <li>
          <code>org.springframework.data.mongodb.core.mapping.Document</code>
          <br /> The <code>@Document</code> annotation is used to specify the
          name of the MongoDB collection to which a domain object should be
          mapped. It should be applied to the <code>UserDocument</code> class in
          the code.
        </li>
      </ol>
      <h2>Connection Details:</h2>
      <p>
        Connection details for MongoDB in application.yml should be added as
        below:
      </p>
      <ul className="listitems">
        <pre>{connectionDetailsRawHTML}</pre>
      </ul>
      <h2>Sample Codes:</h2>
      <p>
        Below are sample code snippets for each operation (insert, select,
        filter, update, delete) within the Service and repository classes.
      </p>
      <p>For example, we have a user collection with document as below:</p>
      <div className="listitems">
        <pre>{entityRawHTML}</pre>
      </div>

      <h3>Repository code:</h3>
      <div className="listitems">
        <pre>{filterRepoRawHTML}</pre>
      </div>

      <h3>Insert (Add) Data:</h3>
      <div className="listitems">
        <pre>{insertRawHTML}</pre>
      </div>
      <h3>Select (Get) Data With Pagination:</h3>
      <div className="listitems">
        <pre>{selectRawHTMLWithPagination}</pre>
      </div>
      <h3>Filter Data:</h3>
      {/* <p>Service code:</p> */}
      <div className="listitems">
        <pre>{filterServiceRawHTML}</pre>
      </div>
      <h3>Update Data:</h3>
      <p>This code updates the "city" field for all documents with the specified "city" in MongoDB's "UserDocument" collection.</p>
      <div className="listitems">
        <pre>{updateRawHTML}</pre>
      </div>
      <h3>Delete Data:</h3>
      <p>This code deletes users from a MongoDB collection based on specified city and age criteria using Spring Data MongoDB.</p>
      <div className="listitems">
        <pre>{deleteRawHTML}</pre>
      </div>
      <h3>Filter Collections with Missing Attribute Age:</h3>
      <div className="listitems">
        <pre>{filterUsersWithMissingAgeRawHTML}</pre>
      </div>
    </div>
  );
};

export default MongoDB;
