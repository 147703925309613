import React from "react";
const OpacityCodeSnippetRawHTML = `
.transparent-element {
  opacity: 0.5; /* Set the element to 50% transparent */
}

.opaque-element {
  opacity: 1; /* Set the element to fully opaque (not transparent) */
}
`;
const TransparentCodeSnippetRawHTML = `
.transparent-background {
  background-color: rgba(255, 0, 0, 0.5); /* 50% transparent red background */
}
`;
const GridCodeSnippetRawHTML = `
selector {
  box-sizing: content-box | border-box;
}
`;
const GridRCCodeSnippetRawHTML = `
.grid-container {
  display: grid;
  grid-template-rows: 100px 200px; /* Two rows with heights of 100px and 200px */
  grid-template-columns: 1fr 2fr; /* Two columns with a ratio of 1:2 */
}
`;
const GridPropCodeSnippetRawHTML = `
.grid-item {
  grid-row: 1 / 3; /* Place the item in rows 1 to 3 */
  grid-column: 2 / 3; /* Place the item in columns 2 to 3 */
}
`;
const GridGapCodeSnippetRawHTML = `
.grid-container {
  display: grid;
  grid-row-gap: 10px; /* Spacing between rows */
  grid-column-gap: 20px; /* Spacing between columns */
}
`;
const AreaCodeSnippetRawHTML = `
.grid-container {
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar content"
    "footer footer";
}
`;
const AlignCodeSnippetRawHTML = `
/* CSS for a box with padding, border, and margin */
.grid-container {
  display: grid;
  align-items: center;
  justify-items: center;
}
`;
const Module07: React.FC = () => {
  return (
    <div>
      <h2> CSS Opacity:</h2>
      <p>
        The opacity property in CSS is used to set the transparency of an
        element, making it more or less opaque. It takes a value between 0
        (completely transparent) and 1 (completely opaque). Values between 0 and
        1 represent varying levels of transparency.
      </p>
      <h3>Syntax:</h3>
      <div className="listitems">
        <pre>{OpacityCodeSnippetRawHTML}</pre>
      </div>
      <p>
        In the example above, the class .transparent-element makes the
        associated element 50% transparent, while the class .opaque-element
        makes the element fully opaque. <br />
        <br />
        Keep in mind that the opacity property affects the entire element,
        including its content.
        <br /> If you want to make only the background of an element transparent
        while keeping the text fully opaque, you might consider using RGBA
        colors or other techniques.
      </p>
      <h3>Syntax:</h3>
      <div className="listitems">
        <pre>{TransparentCodeSnippetRawHTML}</pre>
      </div>
      <h2> CSS Grid:</h2>
      <p>
        Grid is a layout system that allows to create complex
        two-dimensional layouts with rows and columns.
        <br /> It provides a more intuitive and powerful way to design web
        layouts compared to traditional methods like floats and positioning.
      </p>
      <h3>Syntax:</h3>
      <h4>Define the Grid Container:</h4>
      <p>Use the display: grid; property on the container element.</p>
      <div className="listitems">
        <pre>{GridCodeSnippetRawHTML}</pre>
      </div>
      <h4>Define Rows and Columns:</h4>
      <p>
        grid-template-rows and grid-template-columns properties are used
        to define the size and structure of rows and columns.
      </p>
      <div className="listitems">
        <pre>{GridRCCodeSnippetRawHTML}</pre>
      </div>
      <h4>Place Items on the Grid:</h4>
      <p>
        Use the grid-row and grid-column properties to place items within
        specific rows and columns.
      </p>

      <div className="listitems">
        <pre>{GridPropCodeSnippetRawHTML}</pre>
      </div>
      <h4>Grid Gaps:</h4>
      <p>
        Use grid-row-gap and grid-column-gap properties to define the
        spacing between rows and columns.
      </p>
      <div className="listitems">
        <pre>{GridGapCodeSnippetRawHTML}</pre>
      </div>
      <h4>Responsive Grids:</h4>
      <p>
        CSS Grid is inherently responsive, and can be use media queries to
        change the layout based on screen size.
      </p>
      <div className="listitems">
        <pre>{GridGapCodeSnippetRawHTML}</pre>
      </div>
      <h4> Grid Areas:</h4>
      <p>Define named grid areas to simplify the placement of items.</p>
      <div className="listitems">
        <pre>{AreaCodeSnippetRawHTML}</pre>
      </div>
      <h4>Align and Justify:</h4>
      <p>
        Use properties like align-items, justify-items, align-content, and
        justify-content for aligning and justifying items within the grid.
      </p>
      <div className="listitems">
        <pre>{AlignCodeSnippetRawHTML}</pre>
      </div>
    </div>
  );
};

export default Module07;
