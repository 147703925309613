import React from "react";

const dockerComposeFile = `
version: '3.8'

services:
  localstack:
    image: localstack/localstack:latest
    ports:
      - "4566:4566"  # Edge port
      - "4571:4571"  # Secrets Manager port (deprecated, use 4566)
    environment:
      - SERVICES=secretsmanager
      - DEBUG=1
      - DATA_DIR=/var/lib/localstack
    volumes:
      - "./localstack:/var/lib/localstack"
`;

const dockerComposeUp = `
docker-compose up
`;

const awsConfigure = `
aws configure
`;

const createSecret = `
aws --endpoint-url=http://localhost:4566 secretsmanager create-secret --name MyCredentials --secret-string '{"username": "your_username", "password": "your_password"}'
`;

const listSecrets = `
aws --endpoint-url=http://localhost:4566 secretsmanager list-secrets
`;

const retrieveSecret = `aws --endpoint-url=http://localhost:4566 secretsmanager get-secret-value --secret-id MyCredentials
`;

const npmDependencies = `npm install express body-parser mongodb aws-sdk
`;

const awsConfigFile = `const AWS = require('aws-sdk');

const config = {
  region: 'eu-west-2',
  endpoint: 'http://localhost:4566',
};

AWS.config.update(config);

const secretsManager = new AWS.SecretsManager();

async function getSecret(secretName) {
  const params = {
    SecretId: secretName,
  };

  try {
    const data = await secretsManager.getSecretValue(params).promise();
    if ('SecretString' in data) {
      return JSON.parse(data.SecretString);
    } else {
      const buff = Buffer.from(data.SecretBinary, 'base64');
      return JSON.parse(buff.toString('ascii'));
    }
  } catch (err) {
    console.log('Error retrieving secret:', err);
    throw err;
  }
}

module.exports = getSecret;
`;

const indexjsFile = `import { MongoClient } from 'mongodb';
const express = require('express');
const body = require('body-parser');
const getSecret = require('./awsConfig'); // Import the getSecret function

async function start() {
  try {
    // Get credentials from AWS Secret Manager
    const secretName = 'mongo-db';
    const secret = await getSecret(secretName); // Retrieve the secret
    const uri = 'mongodb://dollarSymbol{encodeURIComponent(secret.username)}:dollarSymbol{encodeURIComponent(secret.password)}@localhost:27017/admin';

    console.log('Connecting to MongoDB with URI: dollarSymbol{uri}');

    // Connect to MongoDB
    const mongo = new MongoClient(uri);
    await mongo.connect();

    const app = express();
    app.db = mongo.db();

    // Body parser middleware
    app.use(body.json({ limit: '5mb' }));

    // Routes
    app.use('/customers', require('./routes/customers'));

    // Endpoint to list MongoDB collections (tables)
    app.get('/list-collections', async (req, res) => {
      try {
        const collections = await app.db.listCollections().toArray();
        res.json(collections);
      } catch (error) {
        res.status(500).json({ error: 'Failed to list collections' });
      }
    });

    // Start server
    app.listen(3000, () => {
      console.log('Server is running on port 3000');
    });

  } catch (error) {
    console.log('Error:', error);
  }
}

start();
`;

const createMongoDBSecret = `aws --endpoint-url=http://localhost:4566 secretsmanager create-secret --name mongo-db --secret-string "{\"username\": \"root\", \"password\": \"rootpassword\"}"
`;

const SecretManager: React.FC = () => {
  return (
    <div>
      <h2>Secret Manager</h2>
      <p>
        AWS Secrets Manager is a service that helps you protect access to your
        applications, services, and IT resources without the upfront cost and
        complexity of managing your own hardware security module (HSM)
        infrastructure. You can use Secrets Manager to rotate, manage, and
        retrieve database credentials, API keys, and other secrets throughout
        their lifecycle. Users and applications retrieve secrets with a call to
        Secrets Manager APIs, avoiding the need to hardcode sensitive
        information in plaintext.
      </p>
      <h3>Running AWS Secrets Manager Locally Using Docker Compose:</h3>
      <p>
        You can use LocalStack to run AWS Secrets Manager locally. LocalStack is
        a fully functional local AWS cloud stack that allows you to develop and
        test your cloud and serverless applications offline.
      </p>
      <p>
        Here's how you can set up LocalStack with AWS Secrets Manager using
        Docker Compose:
      </p>
      <div className="listitems">
        <pre>{dockerComposeFile}</pre>
      </div>
      <h3>Setting Up Secrets Using Command Prompt or AWS CLI:</h3>
      <p>
        <strong>1. Start LocalStack:</strong> Run the following command to start
        LocalStack using Docker Compose:
      </p>
      <div className="listitems">
        <pre>{dockerComposeUp}</pre>
      </div>
      <p>
        <strong>2. Configure AWS CLI:</strong> Configure your AWS CLI to use
        LocalStack by setting the endpoint URL. You can configure the AWS CLI by
        running:
      </p>
      <div className="listitems">
        <pre>{awsConfigure}</pre>
      </div>
      <p>
        You can use dummy values for the AWS Access Key ID and Secret Access Key
        since LocalStack doesn't enforce authentication.
      </p>
      <p>
        <strong>3. Create a Secret:</strong> Use the following command to create
        a secret in LocalStack Secrets Manager:
      </p>
      <div className="listitems">
        <pre>{createSecret}</pre>
      </div>
      <p>
        Replace "your_username" with your actual username and "your_password"
        with your actual password.
      </p>
      <p>
        <strong>4. List Secrets:</strong> To verify that the secret has been
        created, you can list all secrets:
      </p>
      <div className="listitems">
        <pre>{listSecrets}</pre>
      </div>
      <p>
        <strong>5. Retrieve a Secret:</strong> To retrieve the secret value, use
        the following command:
      </p>
      <div className="listitems">
        <pre>{retrieveSecret}</pre>
      </div>
      <p>
        By following these steps, you should be able to set up AWS Secrets
        Manager locally using LocalStack and Docker Compose, and manage your
        secrets using the AWS CLI.
      </p>
      <h3>Connecting to MongoDB with Express Using AWS Secrets Manager:</h3>
      <h4>Prerequisites:</h4>
      <p>
        1. Make sure you have <strong>Node.js</strong> and <strong>npm</strong>{" "}
        installed on your machine.
      </p>
      <p>
        2. Ensure <strong>LocalStack</strong> is installed and running to mock
        AWS services locally.
      </p>
      <p>
        3. Ensure <strong>MongoDb Express</strong> is installed and running on
        your machine.
      </p>
      <p><strong>Step 1:</strong> Install the necessary npm packages:</p>
      <div className="listitems">
        <pre>{npmDependencies}</pre>
      </div>
      <p><strong>Step 2:</strong> Set Up AWS Secrets Manager Configuration</p>
      <p>
        Create a file named awsConfig.js and add the following code to retrieve
        secrets from AWS Secrets Manager:
      </p>
      <div className="listitems">
        <pre>{awsConfigFile}</pre>
      </div>
      <p>
      <strong>Step 3:</strong> Create an index.js file to set up the Express application and
        MongoDB connection:
      </p>
      <div className="listitems">
        <pre>{indexjsFile}</pre>
      </div>
      <p>
        <strong>Note:</strong> dollarSymbol text in above code should be
        replaced with dollar symbol
      </p>
      <p><strong>Step 4:</strong> Define Routes (Optional):</p>
      <p>
        If you have additional routes, you can define them in the routes
        directory. For example, create a customers.js file in the routes
        directory.
      </p>
      <p><strong>Step 5:</strong> Start LocalStack to mock AWS services.</p>
      <p><strong>Step 6:</strong> Create and Store Secrets in AWS Secrets Manager:</p>
      <p>
        Ensure you have stored your MongoDB credentials in AWS Secrets Manager.
        You can do this using the AWS CLI:
      </p>
      <div className="listitems">
        <pre>{createMongoDBSecret}</pre>
      </div>
      <p>
      <strong>Step 7:</strong> Run the Application using command <strong>node index.js</strong>
      </p>
      <p><strong>Step 8:</strong> Access the Application</p>
      <p>
        Open your browser and navigate to http://localhost:3000/list-collections
        to see the list of MongoDB collections.
      </p>
      <p>
        This guide walks you through setting up an Express application that
        connects to MongoDB using credentials stored in AWS Secrets Manager. By
        following the steps outlined above, you can securely manage your
        database credentials and ensure your application is set up correctly.
      </p>
    </div>
  );
};

export default SecretManager;
