import React from "react";

const passportinst = `
npm install passport passport-local

OR

yarn add passport passport-local

`;

const passport_ex = `
const passport = require('passport');
const LocalStrategy = require('passport-local').Strategy;

passport.use(new LocalStrategy(
  (username, password, done) => {
    // Implement your authentication logic here
    // Verify username and password, and call 'done' with user object if authentication is successful
  }
));

passport.serializeUser((user, done) => {
  // Serialize user object to store in session
  done(null, user.id);
});

passport.deserializeUser((id, done) => {
  // Deserialize user object from session
  // Find user by ID and call 'done' with user object
});

// Additional configuration (optional)
// For example, session support
app.use(passport.initialize());
app.use(passport.session());
`;

const passport_routes = `
app.post('/login',
  passport.authenticate('local', { successRedirect: '/dashboard', failureRedirect: '/login' })
);

app.get('/logout', (req, res) => {
  req.logout();
  res.redirect('/');
});

// Protect a route using authentication
app.get('/dashboard', isAuthenticated, (req, res) => {
  res.render('dashboard', { user: req.user });
});

`;

const passport_auth = `
passport.use(new LocalStrategy(
    (username, password, done) => {
      User.findOne({ username: username }, (err, user) => {
        if (err) { return done(err); }
        if (!user) { return done(null, false, { message: 'Incorrect username.' }); }
        if (!user.validPassword(password)) { return done(null, false, { message: 'Incorrect password.' }); }
        return done(null, user);
      });
    }
  ));
  
`;

const passport_rout_protect = `
function isAuthenticated(req, res, next) {
    if (req.isAuthenticated()) {
      return next();
    }
    res.redirect('/login');
  }
  
`;

const Passport: React.FC = () => {
  return (
    <>
      <h1>Passport.js</h1>
      <p>
        Passport.js is an authentication middleware for Node.js and Express.js,
        designed to be flexible and modular. It provides a set of functions to
        handle various authentication mechanisms, making it easier to integrate
        authentication into your Express.js applications.
      </p>
      <p>
        {" "}
        Passport emerges as a stalwart companion for handling authentication.
        Picture it as your trusty guide, helping users navigate the gates of
        your web application securely.
      </p>
      <h3>Why Use Passport?</h3>
      <p>
        Implementing authentication can be complex, involving intricate details
        and potential security pitfalls. Passport steps in to streamline this
        process, offering a modular and flexible approach. Its strategy-based
        architecture allows you to choose and plug in the authentication methods
        that suit your application's needs.
      </p>
      <h3>Key Features:</h3>
      <ul>
        <li>
          <strong>Modular Design:</strong>Passport is designed with a modular
          architecture, allowing you to choose and implement specific
          authentication strategies as needed.
        </li>
        <li>
          <strong>Wide Range of Strategies: </strong>Passport supports various
          authentication strategies, including local username/password, OAuth,
          OpenID, and more. Each strategy is encapsulated in a separate module.
        </li>
        <li>
          <strong>Extensibility:</strong>Developers can easily extend Passport
          by creating custom authentication strategies or by using existing
          strategies provided by the Passport community.
        </li>
      </ul>
      <h3>1.How to Use Passport.js:</h3>
      <div className="listitems">
        <pre>{passportinst}</pre>
      </div>
      <h3>2.Configure Passport:</h3>
      <div className="listitems">
        <pre>{passport_ex}</pre>
      </div>
      <h3>3.Use Passport in Routes:</h3>
      <div className="listitems">
        <pre>{passport_routes}</pre>
      </div>
      <h3>4.Implement Authentication Logic:</h3>
      <p>
        Implement the logic in the LocalStrategy callback to verify user
        credentials. The done callback should be called with the user object if
        authentication is successful.
      </p>
      <div className="listitems">
        <pre>{passport_auth}</pre>
      </div>
      <h3>5.Protect Routes with Passport:</h3>
      <p>
        Use the isAuthenticated middleware to protect routes that require
        authentication.
      </p>
      <div className="listitems">
        <pre>{passport_rout_protect}</pre>
      </div>
      <h3>Conclusion:</h3>
      <p>
        Passport.js simplifies the process of adding authentication to
        Express.js applications, providing a flexible and modular approach. Its
        support for various authentication strategies makes it a powerful tool
        for securing web applications.
      </p>
    </>
  );
};

export default Passport;
