import React from "react";

const dependencies = `
implementation 'org.springframework.boot:spring-boot-starter-amqp
`;

const applicationProperties = `
spring.rabbitmq.host=localhost
spring.rabbitmq.port=5672
spring.rabbitmq.username=guest
spring.rabbitmq.password=guest
`;
const customerClass = `
package com.nipuna.apache_camel.rabbitMq;

import lombok.AllArgsConstructor;
import lombok.Data;
import lombok.NoArgsConstructor;

import java.io.Serializable;

@Data
@NoArgsConstructor
@AllArgsConstructor
public class Customer implements Serializable {
    private int customerId;
    private String customerName;
    private String position;
}
`;

const config = `
package com.nipuna.apache_camel.rabbitMq;

import org.springframework.amqp.core.Binding;
import org.springframework.amqp.core.BindingBuilder;
import org.springframework.amqp.core.Queue;
import org.springframework.amqp.core.TopicExchange;
import org.springframework.amqp.rabbit.connection.CachingConnectionFactory;
import org.springframework.amqp.rabbit.core.RabbitTemplate;
import org.springframework.amqp.support.converter.Jackson2JsonMessageConverter;
import org.springframework.context.annotation.Bean;
import org.springframework.context.annotation.Configuration;
@Configuration
public class RabbitConfig {

    public static final String QUEUE_NAME = "nipuna";
    public static final String EXCHANGE_NAME = "nipuna";

    @Bean
    public Queue myQueue() {
        return new Queue(QUEUE_NAME, false);
    }

    @Bean
    public TopicExchange myExchange() {
        return new TopicExchange(EXCHANGE_NAME);
    }

    @Bean
    public Binding binding(Queue queue, TopicExchange exchange) {
        return BindingBuilder.bind(queue).to(exchange).with("routing.key.customerdetails");
    }

    @Bean
    public Jackson2JsonMessageConverter messageConverter() {
        return new Jackson2JsonMessageConverter();
    }

    @Bean
    public RabbitTemplate rabbitTemplate(CachingConnectionFactory connectionFactory) {
        RabbitTemplate template = new RabbitTemplate(connectionFactory);
        template.setMessageConverter(messageConverter());
        return template;
    }

}
`;

const rabbitListener = `
package com.nipuna.apache_camel.rabbitMq;

import org.springframework.amqp.rabbit.annotation.RabbitListener;
import org.springframework.stereotype.Component;

@Component
public class MyRabbitListener {

    @RabbitListener(queues = RabbitConfig.QUEUE_NAME)
    public void receiveMessage(Customer customer) {
        System.out.println("Received customer object: " + customer);
    }
}
`;

const messageSender = `
package com.nipuna.apache_camel.rabbitMq;

import org.springframework.amqp.rabbit.core.RabbitTemplate;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.stereotype.Service;

@Service
public class MessageSender {

    @Autowired
    private RabbitTemplate rabbitTemplate;

    public void sendCustomer(Customer customer) {
        rabbitTemplate.convertAndSend(RabbitConfig.EXCHANGE_NAME, "routing.key.customerdetails", customer);
        //rabbitTemplate.convertAndSend(RabbitConfig.QUEUE_NAME, customer);
        System.out.println("Customer object sent: " + customer);
    }
}

`;

const messageProducerListener = `
package com.nipuna.apache_camel.rabbitMq;

import org.springframework.boot.ApplicationArguments;
import org.springframework.boot.ApplicationRunner;
import org.springframework.stereotype.Component;

@Component
public class MessageProducerRunner implements ApplicationRunner {

    private final MessageSender messageSender;

    public MessageProducerRunner(MessageSender messageSender) {
        this.messageSender = messageSender;
    }

    @Override
    public void run(ApplicationArguments args) throws Exception {
        Customer customer = new Customer(1, "John Doe", "Manager");
        messageSender.sendCustomer(customer);
    }
}
`;

const RabbitMq: React.FC = () => {
  return (
    <div>
      <h2>RabbitMQ Overview</h2>
      <p>
        RabbitMQ is a message broker that allows applications to communicate
        with each other by sending messages through queues. It supports multiple
        messaging protocols and is widely used for message queuing and
        distributed systems.
      </p>

      <h3>Steps to Run RabbitMQ Locally with Docker</h3>
      <ol>
        <li>
          <strong>Install Docker:</strong> If you haven't already, install
          Docker on your machine. You can download it from{" "}
          <a
            href="https://www.docker.com/get-started"
            target="_blank"
            rel="noopener noreferrer"
          >
            Docker's official website
          </a>
          .
        </li>
        <li>
          <strong>Run RabbitMQ Docker Container:</strong> Use the following
          command to start a RabbitMQ container with the management plugin
          enabled:
          <pre>
            <code>
              docker run -d --name rabbitmq -p 5672:5672 -p 15672:15672
              rabbitmq:management
            </code>
          </pre>
          - <code>-d</code>: Run the container in detached mode.
          <br />- <code>--name rabbitmq</code>: Name the container "rabbitmq".
          <br />- <code>-p 5672:5672</code>: Map port 5672 for RabbitMQ's AMQP
          protocol.
          <br />- <code>-p 15672:15672</code>: Map port 15672 for RabbitMQ's
          management UI.
        </li>
        <li>
          <strong>Access RabbitMQ Management UI:</strong> Open a browser and
          navigate to{" "}
          <a
            href="http://localhost:15672"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://localhost:15672
          </a>
          .
        </li>
        <li>
          <strong>Log In:</strong>- Username: <code>guest</code>
          <br />- Password: <code>guest</code>
        </li>
      </ol>

      <h3>RabbitMQ UI Screens:</h3>
      <p>
        Below is an overview of the RabbitMQ Management UI tabs, including what
        you can find in each section and their purpose.
      </p>
      <p>
        1. <b>Overview Tab:</b> Provides a snapshot of the RabbitMQ server's
        current state. We can quickly check the overall health and statistics of
        the RabbitMQ server
      </p>
      <img
        src="/overviewRabbitMq.png"
        alt="overviewRabbitMq"
        style={{ width: "1000px", border: "1px solid black" }}
      />
      <p>
        2. <b>Connections Tab:</b> Displays information about current
        connections to the RabbitMQ server.
      </p>
      <img
        src="/connectionsRabbitMq.png"
        alt="connectionsRabbitMq"
        style={{ width: "1000px", border: "1px solid black" }}
      />
      <p>
        3. <b>Channels Tab:</b> Shows details about active channels within
        connections. We can monitor and manage active connections to RabbitMQ.
      </p>
      <img
        src="/channelsRabbitMq.png"
        alt="channelsRabbitMq"
        style={{ width: "1000px", border: "1px solid black" }}
      />
      <p>
        4. <b>Exchanges Tab:</b> Manages and views exchanges used for routing
        messages. Can configure and manage exchanges to control message routing.
      </p>
      <img
        src="/exchangesRabbitMq.png"
        alt="exchangesRabbitMq"
        style={{ width: "1000px", border: "1px solid black" }}
      />
      <p>
        5. <b>Queues and Steams Tab:</b> Displays and manages message queues and
        steams. Can view, manage, and interact with queues that store messages
        and also manage high-throughput data streams if supported.
      </p>
      <img
        src="/queuesStreamsRabbitMq.png"
        alt="queuesStreamsRabbitMq"
        style={{ width: "1000px", border: "1px solid black" }}
      />
      <p>
        6. <b>Admin Tab:</b> Provides administrative functions and handle
        administrative tasks such as user management, virtual hosts, and
        policies.
      </p>
      <img
        src="/adminRabbitMq.png"
        alt="adminRabbitMq"
        style={{ width: "1000px", border: "1px solid black" }}
      />

      <h3>Creating a Queue and Exchange in RabbitMQ Management UI</h3>
      <ol>
        <li>
          <strong>Log In:</strong> Go to{" "}
          <a
            href="http://localhost:15672"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://localhost:15672
          </a>{" "}
          and log in using the default credentials.
        </li>
        <li>
          <strong>Create a Queue:</strong>
          - Navigate to the "Queues" tab.
          <br />
          - Click on the "Add a new queue" button.
          <br />- Enter the name for the queue (e.g., <code>nipuna</code>).
          <br />- Click "Add queue".
        </li>
        <li>
          <strong>Create an Exchange:</strong>
          - Navigate to the "Exchanges" tab.
          <br />
          - Click on the "Add a new exchange" button.
          <br />- Enter the name for the exchange (e.g., <code>nipuna</code>).
          <br />
          - Select the type of exchange (e.g., "topic").
          <br />- Click "Add exchange".
        </li>
        <li>
          <strong>Bind the Queue to the Exchange:</strong>
          - Navigate to the "Exchanges" tab.
          <br />
          - Click on the name of the exchange you created.
          <br />
          - Scroll down to the "Add a binding" section.
          <br />
          - Select the queue you created from the dropdown.
          <br />- Enter a routing key (e.g., <code>routing.key.customerdetails</code>).
          <br />- Click "Add binding".
        </li>
      </ol>
      <p>Exchange binding to queue on UI looks like below for reference:</p>
      <img
        src="/myExchangeRabbitMq.png"
        alt="myExchangeRabbitMq"
        style={{ width: "1000px", border: "1px solid black" }}
      />

      <h3>Sample Code</h3>
      <p>
        The following code demonstrates how to configure and use RabbitMQ in a
        Spring Boot application:
      </p>
      <h4>1. Dependencies</h4>
      <p>
        Add the following dependency to your build.gradle file for RabbitMQ
        support:
      </p>
      <div className="listitems">
        <pre>{dependencies}</pre>
      </div>
      <h4>2. `application.properties`</h4>
      <p>Add below properties to the application.properties file</p>
      <div className="listitems">
        <pre>{applicationProperties}</pre>
      </div>
      <p>
        Configures Spring Boot to connect to RabbitMQ running on{" "}
        <code>localhost</code> at port <code>5672</code> using the default
        credentials.
      </p>

      <h4>3. `RabbitConfig` Class</h4>
      <div className="listitems">
        <pre>{config}</pre>
      </div>
      <p>
        Defines a queue named <code>nipuna</code>, a topic exchange named{" "}
        <code>nipuna</code>, and binds the queue to the exchange with a routing
        key pattern <code>routing.key.customerdetails</code>.
      </p>

      <h4>4. `Customer` Class</h4>
      <div className="listitems">
        <pre>{customerClass}</pre>
      </div>
      <p>
        Create a Customer class that defines the data structure to be sent and
        received.This class object will be serialized and sent through RabbitMQ.
      </p>
      <h4>5. `MyRabbitListener` Class</h4>
      <div className="listitems">
        <pre>{rabbitListener}</pre>
      </div>
      <p>
        Listens for messages on the <code>nipuna</code> queue from RabbitMQ and
        prints them out.
      </p>
      <p>Refer to the screenshot below to see how to publish a Customer object to RabbitMQ directly from the RabbitMQ management UI:</p>
      <img
        src="/publishFromUI.png"
        alt="publishFromUI"
        style={{ width: "800px", border: "1px solid black" }}
      />

      <h4>6. `MessageSender` Class</h4>
      <div className="listitems">
        <pre>{messageSender}</pre>
      </div>
      <p>
        Sends customer objects to the <code>nipuna</code> exchange with a
        routing key <code>routing.key.example</code>.
      </p>

      <h4>7. `MessageProducerRunner` Class</h4>
      <div className="listitems">
        <pre>{messageProducerListener}</pre>
      </div>
      <p>
        Initializes the sending of a Customer object to RabbitMQ when the Spring
        Boot application starts.
      </p>
      <p>
        By following the steps and using the provided classes, you can
        effectively set up and use RabbitMQ for message queuing in your
        applications, enabling robust and scalable communication between
        different parts of your system.
      </p>
    </div>
  );
};

export default RabbitMq;
