import React from "react";

const GWT: React.FC = () => {
  return (
    <div>
      <h2>Introduction to GWT</h2>

      <p>
        Google Web Toolkit (GWT) is an open-source set of tools that allows
        developers to create and maintain complex JavaScript front-end
        applications in Java. GWT enables you to write client-side code in Java,
        which is then compiled into highly optimized JavaScript. This allows
        developers to take advantage of the Java language features while
        ensuring that their applications run efficiently in the browser.
      </p>
      <h3>Topics Covered:</h3>
      <ul>
        <li>Set Up/Create GWT Project</li>
        <li>Create Form Using Panel</li>
        <li>Event Handlers</li>
        <li>Create Form Using UI Binder</li>
        <li>RPC Calls</li>
      </ul>
    </div>
  );
};

export default GWT;
